<template>
  <div v-if="HAS_SUBSCRIPTION" class="lc-plan-detail">
    <div class="lc-plan-detail_left-column">
      <div class="lc-plan-detail_left-column_planinfo">
        <div class="lc-plan-detail_left-column_planinfo--plan">{{ planName }}</div>
        <div class="lc-plan-detail_left-column_planinfo--text">Plan</div>
      </div>
      <div class="lc-plan-detail_left-column_users">
        <div class="lc-plan-detail_left-column_users--text">{{ usersActive }} of {{ totalAvailableUsers }} users active</div>
        <div class="lc-plan-detail_left-column_users--bar">
          <div class="lc-plan-detail_left-column_users_progress-bar">
            <div class="lc-plan-detail_left-column_users_progress-bar-fill" :style="{ width: percentage + '%' }"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="company.isAppSumoAccount" class="lc-plan-detail_right-column">
      <div class="lc-plan-detail_right-column--logo">
        <AppsumoLogo />
        <CheckIcon class="lc-plan-detail_right-column--check-icon" />
      </div>
      <div class="lc-plan-detail_right-column_info">
        <div class="lc-plan-detail_right-column_info--deal">{{ $t('pricing.lifetimeDeal') }}</div>
        <div class="lc-plan-detail_right-column_info--text">$ {{ unitPrice }},-</div>
        <div class="lc-plan-detail_right-column_info--text">on {{ createdDate }}</div>
      </div>
    </div>
    <div v-else class="lc-plan-detail_right-column">
      <div class="lc-plan-detail_right-column_amount" v-if="!HAS_FREE_SUBSCRIPTION">
        <div class="lc-plan-detail_right-column_amount--text">
          <span class="lc-plan-detail_right-column_amount--text-currency">{{ unitPriceCurrency }}</span>
          <span class="lc-plan-detail_right-column_amount--text-amount">{{ unitPrice }}</span>
          <span class="lc-plan-detail_right-column_amount--text-period">/mo</span>
        </div>
      </div>
      <div
        v-if="(HAS_MOLLIE_SUBSCRIPTION || HAS_INVOICE_SUBSCRIPTION || HAS_FREE_SUBSCRIPTION) && !IS_LETSCONNECT_SUBSCRIPTION_CANCELLED"
        class="lc-plan-detail_right-column_upgrade"
        @click="onShowPlans"
      >
        <span class="mr-4">{{ $t('upgrade-messages.upgrade-plan') }}</span>
        <img src="@/assets/images/register-funnel/forward-arrow.png" width="18px" height="16.2px" alt="Next" />
      </div>
    </div>
  </div>
</template>
<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

import { mapGetters } from 'vuex'
import { paddle } from '@/mixins/paddle'
import { appsumo_subscription } from '@/mixins/appsumo_subscription'
import AppsumoLogo from './AppsumoLogo.vue'
import CheckIcon from './CheckIcon.vue'

export default {
  name: 'PlanInfo',
  mixins: [paddle, appsumo_subscription],
  components: {
    AppsumoLogo,
    CheckIcon
  },
  data() {
    return {
      MAX_USERS_FREE_PLAN: 5,
      symbols: {
        EUR: '€',
        AUD: '$',
        USD: '$',
        GBP: '£'
      }
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      allUsers: 'users',
      company: 'company',
      subscription: 'subscription',
      currency: 'currency',
      pathadviceInvoices: 'invoice/pathadviceInvoices'
    }),

    createdDate() {
      if (this.company && this.company.created) {
        return dayjs.unix(this.company.created.seconds).locale(this.$i18n.locale).format('LL')
      }
      return null
    },

    totalUsersForPlan() {
      const total = this.IS_ON_TRIAL
        ? this.company.totalUsersForPlan || this.MAX_USERS_FREE_PLAN
        : this.company.totalUsersForPlan || this.getTotalUsersForSubscriptionPlan(this.company.subscriptionPlanId)

      return total || this.users.length
    },

    totalAvailableUsers() {
      const totalLicenses = this.company && typeof this.company.totalLicenses !== 'undefined' ? this.company.totalLicenses : 1
      return totalLicenses * this.totalUsersForPlan
    },

    users() {
      return this.allUsers.filter((x) => x.company === this.activeUserInfo.company && x.isActive)
    },

    HAS_SUBSCRIPTION() {
      return (
        this.HAS_APPSUMO_SUBSCRIPTION ||
        this.HAS_SHOPIFY_SUBSCRIPTION ||
        this.HAS_INVOICE_SUBSCRIPTION ||
        this.HAS_MOLLIE_SUBSCRIPTION ||
        this.HAS_PADDLE_SUBSCRIPTION ||
        this.HAS_FREE_SUBSCRIPTION
      )
    },

    IS_LETSCONNECT_SUBSCRIPTION_CANCELLED() {
      if (this.company && this.company.paymentStatus === 'subscription-cancelled') {
        return true
      }
      if (this.subscription && (this.subscription.subscriptionStatus === 'canceled' || this.subscription.status === 'canceled')) {
        return true
      }
      return false
    },

    HAS_FREE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.subscriptionPlanId && this.company.subscriptionPlanId.indexOf('FREE') > -1 && this.company.paymentStatus === 'subscription')
    },

    HAS_SHOPIFY_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company && this.company.isShopifyAccount)
    },

    HAS_APPSUMO_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company && this.company.isAppSumoAccount)
    },

    HAS_INVOICE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.currentInvoiceId)
    },

    HAS_MOLLIE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.mollieCustomerId && this.company.mollieSubscriptionId)
    },

    HAS_PADDLE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(
        this.company.subscriptionId &&
          !this.company.mollieSubscriptionId &&
          ['subscription', 'subscription-cancelled', 'deleted'].includes(this.company.paymentStatus)
      )
    },

    usersActive() {
      return this.users.length
    },

    percentage() {
      return (this.usersActive * 100) / this.totalAvailableUsers
    },

    planName() {
      if (this.company && this.company.isAppSumoAccount && this.company.subscriptionPlanId) {
        const plan = this.appSumoPlans.find((x) => x.id === this.company.subscriptionPlanId)
        if (plan) {
          return plan.title
        }
        return null
      }
      if (this.subscription && this.subscription.planDetails && this.subscription.planDetails.name) {
        const name = this.subscription.planDetails.name.toLowerCase() === 'professional' ? 'Pro' : this.subscription.planDetails.name
        return name.replace('PATHADVICE ', '')
      }
      if (this.HAS_FREE_SUBSCRIPTION) {
        return 'Free'
      }
      return null
    },
    unitPrice() {
      if (this.company && this.company.isAppSumoAccount && this.company.subscriptionPlanId) {
        const plan = this.appSumoPlans.find((x) => x.id === this.company.subscriptionPlanId)
        if (plan) {
          return Number(plan.price)
        }
        return null
      }

      if (this.company && this.company.currentInvoiceId) {
        const plan = this.subscription.planDetails
        if (this.subscription && this.subscription.planAmount && this.subscription.currency && !this.company.IS_LOTTERY_WINNER) {
          return Number(this.subscription.planAmount)
        }

        if (this.company.IS_LOTTERY_WINNER && plan.lottery_price) {
          const planAmount =
            this.subscription && this.subscription.planAmount && plan.billing_type === 'year' ? Number(this.subscription.planAmount / 12).toFixed(2) : null
          return planAmount || Number(plan.lottery_price['EUR'])
        }
        return Number(plan.recurring_price[this.currency.text])
      }
      if (this.HAS_MOLLIE_SUBSCRIPTION || this.HAS_PADDLE_SUBSCRIPTION) {
        return Number(this.subscription.planDetails.unitPrice.split(' ')[0])
      }
      return ''
    },

    unitPriceCurrency() {
      if (this.company && this.company.currentInvoiceId) {
        const plan = this.subscription.planDetails
        if (this.subscription && this.subscription.planAmount && this.subscription.currency && !this.company.IS_LOTTERY_WINNER) {
          return this.subscription.currency.symbol
        }

        if (this.company.IS_LOTTERY_WINNER && plan.lottery_price) {
          return '€'
        }

        if (this.subscription && this.subscription.metadata && this.subscription.metadata.currency && !this.company.IS_LOTTERY_WINNER) {
          return this.subscription.metadata.currency.symbol
        }

        return this.subscription.currency.symbol
      }

      if (this.HAS_MOLLIE_SUBSCRIPTION || this.HAS_PADDLE_SUBSCRIPTION) {
        if (
          this.subscription &&
          this.subscription.metadata &&
          this.subscription.metadata.planAmount &&
          this.subscription.metadata.currency &&
          this.subscription.metadata.currency.symbol
        ) {
          return this.subscription.metadata.currency.symbol
        }

        return this.symbols[this.subscription.planDetails.unitPrice.split(' ')[1]]
      }
      return ''
    }
  },
  methods: {
    onShowPlans() {
      this.$serverBus.$emit('show-plans')
    }
  }
}
</script>
<style lang="scss" scoped>
.lc-plan-detail {
  display: flex;
  justify-content: space-between;

  padding: 40px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 10px 10px;
  height: 100%;

  &_left-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 64%;

    &_planinfo {
      display: flex;
      justify-content: flex-start;

      &--plan {
        padding: 2px 39px;
        border-radius: 6px;
        background: #275d73;
        color: #fff;
        text-align: center;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.111px;
        margin-right: 11px;
      }
      &--text {
        padding: 2px 0px;
        color: #262629;
        text-align: center;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.111px;
      }
    }

    &_users {
      display: flex;
      flex-direction: column;

      &_progress-bar {
        width: 90%;
        height: 10px;
        background-color: #ebf5fe;
        border-radius: 5px;
        overflow: hidden;
      }

      &_progress-bar-fill {
        height: 100%;
        background-color: #275d73;
        transition: width 0.3s ease;
        border-radius: 5px;
      }
    }
  }

  &_right-column {
    position: relative;
    display: flex;
    flex-direction: column;

    &--logo {
      position: relative;
      align-self: flex-end;
    }
    &--check-icon {
      position: absolute;
      top: -8px;
      right: -12px;
    }

    &_info {
      margin-top: 33.44px;

      &--deal {
        color: #7e8186;
        text-align: right;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; /* 137.5% */
        letter-spacing: -0.111px;
      }

      &--text {
        color: #262629;
        text-align: right;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; /* 110% */
        letter-spacing: -0.111px;
      }
    }

    &_amount {
      flex-basis: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      &--text {
        display: flex;

        &-currency {
          color: #262629;
          font-family: Larsseit;
          font-size: 31px;
          font-style: normal;
          font-weight: 500;
          line-height: 31px;
          letter-spacing: -0.172px;
        }
        &-amount {
          color: #262629;
          font-family: Larsseit;
          font-size: 56.364px;
          font-style: normal;
          font-weight: 700;
          line-height: 62px;
          letter-spacing: -0.313px;
        }
        &-period {
          color: #53535b;
          vertical-align: bottom;
          font-family: Larsseit;
          font-size: 19.727px;
          font-style: normal;
          font-weight: 500;
          line-height: 81.545px;
          letter-spacing: -0.11px;
        }
      }
    }

    &_upgrade {
      border-radius: 8px;
      background: #3034f7;
      color: #fff;
      padding: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 200px;
      cursor: pointer;
    }
  }
}
</style>
