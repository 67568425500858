<template>
  <div class="gpt-subscription">
    <gpt-connect-message v-if="!HAS_ACCESS"></gpt-connect-message>
    <vs-popup :title="upgradeTitle" :active.sync="showUpgradeConfirmation">
      <p class="mb-2" v-if="selectedPlan && selectedPlan.level > this.CURRENT_PLAN_LEVEL">{{ $t('vue.confirmQuestion') }}</p>

      <vs-button @click="onConfirmUpgrade" color="#3034F7" type="filled" class="mr-2">{{ $t('vue.confirm') }}</vs-button>
      <vs-button @click="onCloseUpgrade" type="border" color="#3034F7">{{ $t('vue.cancelChangePlan') }}</vs-button>
    </vs-popup>

    <vs-popup :title="downgradeTitle" :active.sync="showDowngradeConfirmation">
      <p class="mb-2">{{ DOWNGRADED_FROM }}</p>
      <vs-button @click="onConfirmDowngrade" color="#e8546f" type="filled" class="mr-2">{{ $t('vue.confirm') }}</vs-button>
      <vs-button @click="onCloseDowngrade" type="border" color="#e8546f">{{ $t('vue.cancelChangePlan') }}</vs-button>
    </vs-popup>

    <div v-if="isProcessing" class="gpt-subscription__loading">
      <div class="gpt-subscription__loading__loader">
        <span class="gpt-subscription__loading__loader__spinner" />
        <div class="gpt-subscription__loading__loader__text">{{ $t('appsumo.processing') }}</div>
      </div>
    </div>

    <div v-if="isGptActivationSuccessful" class="gpt-subscription__loading">
      <div class="gpt-subscription__loading__loader">
        <img src="@/assets/images/register-funnel/check.png" alt="Success" />
        <div class="gpt-subscription__loading__loader__title">{{ $t('gpt.subscription.thankYou') }}</div>
        <div class="gpt-subscription__loading__loader__text">{{ $t('gpt.subscription.thankYouMessage') }}</div>
      </div>
    </div>
    <vx-card class="p-0">
      <vs-row class="mt-5">
        <vs-col vs-type="flex">
          <div class="price-list">
            <div class="price-list__heading">
              <h3 class="price-list__heading__title">{{ $t('pricing.chooseTheRightPlanForYou') }}</h3>
            </div>

            <template>
              <div class="price-list__plans">
                <div class="price-list__plans__heading">
                  <div class="price-list__plans__heading__text">
                    {{ $t('gpt.billingHeadingText') }}
                  </div>
                </div>

                <div class="price-list__plans-list">
                  <div v-for="plan in filteredGptPlans" class="price-list__plans-list__item" :key="plan.id">
                    <div
                      class="price-list__plans-list__item__heading"
                      :class="{
                        'price-list__plans-list__item__heading--active': isPlanHighlighted(plan)
                      }"
                    >
                      <div class="price-list__plans-list__item__heading__title">{{ plan.title }}</div>

                      <div v-if="plan.isNewPlan" class="price-list__plans-list__item__heading__label-summary">
                        {{ ACTIVE_FROM }}
                      </div>
                    </div>

                    <div class="price-list__plans-list__item__body">
                      <div v-if="plan.mostPopular">
                        <div class="price-list__plans-list__item__content__label price-list__plans-list__item__content__label--desktop">
                          {{ $t('vue.mostPopular') }}
                        </div>
                        <div class="price-list__plans-list__item__content__label price-list__plans-list__item__content__label--mobile">
                          {{ $t('vue.mostPopular') }}
                        </div>
                      </div>

                      <div class="price-list__plans-list__item__content__price">
                        <div class="price-list__plans-list__item__content__price__currency">€</div>
                        <div class="price-list__plans-list__item__content__price__amount">
                          {{ plan.price }}
                          <span class="price-list__plans-list__item__content__price__amount__period">/mo</span>
                        </div>
                      </div>

                      <div class="price-list__plans-list__item__heading__label-action" :class="SET_PLAN_CLASS(plan)">
                        <div class="price-list__plans-list__item__heading__label-action__text" @click="onChoosePlan(plan)">
                          <template>{{ $t(planChangeText(plan)) }}</template>
                        </div>
                      </div>

                      <div class="price-list__plans-list__item__downgrade" v-if="plan.isNewPlan && DOWNGRADED_FROM_TEXT">
                        {{ DOWNGRADED_FROM_TEXT }}
                      </div>
                      <ul class="price-list__plans-list__item__body__options-list">
                        <li
                          v-for="option in plan.options"
                          class="price-list__plans-list__item__body__options-list__item"
                          :class="{ 'price-list__plans-list__item__body__options-list__item--boldify': option.boldify }"
                          :key="option.id"
                        >
                          <ThinCheckIcon class="price-list__plans-list__item__body__options-list__item__icon" />
                          {{ $t(option.description) }}
                        </li>

                        <li v-if="plan.isEnterprisePlan" class="price-list__plans-list__item__body__options-list__item">
                          <AmpersandIcon class="price-list__plans-list__item__body__options-list__item__icon" />
                          {{ $t('pricing.more') }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <MessagesCount v-if="HAS_GPT_MOLLIE_SUBSCRIPTION || HAS_GPT_INVOICE_SUBSCRIPTION" />
                <div v-else class="price-list__info">
                  <div class="price-list__info--more-info">{{ $t('gpt.needMoreInfo') }}</div>
                  <div class="price-list__info--see-more" @click="onGoToSeeMore()">{{ $t('gpt.seeMore') }}</div>
                </div>
              </div>
            </template>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { gpt_subscription } from '@/mixins/gpt_subscription'

import ThinCheckIcon from '@/components/icons/ThinCheckIcon'
import AmpersandIcon from '@/components/icons/AmpersandIcon'
import MessagesCount from './MessagesCount.vue'

import GptConnectMessage from '@/components/upgrade-messages/GptConnectMessage.vue'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default {
  name: 'GptConnectPricingPlans',
  mixins: [gpt_subscription],
  components: {
    ThinCheckIcon,
    AmpersandIcon,
    MessagesCount,
    GptConnectMessage
  },
  data: () => ({
    selectedPlan: null,
    showUpgradeConfirmation: false,
    showDowngradeConfirmation: false,
    isGptActivationSuccessful: false,
    isProcessing: false
  }),

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      companyData: 'company'
    }),

    HAS_ACCESS() {
      if (!this.companyData) {
        return false
      }
      let claims = this.companyData.claims || []
      const custom_claims = this.companyData.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('gpt-connect') && this.companyData.expiryDate && dayjs().isBefore(dayjs.unix(this.companyData.expiryDate.seconds))
    },

    filteredGptPlans() {
      return this.gptPlans.map((plan) => {
        const modifiedPlan = { ...plan }
        if (!this.HAS_GPT_SUBSCRIPTION && plan.mostPopular) {
          modifiedPlan.isSelected = true
        }
        if (this.HAS_GPT_SUBSCRIPTION) {
          if (this.HAS_GPT_MOLLIE_SUBSCRIPTION && this.companyData.gptSubscriptionPlanId && this.companyData.gptSubscriptionPlanId === plan.id) {
            modifiedPlan.isSelected = true
            modifiedPlan.isCurrentPlan = true
          }
          if (this.HAS_GPT_INVOICE_SUBSCRIPTION && this.companyData.gptSubscriptionPlanId && this.companyData.gptSubscriptionPlanId === plan.id) {
            modifiedPlan.isSelected = true
            modifiedPlan.isCurrentPlan = true
          }
          if (this.companyData.newMollieGptSubscriptionPlanId && this.companyData.newMollieGptSubscriptionPlanId === plan.id) {
            modifiedPlan.isNewPlan = true
          }
        }
        return modifiedPlan
      })
    },

    upgradeTitle() {
      if (!this.selectedPlan) return ''
      return `${this.$i18n.t('pricing.upgradeNow')} - ${this.selectedPlan.description}`.toUpperCase()
    },

    downgradeTitle() {
      if (!this.selectedPlan) return ''
      return `${this.$i18n.t('pricing.downgradeNow')} - ${this.selectedPlan.description}`.toUpperCase()
    },

    CURRENT_PLAN() {
      const plan = this.filteredGptPlans.find((x) => x.isCurrentPlan)
      return plan
    },

    CURRENT_PLAN_LEVEL() {
      return this.CURRENT_PLAN ? this.CURRENT_PLAN.level : 0
    },

    MOLLIE_CUSTOMER_ID() {
      if (this.companyData && this.companyData.mollieCustomerId) return this.companyData.mollieCustomerId
      return null
    },

    MOLLIE_MANDATE_ID() {
      if (this.companyData && this.companyData.mollieMandateId) return this.companyData.mollieMandateId
      return this.companyData.mollieMandateId
    },

    ACTIVE_FROM() {
      if (this.companyData && this.companyData.gptExpiryDate) {
        return `${this.$i18n.t('pricing.activeFrom')} ${dayjs.unix(this.companyData.gptExpiryDate.seconds).add(-1, 'day').format('DD-MM-YYYY')}`
      }
      return ''
    },
    DOWNGRADED_FROM() {
      if (this.companyData && this.companyData.gptExpiryDate) {
        return `${this.$i18n.t('pricing.downgradeSubscriptionMessage')} ${dayjs
          .unix(this.companyData.gptExpiryDate.seconds)
          .add(-1, 'day')
          .format('DD-MM-YYYY')}`
      }
      return null
    },

    DOWNGRADED_FROM_TEXT() {
      if (this.companyData && this.companyData.gptExpiryDate && this.companyData.newMollieGptSubscriptionPlanId) {
        return `${this.$i18n.t('pricing.downgradeSubscriptionMessage')} ${dayjs
          .unix(this.companyData.gptExpiryDate.seconds)
          .add(-1, 'day')
          .locale(this.$i18n.locale)
          .format('LL')}`
      }
      return null
    },

    HAS_INVOICE_SUBSCRIPTION() {
      if (!this.companyData) return false
      return Boolean(this.companyData.currentInvoiceId)
    },

    HAS_GPT_INVOICE_SUBSCRIPTION() {
      if (!this.companyData) return false
      return Boolean(this.companyData.gptCurrentInvoiceId)
    },

    HAS_MOLLIE_MANDATE() {
      if (!this.companyData) return false
      return this.companyData.mollieMandateId
    },

    HAS_GPT_SUBSCRIPTION() {
      return (this.HAS_GPT_MOLLIE_SUBSCRIPTION && this.HAS_MOLLIE_MANDATE) || this.HAS_GPT_INVOICE_SUBSCRIPTION
    },

    HAS_MOLLIE_SUBSCRIPTION() {
      if (!this.companyData) return false
      return Boolean(this.companyData.mollieCustomerId && this.companyData.mollieSubscriptionId)
    },

    HAS_GPT_MOLLIE_SUBSCRIPTION() {
      return (
        this.companyData &&
        Boolean(this.companyData.gptSubscriptionPlanId) &&
        Boolean(this.companyData.mollieGptSubscriptionId) &&
        Boolean(this.companyData.mollieCustomerId) &&
        this.companyData.gptPaymentStatus !== 'subscription-cancelled' &&
        this.companyData.gptExpiryDate &&
        dayjs().isBefore(dayjs.unix(this.companyData.gptExpiryDate.seconds))
      )
    },

    locale() {
      return this.$i18n.locale
    }
  },

  methods: {
    ...mapMutations({
      setSelectedGptPlan: 'gpt/SET_SELECTED_GPT_PLAN',
      setShowMollieGpt: 'gpt/SET_SHOW_MOLLIE_GPT',
      updateShowChangePlanUI: 'UPDATE_SHOW_CHANGE_PLAN_UI'
    }),

    onDisplayError(message) {
      this.$vs.notify({
        title: this.$i18n.t('vue.error'),
        text: this.$i18n.t(message),
        color: 'danger',
        time: 10000
      })
    },

    getMollieLocale() {
      const locale = this.$i18n.locale
      let mollieLocale = 'en_US'
      switch (locale) {
        case 'en':
          mollieLocale = 'en_US'
          break

        case 'de':
          mollieLocale = 'de_DE'
          break
      }
      return mollieLocale
    },

    onGoToSeeMore() {
      window.open('https://letsconnect.at/gpt-connect', '_blank')
    },

    onGetVatPercent(invoice) {
      /*
    vatPercent is only stored for EU companies
    If they are outside of the EU (i.e vatPercent does not exist in invoice) we need no VAT.
  */
      if (typeof invoice.country.vatPercent === 'undefined') {
        return '0.0'
      }

      /* Vat is 20% if the customers are based in Austria  */
      if (invoice.country.code === 'at') {
        return invoice.country.vatPercent
      }

      /* If they have entered a VAT number and are inside the EU - then we do not need a VAT. */
      if (invoice.vatNumber && invoice.vatNumber.trim().length > 0 && invoice.country.vatPercent) {
        return '0.0'
      }

      /*If they have not entered a VAT number and are inside the EU - we charge the VAT from the country on top. */
      if (!invoice.vatNumber || invoice.vatNumber.trim().length === 0) {
        return invoice.country.vatPercent || '0.0'
      }

      return '0.0'
    },

    isPlanHighlighted(plan) {
      if (!this.HAS_GPT_MOLLIE_SUBSCRIPTION && !this.HAS_GPT_INVOICE_SUBSCRIPTION) {
        return plan.isSelected
      }
      if (this.HAS_GPT_MOLLIE_SUBSCRIPTION || this.HAS_GPT_INVOICE_SUBSCRIPTION) {
        return plan.isCurrentPlan
      }
    },

    SET_PLAN_CLASS(plan) {
      if (plan.isNewPlan) {
        return 'price-list__plans-list__item__heading__label-action_downgraded'
      }
      if (plan.isCurrentPlan) {
        return 'price-list__plans-list__item__heading__label-action_active'
      }
      if (plan.isSelected) {
        return 'price-list__plans-list__item__heading__label-action_selected'
      }
      return ''
    },

    showMollieGptAndUpdateUI(plan) {
      this.setSelectedGptPlan(plan)
      this.setShowMollieGpt(true)
      this.updateShowChangePlanUI(true)
    },

    async onAddInvoiceSubscription(plan) {
      try {
        const currentInvoiceRef = await this.$db.collection('pathadvice-invoices').doc(this.companyData.currentInvoiceId).get()
        const currentInvoice = currentInvoiceRef.data()

        let _streetAddress = this.companyData.street
        if (this.companyData.houseNumber) {
          _streetAddress = `${_streetAddress} ${this.companyData.houseNumber}`
        }

        const newInvoice = {
          currency: { text: 'EUR', symbol: '€' },
          title: `GPT PAYMENT ${dayjs().utc().format('YYYY-MM-DD')}`,
          academicTitle: currentInvoice.academicTitle || '',
          firstName: currentInvoice.firstName,
          lastName: currentInvoice.lastName,
          company: this.companyData.name,
          companyId: this.activeUserInfo.company,
          vatNumber: this.companyData.vatNumber,
          email: currentInvoice.email,
          streetAddress: _streetAddress,
          city: this.companyData.city,
          zipOrPostalCode: this.companyData.zipcode,
          country: this.companyData.country,
          selectedPlanId: plan.id,
          isFirstInvoice: true,
          status: 'open',
          source: 'gpt-payment',
          createdAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
          emailLink: `${window.location.protocol}//${window.location.host}/payment?showInvoice=`,
          invoiceBillDate: dayjs().utc().format('YYYY-MM-DD')
        }
        await this.$db.collection('pathadvice-invoices').add(newInvoice)

        this.isGptActivationSuccessful = true
        setTimeout(() => {
          this.isGptActivationSuccessful = false
        }, 2500)
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
      }
    },

    async onUpdateInvoiceSubscription(plan) {
      // eslint-disable-next-line
      console.log('onUpdateInvoiceSubscription', plan)
    },

    async onCheckAndCreateInvoiceSubscription(plan) {
      try {
        if (this.isProcessing) return
        this.isProcessing = true
        if (!this.HAS_GPT_INVOICE_SUBSCRIPTION) {
          await this.onAddInvoiceSubscription(plan)
        } else {
          await this.onUpdateInvoiceSubscription(plan)
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
      }

      this.isProcessing = false
    },

    async onCreateMollieSubscription(plan) {
      this.isProcessing = true
      try {
        const _invoice = {
          country: this.companyData.country,
          vatNumber: this.companyData.vatNumber
        }
        const planAmount = Number(plan.price)
        const vatPercent = parseFloat(this.onGetVatPercent(_invoice))
        const taxAmount = (planAmount * vatPercent) / 100
        const totalAmount = planAmount + taxAmount

        const mollieCreateNewGptPayment = this.$functions.httpsCallable('mollieCreateNewGptPayment')
        const payment = {
          customerName: this.activeUserInfo.displayName || `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`,
          customerEmail: this.activeUserInfo.email,
          description: plan.description,
          locale: this.getMollieLocale(),
          amount: totalAmount.toFixed(2),

          metadata: {
            companyId: this.activeUserInfo.company,
            userId: this.activeUserInfo.uid,
            type: 'new',
            planId: plan.id,
            source: 'gpt-payment',
            planAmount: planAmount.toFixed(2),
            taxAmount: taxAmount.toFixed(2),
            totalAmount: totalAmount.toFixed(2)
          },
          redirectUrl: `${location.protocol}//${location.host}${location.pathname}?source=gpt`,
          sequenceType: 'recurring'
        }

        if (this.MOLLIE_CUSTOMER_ID) {
          payment.mollieCustomerId = this.MOLLIE_CUSTOMER_ID
          payment.metadata.customerId = this.MOLLIE_CUSTOMER_ID
        }

        if (this.MOLLIE_MANDATE_ID) {
          payment.mollieMandateId = this.MOLLIE_MANDATE_ID
          payment.metadata.mandateId = this.MOLLIE_MANDATE_ID
        }

        const transaction = await mollieCreateNewGptPayment(payment)

        if (transaction && transaction.data && transaction.data.error) {
          this.onDisplayError(transaction.data.message)
        }

        if (transaction && transaction.result && transaction.result.status === 'paid') {
          this.isGptActivationSuccessful = true
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
      }

      setTimeout(() => {
        this.isProcessing = false
        this.isGptActivationSuccessful = false
      }, 3000)
    },

    async onConfirmUpgrade() {
      await this.onUpgradeMollieSubscription()
    },

    onCloseUpgrade() {
      this.selectedPlan = null
      this.showUpgradeConfirmation = false
    },

    async onUpgradeMollieSubscription() {
      try {
        this.isProcessing = true

        const subscription = {
          companyId: this.companyData.id,
          mollieCustomerId: this.companyData.mollieCustomerId,
          mollieGptSubscriptionId: this.companyData.mollieGptSubscriptionId,
          metadata: {
            companyId: this.companyData.id,
            userId: this.activeUserInfo.uid,
            planId: this.selectedPlan.id,
            customerId: this.companyData.mollieCustomerId
          },
          redirectUrl: location.href,
          planId: this.selectedPlan.id,
          description: this.selectedPlan.description
        }
        const mollieUpdateGptSubscription = this.$functions.httpsCallable('mollieUpdateGptSubscription')
        const response = await mollieUpdateGptSubscription(subscription)
        if (response && response.data && response.data.error && response.data.message) {
          this.onDisplayError(response.data.message)
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
      }

      setTimeout(() => {
        this.isProcessing = false
      }, 2000)

      this.selectedPlan = null
      this.showUpgradeConfirmation = false
    },

    async onShowUpgradeConfirmation(plan) {
      this.selectedPlan = plan
      this.showUpgradeConfirmation = true
    },

    async onConfirmDowngrade() {
      this.showDowngradeConfirmation = false

      if (!this.selectedPlan) return

      try {
        this.isProcessing = true
        await this.$db.collection('company').doc(this.activeUserInfo.company).set({ newMollieGptSubscriptionPlanId: this.selectedPlan.id }, { merge: true })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
      }
      this.selectedPlan = null
      setTimeout(() => {
        this.isProcessing = false
      }, 2500)
    },
    onCloseDowngrade() {
      this.selectedPlan = null
      this.showDowngradeConfirmation = false
    },

    async onShowDowngradeConfirmation(plan) {
      this.selectedPlan = plan
      this.showDowngradeConfirmation = true
    },

    async onUpdateMollieSubscription(plan) {
      const upgrade = plan.level > this.CURRENT_PLAN_LEVEL
      if (upgrade) {
        this.onShowUpgradeConfirmation(plan)
      }
      const downgrade = plan.level < this.CURRENT_PLAN_LEVEL
      if (downgrade) {
        this.onShowDowngradeConfirmation(plan)
      }
    },

    async onCheckAndCreateMollieSubscription(plan) {
      if (!this.HAS_GPT_MOLLIE_SUBSCRIPTION) {
        await this.onCreateMollieSubscription(plan)
      } else {
        await this.onUpdateMollieSubscription(plan)
      }
    },

    async onChoosePlan(plan) {
      if (!this.companyData) {
        return
      }

      if (plan.isCurrentPlan) {
        return
      }

      if (plan.isNewPlan) {
        if (this.companyData.newMollieGptSubscriptionPlanId) {
          await this.$db
            .collection('company')
            .doc(this.activeUserInfo.company)
            .set({ newMollieGptSubscriptionPlanId: this.$firebase.firestore.FieldValue.delete() }, { merge: true })
        }
        return
      }

      if (this.HAS_INVOICE_SUBSCRIPTION) {
        await this.onCheckAndCreateInvoiceSubscription(plan)
        return
      }

      if (this.HAS_GPT_MOLLIE_SUBSCRIPTION && this.HAS_MOLLIE_MANDATE) {
        await this.onCheckAndCreateMollieSubscription(plan)
        return
      }

      this.showMollieGptAndUpdateUI(plan)
    },

    planChangeText(plan) {
      if (!this.HAS_GPT_SUBSCRIPTION) return 'gpt.getStarted'
      if (plan.isCurrentPlan) return 'gpt.activePlan'
      if (plan.isNewPlan) return 'vue.cancel'

      return plan.level < this.CURRENT_PLAN_LEVEL ? 'pricing.downgrade' : 'pricing.upgradeNow'
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Larsseit-Regular';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Regular.woff');
}
@font-face {
  font-family: 'Larsseit-Medium';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Medium.woff');
}

@font-face {
  font-family: 'Larsseit-Bold';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Bold.woff');
}
</style>

<style lang="scss" scoped>
$main-color: rgb(18, 89, 141);
$secondary-color: rgb(59, 134, 247);
$light-color: rgb(255, 255, 255);

.gpt-subscription {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 8px;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
    z-index: 999;

    &__loader {
      text-align: center;

      &__spinner {
        display: inline-block;
        width: 70px;
        height: 70px;
        border: 10px solid rgb(222, 241, 254);
        border-bottom-color: rgb(66, 181, 255);
        border-radius: 50%;
        animation: rotation 1s linear infinite;
      }

      &__title {
        margin-top: 28px;
        color: rgb(38, 38, 41);
        font-family: 'Larsseit-Bold';
        font-size: 28px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.155556px;
      }

      &__text {
        margin-top: 6px;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.111111px;
        color: rgb(83, 83, 91);
      }
    }
  }
}

.price-list {
  width: 1024px;
  max-width: 100%;
  margin: 0 auto;

  &__info {
    position: relative;
    margin-top: 26px;
    background-color: #fff;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &--more-info {
      margin-top: 10px;
      margin-left: 20px;
      margin-right: 20px;
      font-family: 'Larsseit-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.05px;
      color: #53535b;
    }

    &--see-more {
      font-family: 'Larsseit-Medium';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.05px;
      color: #3034f7;
      cursor: pointer;
    }
  }

  &-mollie-close-button {
    width: 15px;
    height: 15px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    &-icon {
      margin-left: -1px;
    }
  }

  &__heading {
    text-align: center;
    color: #262629;

    &-features {
      margin-top: 80px;
    }

    &__title {
      color: #262629;
      font-size: 30px;
      font-weight: 600;
      line-height: 38px;
      text-transform: capitalize;
    }

    &__description {
      margin-top: 15px;
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  &__plans {
    margin-top: 30px;
    &__heading {
      display: flex;
      justify-content: center;

      &__text {
        color: #53535b;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
      }
    }

    &-list {
      display: flex;
      justify-content: center;
      gap: 12px;
      width: 100%;
      @media (max-width: 991px) {
        flex-direction: column;
        align-items: center;
      }

      &__item {
        display: flex;
        overflow-wrap: break-word;
        flex-direction: column;
        margin-top: 43px;
        width: calc(100% / 3 - 24px / 4);

        @media (max-width: 991px) {
          margin-top: 26px;
          width: 100%;
          max-width: 480px;
        }

        &__downgrade {
          background: rgba(240, 85, 65, 0.3);
          margin-top: 20px;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          border-radius: 6px;
        }

        &__content {
          &__price {
            display: flex;
            justify-content: center;

            &__currency {
              padding-top: 3px;
              padding-right: 3px;
              color: rgb(38, 38, 41);
              font-family: 'Larsseit-Medium';
              font-size: 22px;
              line-height: 1;
              letter-spacing: -0.122222px;
            }

            &__amount {
              display: flex;
              align-items: flex-end;
              color: rgb(38, 38, 41);
              font-family: 'Larsseit-Bold';
              font-size: 40px;
              line-height: 0.9;
              letter-spacing: -0.222222px;

              &__period {
                color: rgb(83, 83, 91);
                font-family: 'Larsseit-Medium';
                font-size: 14px;
                line-height: 1;
                text-align: right;
                letter-spacing: -0.0777778px;
              }

              &--custom-price {
                color: #262629;
                text-transform: none;
                font-family: 'Larsseit-Bold';
                font-weight: 700;
                text-align: center;
                font-size: 20px;
                min-height: 36px;
              }
            }
          }

          &__label {
            position: absolute;
            top: 11px;
            left: 0;
            width: 100%;
            color: rgb(66, 181, 255);
            font-family: 'Larsseit-Bold';
            font-size: 12px;
            line-height: 12px;
            text-align: center;
            letter-spacing: 1.13333px;
            text-transform: uppercase;

            &--desktop {
              @media (max-width: 991px) {
                display: none;
              }
            }

            &--mobile {
              display: none;

              @media (max-width: 991px) {
                display: block;
              }
            }
          }
        }

        &__heading {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 48px;
          border-radius: 12px 12px 0 0;
          @media (max-width: 991px) {
            background: rgb(232, 246, 255);
          }
          &.price-list__plans-list__item__heading--active {
            background: #3034f7;
            .price-list__plans-list__item__heading__title {
              color: #fff;
            }
          }

          &__title {
            color: rgb(38, 38, 41);
            font-family: 'Larsseit-Bold';
            font-size: 22px;
            height: 28px;
            font-weight: 500;
            line-height: 28px;
            text-align: center;
            letter-spacing: -0.111111px;
            text-transform: capitalize;
          }

          &__label-summary {
            position: absolute;
            left: 0;
            width: 100%;
            bottom: -30px;
            text-align: center;
            color: #f05541;
            font-size: 0.8rem;
          }

          &__label-action {
            display: flex;
            justify-content: center;
            width: 100%;

            &__text {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 50px;
              color: white;
              font-family: 'Larsseit-Bold';
              font-size: 18px;
              line-height: 22px;
              letter-spacing: -0.1px;
              margin-top: 14px;
              background: #82ceff;
              border-radius: 8px;
              text-transform: capitalize;
              width: 100%;
              &.price-list__plans-list__item__heading__label-action__text--active {
                background: #3034f7;
              }
              &.price-list__plans-list__item__heading__label-action__text--disabled {
                background: rgba(130, 206, 255, 0.3);
                &:hover {
                  opacity: 0.98;
                  box-shadow: none;
                  transition: all 0.2s linear;
                  background: #c7c7c7;
                  cursor: default;
                }
              }
              &:hover {
                opacity: 0.98;
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
                transition: all 0.2s linear;
                cursor: pointer;
              }
            }

            &_active {
              .price-list__plans-list__item__heading__label-action {
                &__text {
                  background: #3034f7;

                  &:hover {
                    opacity: 1;
                    box-shadow: none;
                    cursor: default;
                  }
                }
              }
            }

            &_downgraded {
              .price-list__plans-list__item__heading__label-action {
                &__text {
                  background: #f05541;

                  &:hover {
                    opacity: 1;
                    box-shadow: none;
                    cursor: pointer;
                  }
                }
              }
            }

            &_selected {
              .price-list__plans-list__item__heading__label-action {
                &__text {
                  background: #3034f7;
                }
              }
            }

            &_inactive {
              .price-list__plans-list__item__heading__label-action {
                &__text {
                  background: #f05541;

                  &:hover {
                    opacity: 1;
                    box-shadow: none;
                    cursor: pointer;
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
                  }
                }
              }
            }
          }
        }

        &__body {
          flex: 1;
          position: relative;
          padding: 40px 30px 34px;
          width: 100%;
          background: rgb(244, 250, 255);
          border-radius: 12px;

          @media (max-width: 991px) {
            border-radius: 0 0 12px 12px;
          }

          &__options-list {
            margin-top: 23px;

            &__item {
              position: relative;
              margin-top: 15px;
              padding-left: 30px;
              color: #262629;
              font-size: 15px;
              line-height: 18px;
              letter-spacing: -0.0833333px;

              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;

              &--boldify {
                margin-top: 0;
                font-weight: 700;
              }
              &__title {
                font-weight: 600;
              }

              &__icon {
                position: absolute;
                top: 0;
                left: 0;
                font-weight: 600;
                color: #42b5ff;
                height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
