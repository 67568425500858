<template>
  <div class="lc-vega-module" :class="{ 'lc-vega-module--ht-100p': !HAS_SUBSCRIPTION }">
    <div class="lc-vega-module_info-wrapper">
      <div class="lc-vega-module_info__label">Upgrade Module</div>

      <div class="lc-vega-module_info">
        <div class="lc-vega-module_info--icon">
          <VegaLogo />
        </div>
        <div class="lc-vega-module_info_detail">
          <div class="lc-vega-module_info_detail--head">VEGA</div>
          <div class="lc-vega-module_info_detail--text">active since {{ vegaActivationDateText }}</div>
        </div>
      </div>
    </div>
    <div class="lc-vega-module_pricing">
      <span class="lc-vega-module_pricing--euro">€</span>
      <span class="lc-vega-module_pricing--price">{{ formattedVegaCostsPerVisitor }}</span>
      <span class="lc-vega-module_pricing--text"> /visitor</span>
      <VegaIcon class="lc-vega-module_pricing--icon" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
import VegaLogo from './VegaLogo.vue'
import VegaIcon from './VegaIcon.vue'
export default {
  name: 'VegaModule',
  components: {
    VegaLogo,
    VegaIcon
  },
  computed: {
    ...mapGetters({
      company: 'company'
    }),

    vegaCostPerVisitor() {
      return this.company && this.company.vegaCostPerVisitor ? this.company.vegaCostPerVisitor : this.$vegaCostPerVisitor
    },
    formattedVegaCostsPerVisitor() {
      // Check if vegaCostsPerVisitor is a valid number
      if (typeof this.vegaCostPerVisitor === 'number' && !isNaN(this.vegaCostPerVisitor)) {
        // Convert the number to a string with a period (.) as the decimal separator
        const formattedNumber = this.vegaCostPerVisitor.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })

        // Replace the period (.) with a comma (,)
        return formattedNumber.replace('.', ',')
      } else {
        // Return the original value if it's not a valid number
        return this.vegaCostPerVisitor
      }
    },
    vegaActivationDate() {
      return this.company && this.company.vegaActivationDate
    },
    vegaActivationDateText() {
      if (this.vegaActivationDate) {
        return dayjs.unix(this.vegaActivationDate.seconds).locale(this.$i18n.locale).format('LL')
      }
      return null
    },

    HAS_SUBSCRIPTION() {
      return this.HAS_MOLLIE_SUBSCRIPTION || this.HAS_INVOICE_SUBSCRIPTION || this.HAS_PADDLE_SUBSCRIPTION || this.HAS_APPSUMO_SUBSCRIPTION
    },

    HAS_INTERNAL_SUBSCRIPITON() {
      return this.HAS_MOLLIE_SUBSCRIPTION || this.HAS_INVOICE_SUBSCRIPTION || this.HAS_PADDLE_SUBSCRIPTION
    },

    HAS_APPSUMO_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company && this.company.isAppSumoAccount)
    },

    HAS_INVOICE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.currentInvoiceId)
    },

    HAS_MOLLIE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.mollieCustomerId && this.company.mollieSubscriptionId)
    },

    HAS_PADDLE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.subscriptionId && !this.company.mollieSubscriptionId && this.company.paymentStatus === 'subscription')
    }
  }
}
</script>

<style lang="scss" scoped>
.lc-vega-module {
  padding: 20px 40px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 10px 10px;
  min-height: 170px;
  height: 40%;

  display: flex;
  justify-content: space-between;

  &--ht-100p {
    height: 100% !important;
  }

  &_info {
    display: flex;
    justify-content: center;
    align-items: center;

    &__label {
      margin-bottom: 20px;
      color: #7E8186;
      font-size: 16px;
      font-weight: 600;
    }

    &_detail {
      margin-left: 20.72px;
      display: flex;
      flex-direction: column;

      &--head {
        color: #262629;
        font-size: 16px;
        font-weight: 700;
      }

      &--text {
        color: #7E8186;
      }
    }
  }

  &_pricing {
    position: relative;
    margin-right: 20px;
    align-self: center;

    display: flex;
    &--euro {
      color: #262629;
      font-family: Larsseit;
      font-size: 31px;
      font-style: normal;
      font-weight: 500;
      line-height: 31px;
      letter-spacing: -0.172px;
      z-index: 1;
    }
    &--price {
      color: #262629;
      font-family: Larsseit;
      font-size: 56.364px;
      font-style: normal;
      font-weight: 700;
      line-height: 54px;
      letter-spacing: -0.313px;
      z-index: 1;
    }
    &--text {
      color: #53535b;
      font-family: Larsseit;
      font-size: 19.727px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.545px;
      letter-spacing: -0.11px;
      align-self: flex-end;
      z-index: 1;
    }

    &--icon {
      position: absolute;
      z-index: 0;
      top: -17px;
      right: -38px;
    }
  }
}
</style>
