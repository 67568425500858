<template>
  <svg width="119" height="96" viewBox="0 0 119 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M81.752 84.9375C95.9668 84.9375 107.49 73.414 107.49 59.1992C107.49 44.9843 95.9668 33.4609 81.752 33.4609"
      stroke="#C8E2F3"
      stroke-width="5.99103"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path d="M113.926 91.3702L99.9307 77.375" stroke="#C8E2F3" stroke-width="5.99103" stroke-linecap="square" stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M90.009 0.128906L78.4174 30.5187C64.6043 32.6082 54.0172 44.5317 54.0172 58.9279C54.0172 65.8827 56.4881 72.2605 60.6 77.2311L55.6279 90.2666H34.3811L0 0.128906H23.5646L43.0086 53.0526L44.9401 59.491L47.0004 53.0526L66.4444 0.128906H90.009ZM60.0082 58.9279C60.0082 48.7734 66.6631 40.1734 75.8495 37.2512L63.1677 70.4991C61.1604 67.1092 60.0082 63.1531 60.0082 58.9279Z"
      fill="#C8E2F3"
    />
  </svg>
</template>
<script>
export default {
  name: 'VegaIcon'
}
</script>
