<template>
  <div class="lc-gpt-module" :class="{ 'lc-gpt-module--ht-100p': !HAS_GPT_SUBSCRIPTION }">
    <div class="lc-gpt-module_info-wrapper">
      <div class="lc-gpt-module_info__label">Upgrade Module</div>

      <div class="lc-gpt-module_info">
        <div class="lc-gpt-module_info--icon">
          <GptLogo />
        </div>
        <div class="lc-gpt-module_info_detail">
          <div class="lc-gpt-module_info_detail--head">
            GPT Connect
            <div class="lc-gpt-module_info_detail--head__plan">{{ CURRENT_PLAN_NAME }}</div>
          </div>
          <div class="lc-gpt-module_info_detail--text">active since {{ gptActivationDateText }}</div>
        </div>
      </div>
    </div>
    <!-- <div class="lc-gpt-module_pricing">
      <span class="lc-gpt-module_pricing--euro">€</span>
      <span class="lc-gpt-module_pricing--price">19</span>
      <span class="lc-gpt-module_pricing--text"> /mo</span>
    </div> -->
    <div class="lc-gpt-module_right-column">
      <div class="lc-gpt-module_pricing">
        <span class="lc-gpt-module_pricing--euro">€</span>
        <span class="lc-gpt-module_pricing--price">{{ CURRENT_PLAN_PRICE }}</span>
        <span class="lc-gpt-module_pricing--text"> /mo</span>
      </div>

      <div class="lc-gpt-module_right-column_upgrade" v-if="CURRENT_PLAN_IS_NOT_ENTERPRISE && !IS_GPT_SUBSCRIPTION_CANCELLED" @click="onShowGptPlans">
        <span class="mr-4">{{ $t('upgrade-messages.upgrade-plan') }}</span>
        <img src="@/assets/images/register-funnel/forward-arrow.png" width="18px" height="16.2px" alt="Next" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { gpt_subscription } from '@/mixins/gpt_subscription'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
import GptLogo from './GptLogo.vue'
export default {
  name: 'GptModule',
  mixins: [gpt_subscription],
  components: {
    GptLogo
  },
  computed: {
    ...mapGetters({
      company: 'company',
      gptSubscription: 'gpt/gptSubscription'
    }),

    filteredGptPlans() {
      return this.gptPlans.map((plan) => {
        const modifiedPlan = { ...plan }
        if (this.HAS_GPT_SUBSCRIPTION) {
          if (this.HAS_GPT_MOLLIE_SUBSCRIPTION && this.company.gptSubscriptionPlanId && this.company.gptSubscriptionPlanId === plan.id) {
            modifiedPlan.isCurrentPlan = true
            modifiedPlan.isEnterprise = this.company.gptSubscriptionPlanId === 'GPT_ENTERPRISE'
          }
          if (this.HAS_GPT_INVOICE_SUBSCRIPTION && this.company.gptSubscriptionPlanId && this.company.gptSubscriptionPlanId === plan.id) {
            modifiedPlan.isCurrentPlan = true
            modifiedPlan.isEnterprise = this.company.gptSubscriptionPlanId === 'GPT_ENTERPRISE'
          }
        }
        return modifiedPlan
      })
    },

    CURRENT_PLAN() {
      const plan = this.filteredGptPlans.find((x) => x.isCurrentPlan)
      return plan
    },

    CURRENT_PLAN_IS_NOT_ENTERPRISE() {
      if (!this.CURRENT_PLAN) return true
      return !this.CURRENT_PLAN.isEnterprise
    },

    CURRENT_PLAN_NAME() {
      if (!this.CURRENT_PLAN) return ''
      return this.CURRENT_PLAN.name
    },

    CURRENT_PLAN_PRICE() {
      if (!this.CURRENT_PLAN) return ''
      return this.CURRENT_PLAN.price
    },

    gptActivationDate() {
      return this.company && this.company.gptActivationDate
    },
    gptActivationDateText() {
      if (this.gptActivationDate) {
        return dayjs.unix(this.gptActivationDate.seconds).locale(this.$i18n.locale).format('LL')
      }
      return null
    },

    HAS_GPT_SUBSCRIPTION() {
      return (this.HAS_GPT_MOLLIE_SUBSCRIPTION && this.HAS_MOLLIE_MANDATE) || this.HAS_GPT_INVOICE_SUBSCRIPTION
    },

    IS_GPT_SUBSCRIPTION_CANCELLED() {
      if (this.company && this.company.gptPaymentStatus === 'subscription-cancelled') {
        return true
      }
      if (this.gptSubscription && (this.gptSubscription.subscriptionStatus === 'canceled' || this.gptSubscription.status === 'canceled')) {
        return true
      }
      return false
    },

    HAS_GPT_INVOICE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.gptCurrentInvoiceId)
    },

    HAS_MOLLIE_MANDATE() {
      if (!this.company) return false
      return this.company.mollieMandateId
    },

    HAS_GPT_MOLLIE_SUBSCRIPTION() {
      return (
        this.company &&
        Boolean(this.company.gptSubscriptionPlanId) &&
        Boolean(this.company.mollieGptSubscriptionId) &&
        Boolean(this.company.mollieCustomerId) &&
        this.company.gptPaymentStatus !== 'subscription-cancelled' &&
        this.company.gptExpiryDate &&
        dayjs().isBefore(dayjs.unix(this.company.gptExpiryDate.seconds))
      )
    }
  },
  methods: {
    onShowGptPlans() {
      this.$serverBus.$emit('show-gpt-connect')
    }
  }
}
</script>

<style lang="scss" scoped>
.lc-gpt-module {
  padding: 20px 40px;
  min-height: 170px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 10px 10px;
  height: 40%;

  display: flex;
  justify-content: space-between;

  &--ht-100p {
    height: 100% !important;
  }

  &_info {
    display: flex;
    justify-content: center;
    align-items: center;

    &__label {
      margin-bottom: 20px;
      color: #7e8186;
      font-size: 16px;
      font-weight: 600;
    }

    &_detail {
      margin-left: 20.72px;
      display: flex;
      flex-direction: column;

      &--head {
        display: flex;
        align-items: center;
        color: #262629;
        font-size: 16px;
        font-weight: 700;

        &__plan {
          margin-left: 6px;
          padding: 2px 25px;
          border-radius: 6px;
          background: #275D73;
          color: #fff;
          text-align: center;
          font-family: Lato;
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: 15px;
          letter-spacing: -0.111px;
        }
      }

      &--text {
        color: #7e8186;
      }
    }
  }

  &_pricing {
    position: relative;
    margin-right: 20px;
    align-self: center;

    display: flex;
    &--euro {
      color: #262629;
      font-family: Larsseit;
      font-size: 31px;
      font-style: normal;
      font-weight: 500;
      line-height: 31px;
      letter-spacing: -0.172px;
      z-index: 1;
    }
    &--price {
      color: #262629;
      font-family: Larsseit;
      font-size: 56.364px;
      font-style: normal;
      font-weight: 700;
      line-height: 54px;
      letter-spacing: -0.313px;
      z-index: 1;
    }
    &--text {
      color: #53535b;
      font-family: Larsseit;
      font-size: 19.727px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.545px;
      letter-spacing: -0.11px;
      align-self: flex-end;
      z-index: 1;
    }

    &--icon {
      position: absolute;
      z-index: 0;
      top: -17px;
      right: -38px;
    }
  }

  &_right-column {
    position: relative;
    display: flex;
    flex-direction: column;

    &_upgrade {
      margin-top: 24px;
      border-radius: 8px;
      background: #3034f7;
      color: #fff;
      padding: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 200px;
      cursor: pointer;
    }
  }
}
</style>
