<template>
  <div class="lc-payment-details">
    <div v-if="company.isAppSumoAccount" class="lc-payment-details_details">
      <div class="lc-sub-details--label">{{ $t('vue.lastPayment') }}</div>
      <div class="lc-payment-details_details--text">{{ lastPaymentCurrency }} {{ lastPaymentAmount }},-</div>
      <div class="lc-payment-details_details--text">on {{ lastPaymentDate }}</div>

      <div class="lc-sub-details--label mtop-30">{{ $t('vue.nextPayment') }}</div>
      <div class="lc-payment-details_details--text">{{ $t('pricing.noPaymentPlanned') }}</div>

      <template v-if="WAS_GPT_ACTIVATED && lastGptPayment">
        <div class="lc-sub-details--label mtop-30">{{ $t('vue.lastGptPayment') }}</div>
        <div class="lc-payment-details_details--text">€ {{ lastGptPaymentAmount }},-</div>
        <div class="lc-payment-details_details--text">on {{ lastGptPaymentDate }}</div>
      </template>

      <template v-if="WAS_GPT_ACTIVATED && !IS_GPT_SUBSCRIPTION_CANCELLED">
        <div class="lc-sub-details--label mtop-30">{{ $t('vue.nextGptConnectPayment') }}</div>
        <div class="lc-payment-details_details--text">
          € {{ nextGptPaymentAmount }},- <br />
          on {{ nextGptPaymentDate }}
        </div>
      </template>

      <template v-if="gptPaymentCancelledAt">
        <div class="lc-sub-details--label mtop-30">GPT Connect Canceled</div>
        <div class="lc-payment-details_details--text">on {{ gptPaymentCancelledAt }}</div>
      </template>

      <template v-if="WAS_VEGA_ACTIVATED && lastVegaPayment">
        <div class="lc-sub-details--label mtop-30">{{ $t('vue.lastVegaPayment') }}</div>
        <div class="lc-payment-details_details--text">€ {{ lastVegaPaymentAmount }},-</div>
        <div class="lc-payment-details_details--text">on {{ lastVegaPaymentDate }}</div>
      </template>

      <template v-if="WAS_VEGA_ACTIVATED">
        <div class="lc-sub-details--label mtop-30">{{ $t('vue.nextVegaPayment') }}</div>
        <div class="lc-payment-details_details--current-costs" @click="$serverBus.$emit('show-vega')">
          <RightArrowIcon />
          <span>{{ $t('vega.subscription.checkCurrentVegaCosts') }}</span>
        </div>
        <div class="lc-payment-details_details--text">on {{ nextVegaPaymentDate }}</div>
      </template>

      <div class="lc-sub-details--label mtop-30">{{ $t('vue.planType') }}</div>
      <div class="lc-payment-details_details--text">{{ $t('pricing.lifetime') }}</div>
    </div>

    <div v-else class="lc-payment-details_details">
      <template v-if="!canceledDate && lastPaymentDate && !IS_SUBSCRIPTION_CANCELLED">
        <div class="lc-sub-details--label">{{ $t('vue.lastLetsConnectPayment') }}</div>
        <div class="lc-payment-details_details--text">{{ lastPaymentCurrency }} {{ lastPaymentAmount }},-</div>
        <div class="lc-payment-details_details--text">on {{ lastPaymentDate }}</div>
      </template>

      <template v-if="canceledDate">
        <div class="lc-sub-details--label mtop-30">Let's Connect Canceled</div>
        <div class="lc-payment-details_details--text">on {{ canceledDate }}</div>
      </template>

      <template v-if="!canceledDate && nextPaymentDate">
        <div class="lc-sub-details--label mtop-30">{{ $t('vue.nextLetsConnectPayment') }}</div>
        <div class="lc-payment-details_details--text">{{ nextPaymentCurrency }} {{ nextPaymentAmount }},-</div>
        <div class="lc-payment-details_details--text">on {{ nextPaymentDate }}</div>
      </template>

      <template v-if="!nextPaymentDate && HAS_FREE_SUBSCRIPTION && !WAS_VEGA_ACTIVATED">
        <div class="lc-sub-details--label mtop-30">{{ $t('vue.nextLetsConnectPayment') }}</div>
        <div class="lc-payment-details_details--text">{{ $t('pricing.noPaymentPlanned') }}</div>
      </template>

      <template v-if="WAS_VEGA_ACTIVATED">
        <div class="lc-sub-details--label mtop-30">{{ $t('vue.nextVegaPayment') }}</div>
        <div class="lc-payment-details_details--current-costs" @click="$serverBus.$emit('show-vega')">
          <RightArrowIcon />
          <span>{{ $t('vega.subscription.checkCurrentVegaCosts') }}</span>
        </div>
        <div class="lc-payment-details_details--text">on {{ nextVegaPaymentDate }}</div>
      </template>

      <template v-if="WAS_GPT_ACTIVATED && lastGptPayment">
        <div class="lc-sub-details--label mtop-30">{{ $t('vue.lastGptPayment') }}</div>
        <div class="lc-payment-details_details--text">€ {{ lastGptPaymentAmount }},-</div>
        <div class="lc-payment-details_details--text">on {{ lastGptPaymentDate }}</div>
      </template>

      <template v-if="WAS_GPT_ACTIVATED && !IS_GPT_SUBSCRIPTION_CANCELLED">
        <div class="lc-sub-details--label mtop-30">{{ $t('vue.nextGptConnectPayment') }}</div>
        <div class="lc-payment-details_details--text">
          € {{ nextGptPaymentAmount }},- <br />
          on {{ nextGptPaymentDate }}
        </div>
      </template>

      <template v-if="gptPaymentCancelledAt">
        <div class="lc-sub-details--label mtop-30">GPT Connect Canceled</div>
        <div class="lc-payment-details_details--text">on {{ gptPaymentCancelledAt }}</div>
      </template>

      <template v-if="['subscription-cancelled', 'deleted'].includes(company.paymentStatus)">
        <div class="lc-sub-details--label mtop-30">{{ $t('vue.expiryDate') }}</div>
        <div class="lc-payment-details_details--text">on {{ expiryDate }}</div>
      </template>

      <template v-if="planPeriod">
        <div class="lc-sub-details--label mtop-30">{{ $t('vue.planType') }}</div>
        <div class="lc-payment-details_details--text capitalize">{{ planPeriod }}</div>
      </template>
    </div>
    <div class="lc-payment-details_invoices-wrapper">
      <div class="lc-payment-details_invoices" @click="onShowInvoices">
        <span class="mr-4">Invoices</span>
        <img src="@/assets/images/register-funnel/forward-arrow.png" width="18px" height="16.2px" alt="Next" />
      </div>
    </div>
  </div>
</template>
<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

import { mapGetters } from 'vuex'
import { appsumo_subscription } from '@/mixins/appsumo_subscription'
import RightArrowIcon from '@/components/icons/RightArrowIcon.vue'

export default {
  name: 'PaymentDetail',
  mixins: [appsumo_subscription],
  data() {
    return {
      symbols: {
        EUR: '€',
        AUD: 'A$',
        USD: '$',
        GBP: '£'
      }
    }
  },
  components: {
    RightArrowIcon
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      allUsers: 'users',
      company: 'company',
      subscription: 'subscription',
      pathadviceInvoices: 'invoice/pathadviceInvoices',
      gptSubscription: 'gpt/gptSubscription'
    }),

    isAppSumoAccount() {
      return this.company && this.company.isAppSumoAccount
    },

    HAS_FREE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.subscriptionPlanId && this.company.subscriptionPlanId.indexOf('FREE') > -1 && this.company.paymentStatus === 'subscription')
    },

    IS_SUBSCRIPTION_CANCELLED() {
      return this.company && ['subscription-cancelled', 'deleted'].includes(this.company.paymentStatus)
    },

    HAS_GPT_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.gptSubscriptionPlanId)
    },

    IS_GPT_ACTIVATED() {
      if (!this.company) return false
      return this.company.gptIsActivated
    },

    WAS_GPT_ACTIVATED() {
      return this.HAS_GPT_SUBSCRIPTION && this.IS_GPT_ACTIVATED
    },

    IS_GPT_SUBSCRIPTION_CANCELLED() {
      if (this.company && this.company.gptPaymentStatus === 'subscription-cancelled') {
        return true
      }
      if (this.gptSubscription && (this.gptSubscription.subscriptionStatus === 'canceled' || this.gptSubscription.status === 'canceled')) {
        return true
      }
      return false
    },

    WAS_VEGA_ACTIVATED() {
      if (this.company && this.company.vegaIsActivated && !this.company.vegaIsOnTrial) {
        return true
      }

      return false
    },

    nextGptPaymentAmount() {
      if (this.gptSubscription && this.gptSubscription.next_payment && this.gptSubscription.next_payment.amount) {
        return this.gptSubscription.next_payment.amount
      }
      return null
    },

    nextGptPaymentDate() {
      if (!this.gptSubscription || !this.gptSubscription.nextPaymentDate) return null
      return dayjs(this.gptSubscription.nextPaymentDate).locale(this.$i18n.locale).format('LL')
    },

    gptPaymentCancelledAt() {
      if (!this.gptSubscription || !this.gptSubscription.canceledAt) return null

      if (this.gptSubscription.status === 'canceled') {
        return dayjs(this.gptSubscription.canceledAt).locale(this.$i18n.locale).format('LL')
      }

      if (this.gptSubscription.subscriptionStatus === 'canceled') {
        return dayjs.unix(this.gptSubscription.canceledAt.seconds).locale(this.$i18n.locale).format('LL')
      }

      return null
    },

    nextVegaPaymentDate() {
      return dayjs().utc().add(1, 'month').date(1).locale(this.$i18n.locale).format('LL')
    },

    planPeriod() {
      if (this.subscription && this.subscription.planDetails && this.subscription.planDetails.billing_type) {
        return this.subscription.planDetails.billing_type === 'year' ? this.$i18n.t('pricing.yearly') : this.$i18n.t('pricing.monthly')
      }
      if (this.HAS_FREE_SUBSCRIPTION) {
        return this.company.subscriptionPlanId === 'M_ANNUAL_FREE' ? this.$i18n.t('pricing.yearly') : this.$i18n.t('pricing.monthly')
      }
      return ''
    },

    expiryDate() {
      return dayjs.unix(this.company.expiryDate.seconds).tz(this.$defaultTimezone).locale(this.$i18n.locale).format('LL')
    },

    lastPayment() {
      if (this.company && this.company.mollieSubscriptionId) {
        try {
          const invoices = this.pathadviceInvoices.filter((x) => {
            return (
              x.companyId === this.activeUserInfo.company &&
              x.amount &&
              Number(x.amount.value) > 0 &&
              x.metadata &&
              x.metadata.source !== 'vega-payment' &&
              x.metadata.source !== 'gpt-payment'
            )
          })
          const invoice = invoices.reduce((prev, current) => {
            return prev.createdAt.seconds > current.createdAt.seconds ? prev : current
          })

          return invoice
        } catch (error) {
          /* eslint-disable no-console */
          console.log(error.message)
          return null
        }
      }

      if (this.company && this.company.currentInvoiceId && this.subscription && this.subscription.lastPayment) {
        return this.subscription.lastPayment
      }

      return null
    },

    lastVegaPayment() {
      if (this.company && this.company.mollieSubscriptionId) {
        try {
          const invoices = this.pathadviceInvoices.filter((x) => {
            return x.companyId === this.activeUserInfo.company && x.amount && Number(x.amount.value) > 0 && x.metadata && x.metadata.source === 'vega-payment'
          })
          const invoice = invoices.reduce((prev, current) => {
            return prev.createdAt.seconds > current.createdAt.seconds ? prev : current
          })

          return invoice
        } catch (error) {
          /* eslint-disable no-console */
          console.log(error.message)
          return null
        }
      }
      return null
    },

    lastGptPayment() {
      if (this.company && this.company.mollieGptSubscriptionId) {
        try {
          const invoices = this.pathadviceInvoices.filter((x) => {
            return x.companyId === this.activeUserInfo.company && x.amount && Number(x.amount.value) > 0 && x.metadata && x.metadata.source === 'gpt-payment'
          })
          const invoice = invoices.reduce((prev, current) => {
            return prev.createdAt.seconds > current.createdAt.seconds ? prev : current
          })

          return invoice
        } catch (error) {
          /* eslint-disable no-console */
          console.log(error.message)
          return null
        }
      }
      return null
    },

    lastGptPaymentAmount() {
      if (this.lastGptPayment && this.lastGptPayment.amount) {
        return Number(this.lastGptPayment.amount.value).toFixed(2)
      }
      return null
    },

    lastGptPaymentDate() {
      if (this.lastGptPayment && this.lastGptPayment.paidAt) {
        return dayjs(this.lastGptPayment.paidAt).locale(this.$i18n.locale).format('LL')
      }
      return null
    },

    lastVegaPaymentDate() {
      if (this.lastVegaPayment && this.lastVegaPayment.paidAt) {
        return dayjs(this.lastVegaPayment.paidAt).locale(this.$i18n.locale).format('LL')
      }
      return null
    },

    lastPaymentDate() {
      try {
        if (this.company && this.company.created && this.company.isAppSumoAccount) {
          return dayjs.unix(this.company.created.seconds).locale(this.$i18n.locale).format('LL')
        }

        if (this.company && this.company.mollieSubscriptionId) {
          if (this.lastPayment) {
            return dayjs(this.lastPayment.paidAt).locale(this.$i18n.locale).format('LL')
          }
          return null
        }

        if (this.company && this.company.currentInvoiceId && this.subscription && this.subscription.lastPayment) {
          return dayjs.unix(this.subscription.lastPaymentDate.seconds).locale(this.$i18n.locale).format('LL')
        }

        if (this.company && this.company.subscriptionId && this.subscription && this.subscription.last_payment) {
          return dayjs(this.subscription.last_payment.date).locale(this.$i18n.locale).format('LL')
        }

        return null
      } catch (error) {
        console.log(error.message)
      }
      return null
    },

    lastPaymentAmount() {
      if (this.company && this.company.subscriptionPlanId && this.company.isAppSumoAccount) {
        const plan = this.appSumoPlans.find((x) => x.id === this.company.subscriptionPlanId)
        if (plan) {
          return Number(plan.price)
        }
      }

      if (this.lastPayment && this.company && this.company.mollieSubscriptionId && this.lastPayment.amount) {
        return Number(this.lastPayment.amount.value)
      }

      if (this.company && this.company.currentInvoiceId && this.subscription && this.subscription.lastPayment) {
        return this.subscription.lastPayment['EUR']
      }

      if (this.company && this.company.subscriptionId && this.subscription && this.subscription.last_payment) {
        return Number(this.subscription.last_payment.amount)
      }

      return null
    },
    lastPaymentCurrency() {
      if (this.company && this.company.subscriptionPlanId && this.company.isAppSumoAccount) {
        const plan = this.appSumoPlans.find((x) => x.id === this.company.subscriptionPlanId)
        if (plan) {
          return '$'
        }
      }

      if (this.lastPayment && this.company && this.company.mollieSubscriptionId && this.lastPayment.amount && this.lastPayment.amount.currency) {
        return this.symbols[this.lastPayment.amount.currency]
      }

      if (this.company && this.company.currentInvoiceId && this.subscription && this.subscription.lastPayment) {
        return this.symbols.EUR
      }

      if (this.company && this.company.subscriptionId && this.subscription && this.subscription.last_payment) {
        return this.symbols[this.subscription.last_payment.currency]
      }

      return null
    },

    nextPaymentDate() {
      if (
        this.company &&
        this.company.mollieSubscriptionId &&
        this.subscription &&
        this.subscription.nextPaymentDate &&
        this.subscription.next_payment &&
        !this.subscription.canceledAt
      ) {
        return dayjs(this.subscription.nextPaymentDate).locale(this.$i18n.locale).format('LL')
      }

      if (this.company && this.company.currentInvoiceId && this.subscription && this.subscription.nextPaymentDate) {
        return dayjs.unix(this.subscription.nextPaymentDate.seconds).locale(this.$i18n.locale).format('LL')
      }
      if (this.company && this.company.subscriptionId && this.subscription && this.subscription.next_payment) {
        return dayjs(this.subscription.next_payment.date).locale(this.$i18n.locale).format('LL')
      }

      return null
    },

    nextPaymentAmount() {
      if (
        this.company &&
        this.company.mollieSubscriptionId &&
        this.subscription &&
        this.subscription.nextPaymentDate &&
        this.subscription.next_payment &&
        !this.subscription.canceledAt
      ) {
        return Number(this.subscription.next_payment.amount)
      }

      if (this.company && this.company.currentInvoiceId && this.subscription && this.subscription.nextPayment) {
        return this.subscription.nextPayment['EUR']
      }

      if (this.company && this.company.subscriptionId && this.subscription && this.subscription.next_payment) {
        return Number(this.subscription.next_payment.amount)
      }
      return null
    },

    nextPaymentCurrency() {
      if (
        this.company &&
        this.company.mollieSubscriptionId &&
        this.subscription &&
        this.subscription.nextPaymentDate &&
        this.subscription.next_payment &&
        !this.subscription.canceledAt
      ) {
        return this.symbols[this.subscription.next_payment.currency]
      }

      if (this.company && this.company.subscriptionId && this.subscription && this.subscription.next_payment) {
        return this.symbols[this.subscription.next_payment.currency]
      }

      if (this.company && this.company.currentInvoiceId) {
        return this.symbols['EUR']
      }

      return null
    },

    canceledDate() {
      if (this.company && this.company.mollieSubscriptionId && this.subscription && this.subscription.status === 'canceled') {
        return dayjs(this.subscription.canceledAt).locale(this.$i18n.locale).format('LL')
      }
      if (this.company && this.company.currentInvoiceId && this.subscription && this.subscription.subscriptionStatus === 'canceled') {
        return dayjs.unix(this.subscription.canceledAt.seconds).locale(this.$i18n.locale).format('LL')
      }

      return null
    }
  },

  methods: {
    onShowInvoices() {
      this.$serverBus.$emit('show-invoices')
    },
    getMonthAbbreviation(month, year) {
      // Create a dayjs object with the month and a fixed year (since we only care about the month)
      const monthDate = dayjs(`${year}-${month}-01`, 'YYYY-MM-DD')

      // Format the month as a three-letter abbreviation
      const monthAbbreviation = monthDate.format('MMM').toUpperCase()

      return monthAbbreviation
    }
  }
}
</script>

<style lang="scss" scoped>
.lc-payment-details {
  display: flex;
  flex-direction: column;

  .capitalize {
    text-transform: capitalize;
  }

  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 10px 10px;
  flex-grow: 1;
  padding: 40px;

  &_invoices {
    align-self: flex-end;
    border-radius: 8px;
    background: #3034f7;
    color: #fff;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    width: 156px;
    cursor: pointer;

    &-wrapper {
      margin: auto 0 0 auto;
      padding-top: 30px;
    }
  }

  &_details {
    flex-direction: column;

    &--current-costs {
      color: #275D73;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 110% */
      letter-spacing: -0.111px;
      vertical-align: middle;
      cursor: pointer;

      span {
        margin-left: 5px;
      }
    }

    &--text {
      color: #262629;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 110% */
      letter-spacing: -0.111px;
    }
  }
}
</style>
