<template>
  <svg width="107" height="49" viewBox="0 0 107 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_9739_1437)">
      <rect x="2" y="9" width="96" height="36" rx="6" fill="#EBF5FE" />
    </g>
    <path
      d="M27.1686 20.9252L21.432 35.9648H17.887L12.1504 20.9252H16.0822L19.3265 29.7556L19.6487 30.8299L19.9925 29.7556L23.2368 20.9252H27.1686ZM40.1032 20.9252V24.3843H34.8608V26.5328H39.3083V29.9919H34.8608V32.5057H40.1032V35.9648H31.1439V20.9252H40.1032ZM51.9703 20.6459C56.0096 20.6459 58.2655 22.9448 59.0605 26.0601H54.9998C54.5056 24.771 53.6677 24.105 51.9059 24.105C49.7144 24.105 48.2534 25.9098 48.2534 28.4235C48.2534 30.9588 49.7144 32.785 52.0778 32.785C53.8181 32.785 54.8923 32.033 55.1072 30.9803H52.2282V27.5211H59.082V29.0681C59.082 33.6874 56.0955 36.2442 52.0778 36.2442C47.5229 36.2442 44.386 32.9569 44.386 28.4235C44.386 23.9116 47.5229 20.6459 51.9703 20.6459ZM71.3966 20.9252L77.0687 35.9648H73.0724L72.0626 33.1288H66.5624L65.5526 35.9648H61.5563L67.2284 20.9252H71.3966ZM69.3125 25.3082L69.0332 26.1891L67.7011 29.9705H70.9239L69.5918 26.1891L69.3125 25.3082Z"
      fill="#0C1625"
    />
    <path d="M74.9873 30.6719L77.9508 30.6719" stroke="#0C1625" stroke-width="0.74087" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M71.7139 26.9648L76.1591 26.9648" stroke="#0C1625" stroke-width="0.74087" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M71.7139 23.6328L80.2339 23.6328" stroke="#0C1625" stroke-width="0.74087" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M73.9365 34.3711L80.8513 34.3711" stroke="#0C1625" stroke-width="0.74087" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M71.1221 23.2578L71.1221 17.7013" stroke="#0C1625" stroke-width="0.74087" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M86.4707 21.7812L86.4707 19.5586" stroke="#0C1625" stroke-width="0.74087" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M86.5988 25.6058C87.5535 25.6058 88.3275 24.8319 88.3275 23.8771C88.3275 22.9224 87.5535 22.1484 86.5988 22.1484C85.6441 22.1484 84.8701 22.9224 84.8701 23.8771C84.8701 24.8319 85.6441 25.6058 86.5988 25.6058Z"
      stroke="#0C1625"
      stroke-width="0.74087"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M71.0353 17.4574C71.9901 17.4574 72.764 16.6834 72.764 15.7287C72.764 14.774 71.9901 14 71.0353 14C70.0806 14 69.3066 14.774 69.3066 15.7287C69.3066 16.6834 70.0806 17.4574 71.0353 17.4574Z"
      stroke="#0C1625"
      stroke-width="0.74087"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M79.6828 32.5238C80.6375 32.5238 81.4115 31.7498 81.4115 30.7951C81.4115 29.8404 80.6375 29.0664 79.6828 29.0664C78.7281 29.0664 77.9541 29.8404 77.9541 30.7951C77.9541 31.7498 78.7281 32.5238 79.6828 32.5238Z"
      stroke="#0C1625"
      stroke-width="0.74087"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M86.5972 25.6094C86.5972 26.9848 86.0508 28.3039 85.0783 29.2765C84.1057 30.2491 82.7866 30.7955 81.4111 30.7955"
      stroke="#0C1625"
      stroke-width="0.74087"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="97.5" cy="9.5" r="9.5" fill="#2BFF6F" />
    <path d="M101.6 7L96.375 12.7L94 10.1091" stroke="white" stroke-width="1.9" stroke-linecap="round" stroke-linejoin="round" />
    <defs>
      <filter id="filter0_d_9739_1437" x="0" y="9" width="100" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9739_1437" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9739_1437" result="shape" />
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'VegaLogo'
}
</script>
