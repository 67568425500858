var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pav-subscription" },
    [
      !_vm.HAS_ACCESS ? _c("vega-message") : _vm._e(),
      _vm.isProcessing
        ? _c("div", { staticClass: "pav-subscription__loading" }, [
            _c("div", { staticClass: "pav-subscription__loading__loader" }, [
              _c("span", {
                staticClass: "pav-subscription__loading__loader__spinner",
              }),
              _c(
                "div",
                { staticClass: "pav-subscription__loading__loader__text" },
                [_vm._v(_vm._s(_vm.loaderSpinnerText))]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.isVegaActivationSuccessful
        ? _c("div", { staticClass: "pav-subscription__loading" }, [
            _c("div", { staticClass: "pav-subscription__loading__loader" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/register-funnel/check.png"),
                  alt: "Success",
                },
              }),
              _c(
                "div",
                { staticClass: "pav-subscription__loading__loader__title" },
                [_vm._v(_vm._s(_vm.$t("vega.subscription.thankYou")))]
              ),
              _c(
                "div",
                { staticClass: "pav-subscription__loading__loader__text" },
                [_vm._v(_vm._s(_vm.$t("vega.subscription.thankYouMessage")))]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.hasQuerySourceVega &&
      _vm.hasVegaPaymentFailed &&
      !_vm.isPaymentFailedClosed
        ? _c("div", { staticClass: "pav-subscription__loading" }, [
            _c(
              "div",
              {
                staticClass: "pav-subscription__close",
                on: { click: _vm.onClosePaymentFailedUI },
              },
              [
                _c("close-icon", {
                  attrs: { color: "#000000", width: 35, height: 35 },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "pav-subscription__loading__loader" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/register-funnel/payment_failed.png"),
                  alt: "Payment Failed",
                },
              }),
              _c(
                "div",
                { staticClass: "pav-subscription__loading__loader__title" },
                [_vm._v(_vm._s(_vm.$t("promocode.somethingWentWrong")))]
              ),
              _c(
                "div",
                { staticClass: "pav-subscription__loading__loader__text" },
                [_vm._v(_vm._s(_vm.$t("promocode.paymentCouldNotBeProcessed")))]
              ),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "pav-subscription--header" }, [
        _vm._v(
          "\n    " + _vm._s(_vm.$t("vega.subscription.pricingHeader")) + "\n  "
        ),
      ]),
      _c("div", { staticClass: "pav-subscription--description" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("vega.subscription.pricingDescription")) +
            "\n  "
        ),
      ]),
      _c("div", { staticClass: "pav-subscription-features" }, [
        _c(
          "div",
          { staticClass: "pav-subscription-features--item" },
          [
            _c("CheckIcon", {
              staticClass: "pav-subscription-features--item--icon",
            }),
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("vega.subscription.pricingFeature1")) +
                "\n    "
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pav-subscription-features--item" },
          [
            _c("CheckIcon", {
              staticClass: "pav-subscription-features--item--icon",
            }),
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("vega.subscription.pricingFeature2")) +
                "\n    "
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pav-subscription-features--item" },
          [
            _c("CheckIcon", {
              staticClass: "pav-subscription-features--item--icon",
            }),
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("vega.subscription.pricingFeature3")) +
                "\n    "
            ),
          ],
          1
        ),
      ]),
      _vm.showGetStarted
        ? [
            _c("div", { staticClass: "pav-subscription-usage" }, [
              _c("div", { staticClass: "pav-subscription-usage-box" }, [
                _c("div", { staticClass: "pav-subscription-usage-box-item1" }, [
                  _c("div", {
                    staticClass: "pav-subscription-usage-box-item1--header",
                  }),
                  _c(
                    "div",
                    { staticClass: "pav-subscription-usage-box-item1--info" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("vega.subscription.vegaAnalysing")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "pav-subscription-usage-box-item2" }, [
                  _c(
                    "div",
                    { staticClass: "pav-subscription-usage-box-item2--header" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("vega.subscription.usage")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "pav-subscription-usage-box-item2--info" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pav-subscription-usage-box-item2--info--slider",
                        },
                        [
                          !_vm.isVegaActivationSuccessful
                            ? _c(
                                "div",
                                [
                                  _c("vs-slider", {
                                    attrs: { step: "100", min: 10, max: 10000 },
                                    model: {
                                      value: _vm.totalVisitors,
                                      callback: function ($$v) {
                                        _vm.totalVisitors = $$v
                                      },
                                      expression: "totalVisitors",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("vega.subscription.websiteVisitors")
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pav-subscription-usage-box-item2--info--text",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.totalVisitors) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "pav-subscription-usage-box-item3" }, [
                  _c(
                    "div",
                    { staticClass: "pav-subscription-usage-box-item3--header" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("vega.subscription.monthlyCost")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "pav-subscription-usage-box-item3--info" },
                    [_vm._v("€ " + _vm._s(_vm.totalCost))]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "pav-subscription-usage--cost" }, [
                _vm._v("\n        " + _vm._s(_vm.totalCostText) + "\n      "),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "pav-subscription--button",
                on: { click: _vm.onGetStarted },
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("vega.getStarted")) + "\n    "
                ),
              ]
            ),
            _c("div", { staticClass: "pav-subscription--info" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("vega.needMoreInfo")) + "\n    "
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "pav-subscription--link",
                on: { click: _vm.onGoToSeeMore },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("vega.seeMore")) + "\n    ")]
            ),
          ]
        : _vm._e(),
      _vm.showActivationInfo
        ? [
            _c("div", { staticClass: "pav-subscription--dailylimit" }, [
              _c(
                "div",
                { staticClass: "pav-subscription--dailylimit--switch" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "ml-2 mr-2 message-container-upper pointer",
                      staticStyle: { "margin-top": "5px" },
                      on: { click: _vm.onSetDailyLimit },
                    },
                    [
                      _vm.vegaHasDailyLimit
                        ? _c("switch-on-icon", {
                            attrs: { color: "#3034F7", width: 41, height: 20 },
                          })
                        : _vm._e(),
                      !_vm.vegaHasDailyLimit
                        ? _c("switch-off-icon", {
                            attrs: { color: "#B2B2B2", width: 41, height: 20 },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", [
                    _vm._v(_vm._s(_vm.$t("vega.subscription.setDailyLimit"))),
                  ]),
                ]
              ),
              _vm.vegaHasDailyLimit
                ? _c(
                    "div",
                    { staticClass: "pav-subscription--dailylimit--box" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pav-subscription--dailylimit--box--label",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("vega.subscription.setYourDailyLimit")
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      !_vm.isDailyLimitEditMode
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "pav-subscription--dailylimit--box--price",
                            },
                            [_vm._v(_vm._s(_vm.vegaDailyLimit) + " €")]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "pav-subscription--dailylimit--box--input",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.vegaDailyLimit,
                                    expression: "vegaDailyLimit",
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  min: _vm.$vegaCostPerVisitor,
                                  step: ".01",
                                },
                                domProps: { value: _vm.vegaDailyLimit },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.vegaDailyLimit = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" €\n        "),
                            ]
                          ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pav-subscription--dailylimit--box--icon",
                          on: { click: _vm.onEditDailyLimit },
                        },
                        [
                          !_vm.isDailyLimitEditMode
                            ? _c("PencilIcon")
                            : _c("SaveIcon"),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "pav-subscription--dailylimit--description" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("vega.subscription.dailyLimitDescription")
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "pav-subscription--costs" }, [
              _c(
                "div",
                { staticClass: "pav-subscription--costs--month" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pav-subscription--costs--month--select",
                      on: { click: _vm.onShowSelectMonth },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pav-subscription--costs--month--select--icon",
                        },
                        [_c("CalendarIcon")],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pav-subscription--costs--month--select--text",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.currentSelectedMonth) +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pav-subscription--costs--month--select--dropdown",
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "9",
                                height: "5",
                                viewBox: "0 0 9 5",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: { d: "M8 1L4.5 4L1 1", fill: "#275D73" },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M8 1L4.5 4L1 1H8Z",
                                  stroke: "#275D73",
                                  "stroke-width": "2",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("transition", { attrs: { name: "fade-bottom-2x" } }, [
                    _vm.showSelectMonth
                      ? _c(
                          "div",
                          {
                            staticClass: "pav-subscription--costs--month--box",
                          },
                          [
                            _vm.showSelectMonth
                              ? _c(
                                  "scroll-view",
                                  {
                                    staticClass:
                                      "pav-subscription--costs--month--box--scroll",
                                  },
                                  _vm._l(_vm.monthList, function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "pav-subscription--costs--month--box--scroll--item",
                                        class: {
                                          "pav-subscription--costs--month--box--scroll--item--selected":
                                            item.text ===
                                            _vm.selectedMonth.text,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onSelectMonth(item)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.text) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "pav-subscription--costs--cost" }, [
                _c(
                  "div",
                  { staticClass: "pav-subscription--costs--cost--icon" },
                  [
                    _c(
                      "div",
                      { staticClass: "pav-subscription--costs--cost--info" },
                      [
                        _c("VegaPerAnalyzedIcon"),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pav-subscription--costs--cost--info--text",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pav-subscription--costs--cost--info--text--heading",
                              },
                              [_vm._v(_vm._s(_vm.vegaCostPerVisitor) + "€")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pav-subscription--costs--cost--info--text--subtext",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "vega.subscription.perAnalyzedWebsiteVisitor"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "pav-subscription--costs--cost--current" },
                  [_vm._v(_vm._s(_vm.$t("vega.subscription.currentCosts")))]
                ),
                _c(
                  "div",
                  { staticClass: "pav-subscription--costs--cost--total" },
                  [_vm._v(_vm._s(_vm.totalCurrentCost) + " €")]
                ),
              ]),
            ]),
            !_vm.vegaIsActivated
              ? _c(
                  "div",
                  {
                    staticClass: "pav-subscription--button-activate",
                    on: { click: _vm.onActivateVega },
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("vega.subscription.activateButtonText")) +
                        "\n    "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.vegaIsActivated
        ? [
            _c("div", { staticClass: "pav-subscription--actions" }, [
              _c(
                "div",
                {
                  staticClass: "pav-subscription--actions--vega",
                  on: { click: _vm.openVEGA },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("vega.subscription.openVEGA")) +
                      "\n      "
                  ),
                ]
              ),
              !_vm.isPaused
                ? _c(
                    "div",
                    {
                      staticClass: "pav-subscription--actions--pause",
                      on: { click: _vm.onPauseVega },
                    },
                    [
                      _c("PauseIcon", {
                        staticClass: "pav-subscription--actions--icon",
                      }),
                      _vm._v(
                        "\n\n        " +
                          _vm._s(_vm.$t("vega.subscription.pause")) +
                          "\n      "
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "pav-subscription--actions--activate",
                      on: { click: _vm.onUnPauseVega },
                    },
                    [
                      _c("ActivateIcon", {
                        staticClass: "pav-subscription--actions--icon",
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("vega.subscription.activate")) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }