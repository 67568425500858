var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.HAS_SUBSCRIPTION
    ? _c("div", { staticClass: "lc-plan-detail" }, [
        _c("div", { staticClass: "lc-plan-detail_left-column" }, [
          _c("div", { staticClass: "lc-plan-detail_left-column_planinfo" }, [
            _c(
              "div",
              { staticClass: "lc-plan-detail_left-column_planinfo--plan" },
              [_vm._v(_vm._s(_vm.planName))]
            ),
            _c(
              "div",
              { staticClass: "lc-plan-detail_left-column_planinfo--text" },
              [_vm._v("Plan")]
            ),
          ]),
          _c("div", { staticClass: "lc-plan-detail_left-column_users" }, [
            _c(
              "div",
              { staticClass: "lc-plan-detail_left-column_users--text" },
              [
                _vm._v(
                  _vm._s(_vm.usersActive) +
                    " of " +
                    _vm._s(_vm.totalAvailableUsers) +
                    " users active"
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "lc-plan-detail_left-column_users--bar" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "lc-plan-detail_left-column_users_progress-bar",
                  },
                  [
                    _c("div", {
                      staticClass:
                        "lc-plan-detail_left-column_users_progress-bar-fill",
                      style: { width: _vm.percentage + "%" },
                    }),
                  ]
                ),
              ]
            ),
          ]),
        ]),
        _vm.company.isAppSumoAccount
          ? _c("div", { staticClass: "lc-plan-detail_right-column" }, [
              _c(
                "div",
                { staticClass: "lc-plan-detail_right-column--logo" },
                [
                  _c("AppsumoLogo"),
                  _c("CheckIcon", {
                    staticClass: "lc-plan-detail_right-column--check-icon",
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "lc-plan-detail_right-column_info" }, [
                _c(
                  "div",
                  { staticClass: "lc-plan-detail_right-column_info--deal" },
                  [_vm._v(_vm._s(_vm.$t("pricing.lifetimeDeal")))]
                ),
                _c(
                  "div",
                  { staticClass: "lc-plan-detail_right-column_info--text" },
                  [_vm._v("$ " + _vm._s(_vm.unitPrice) + ",-")]
                ),
                _c(
                  "div",
                  { staticClass: "lc-plan-detail_right-column_info--text" },
                  [_vm._v("on " + _vm._s(_vm.createdDate))]
                ),
              ]),
            ])
          : _c("div", { staticClass: "lc-plan-detail_right-column" }, [
              !_vm.HAS_FREE_SUBSCRIPTION
                ? _c(
                    "div",
                    { staticClass: "lc-plan-detail_right-column_amount" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "lc-plan-detail_right-column_amount--text",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "lc-plan-detail_right-column_amount--text-currency",
                            },
                            [_vm._v(_vm._s(_vm.unitPriceCurrency))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "lc-plan-detail_right-column_amount--text-amount",
                            },
                            [_vm._v(_vm._s(_vm.unitPrice))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "lc-plan-detail_right-column_amount--text-period",
                            },
                            [_vm._v("/mo")]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              (_vm.HAS_MOLLIE_SUBSCRIPTION ||
                _vm.HAS_INVOICE_SUBSCRIPTION ||
                _vm.HAS_FREE_SUBSCRIPTION) &&
              !_vm.IS_LETSCONNECT_SUBSCRIPTION_CANCELLED
                ? _c(
                    "div",
                    {
                      staticClass: "lc-plan-detail_right-column_upgrade",
                      on: { click: _vm.onShowPlans },
                    },
                    [
                      _c("span", { staticClass: "mr-4" }, [
                        _vm._v(_vm._s(_vm.$t("upgrade-messages.upgrade-plan"))),
                      ]),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/register-funnel/forward-arrow.png"),
                          width: "18px",
                          height: "16.2px",
                          alt: "Next",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }