<template>
  <div v-if="CAN_CANCEL_SUBSCRIPTION" class="lc-cancel-subscription" @click="onCancelSubscription">
    {{ $t('vue.cancelSubscription') }}
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { mcancelsubscription } from '@/mixins/mcancelsubscription'

export default {
  name: 'CancelSubscription',
  mixins: [mcancelsubscription],
  computed: {
    ...mapGetters({
      company: 'company',
      subscription: 'subscription',
      gptSubscription: 'subscription'
    })
  },
  methods: {
    onCancelSubscription() {
      this.$serverBus.$emit('letsconnect-cancel-subscriptions')
    }
  }
}
</script>
<style lang="scss" scoped>
.lc-cancel-subscription {
  margin: 30px 10px;

  padding: 14px 40px;
  border-radius: 8px;
  background: #ff004e;
  color: #fff;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */
  letter-spacing: -0.1px;
  cursor: pointer;

  &:hover {
    background: #ff004e;
    box-shadow: rgb(255, 0, 78) 0px 8px 25px -8px;
  }
}
</style>
