<template>
  <div class="pricing-subscription">
    <connect-modal :show="showMollieComponent" :width="'505px'" bkcolor="#F7F7F7">
      <div class="price-list-mollie-close-button" style="margin-left: auto; cursor: pointer" @click="onMollieFormClose">
        <close-circle-icon class="price-list-mollie-close-button-icon pa-bk-secondary" :show-circle="false" :width="14" :height="14"></close-circle-icon>
      </div>
      <mollie
        v-if="subscription && subscription.planDetails && showMollieComponent"
        :selectedPlan="subscription.planDetails"
        :subscriptionPlanId="subscription.planDetails.id.toString()"
        :isFirstInvoice="true"
        :isModal="true"
        :supportedPaymentMethods="supportedPaymentMethods"
        :updatePaymentMethod="true"
      ></mollie>
    </connect-modal>
    <connect-modal :show="showMollieVegaComponent" :width="'505px'" bkcolor="#F7F7F7">
      <div class="price-list-mollie-close-button" style="margin-left: auto; cursor: pointer" @click="onMollieFormClose">
        <close-circle-icon class="price-list-mollie-close-button-icon pa-bk-secondary" :show-circle="false" :width="14" :height="14"></close-circle-icon>
      </div>
      <MollieVega v-if="showMollieVegaComponent" :isModal="true" :supportedPaymentMethods="supportedPaymentMethods" :updatePaymentMethod="true"></MollieVega>
    </connect-modal>

    <connect-modal :show="showMollieGptComponent" :width="'505px'" bkcolor="#F7F7F7">
      <div class="price-list-mollie-close-button" style="margin-left: auto; cursor: pointer" @click="onMollieFormClose">
        <close-circle-icon class="price-list-mollie-close-button-icon pa-bk-secondary" :show-circle="false" :width="14" :height="14"></close-circle-icon>
      </div>
      <MollieGpt v-if="showMollieGptComponent" :isModal="true" :supportedPaymentMethods="supportedPaymentMethods" :updatePaymentMethod="true"></MollieGpt>
    </connect-modal>

    <div class="pricing__heading">
      <h3 class="pricing__heading__title">{{ $t('vue.subscriptionDetail') }}</h3>
      <p class="pricing__heading__description">{{ $t('vue.subscriptionDescription') }}</p>
    </div>
    <SubscriptionDetail v-if="CAN_SHOW_SUBSCRIPTION" />

    <vs-popup class="subscription-popup" :title="$t('vue.cancelSubscription')" :active.sync="cancelPrompt">
      <!-- <p class="mb-2" v-html="$t('vue.cancelSubscriptionConfirmationMessage')" /> -->

      <div v-if="subscriptionList.length > 0" class="subscription-popup-body">
        <div class="vx-row subscription-popup-list-item" v-for="(subscription, d) in subscriptionList" :key="d">
          <div class="vx-col w-full">
            <vs-checkbox hide-details class="ma-0 pt-0 pb-0 checkbox-main" v-model="subscription.enabled" @change="onCancellationListUpdated">
              {{ subscription.name }}
            </vs-checkbox>
          </div>
        </div>
      </div>

      <div class="subscription-popup-footer">
        <vs-button
          color="#FF004E"
          type="filled"
          :key="updateKey"
          :disabled="subscriptionList.filter((x) => x.enabled).length === 0"
          @click="cancelPromptConfirm = true"
        >
          {{ $t('vue.cancelSubscription') }}
        </vs-button>
      </div>

      <vs-popup class="subPopup" :title="$t('vue.cancelSubscription')" :active.sync="cancelPromptConfirm">
        <p class="mb-2">{{ $t('vue.confirmQuestion') }}</p>
        <!-- <p class="mb-2" v-html="$t('vue.cancelSubscriptionConfirmationMessage')" /> -->
        <vs-button color="#e8546f" type="filled" class="mr-2" @click="cancelSubscriptionConfirm">
          {{ $t('vue.confirm') }}
        </vs-button>

        <vs-button type="border" color="#e8546f" enabled @click="cancelPromptConfirm = false">
          {{ $t('vue.cancel') }}
        </vs-button>
      </vs-popup>
    </vs-popup>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { paddle } from '@/mixins/paddle'
import { msubscription } from '@/mixins/msubscription'
import { mcancelsubscription } from '@/mixins/mcancelsubscription'
import CloseCircleIcon from '@/components/icons/CloseCircleIcon.vue'
import ConnectModal from '@/components/ConnectModal.vue'
import Mollie from './Mollie.vue'
import MollieVega from '../../vega/subscription/MollieVega.vue'
import MollieGpt from '../../gpt-connect/subscription/MollieGpt.vue'
import SubscriptionDetail from './subscription/SubscriptionDetail.vue'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

export default {
  mixins: [paddle, msubscription, mcancelsubscription],
  components: {
    CloseCircleIcon,
    ConnectModal,
    Mollie,
    MollieVega,
    MollieGpt,
    SubscriptionDetail
  },
  data: () => ({
    showMollieComponent: false,
    showMollieVegaComponent: false,
    showMollieGptComponent: false,
    isLoading: false,
    cancelPrompt: false,
    cancelPromptConfirm: false,
    updateKey: Math.random().toString(36).substring(2, 15)
  }),

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      subscription: 'subscription',
      pathadviceInvoices: 'invoice/pathadviceInvoices',
      fetchedSubscriptionPlans: 'subscription_plans',
      refresh: 'refresh',
      currency: 'currency'
    }),

    CAN_SHOW_SUBSCRIPTION() {
      if (this.HAS_GPT_SUBSCRIPTION) return true

      if (this.company && (this.company.planType === 'partner' || this.company.paymentStatus === 'on-trial')) {
        return false
      }
      return true
    },

    currentPaymentMethod() {
      if (this.company && this.company.currentInvoiceId) {
        return 'invoice'
      }

      if (this.company && this.company.mollieSubscriptionId) {
        try {
          const invoices = this.pathadviceInvoices.filter((x) => x.companyId === this.activeUserInfo.company)

          const invoice = invoices.reduce((prev, current) => {
            return prev.createdAt.seconds > current.createdAt.seconds ? prev : current
          })
          return invoice.method
        } catch (error) {
          /* eslint-disable no-console */
          console.log(error.message)
          return null
        }
      }

      if (this.subscription && this.subscription.payment_information && this.subscription.payment_information.payment_method) {
        return this.subscription.payment_information.payment_method
      }
      return null
    },

    CAN_CANCEL_SUBSCRIPTION() {
      if (this.company && this.company.mollieSubscriptionId) {
        return this.company.paymentStatus === 'subscription' || this.company.paymentStatus === 'on-trial'
      }
      return this.subscription && this.subscription.cancel_url && this.company && this.company.paymentStatus === 'subscription'
    },

    CAN_UPDATE_PAYMENT_METHOD() {
      return (
        this.subscription &&
        this.company &&
        this.company.mollieCustomerId &&
        this.company.mollieSubscriptionId &&
        this.company.paymentStatus !== 'subscription-cancelled'
      )
    },
    CAN_UPDATE_PAYMENT_METHOD_FOR_INVOICE() {
      return this.company && this.company.currentInvoiceId && this.company.paymentStatus !== 'subscription-cancelled'
    }
  },

  watch: {
    async 'company.subscriptionId'(after, before) {
      if (after && after !== before) {
        if (this.company && this.company.subscriptionId) {
          await this.getSubscriptionInfo()
        }
      }
    },
    async 'company.currentInvoiceId'() {
      if (this.company && this.company.currentInvoiceId) {
        await this.getPathadviceInvoiceSuscriptionInfo()
      }
    }
  },

  async created() {
    this.$serverBus.$on('close-mollie-form', (t) => {
      this.onMollieFormClose(t)
    })
    await this.getSubscriptionInfo()

    window.Paddle.Setup({
      vendor: this.getVendorId()
    })
  },
  beforeDestroy() {
    this.$serverBus.$off('close-mollie-form')
    this.$serverBus.$off('payment-method-update-mollie')
    this.$serverBus.$off('payment-method-update-invoice')
    this.$serverBus.$off('payment-method-update-paddle')
    this.$serverBus.$off('payment-method-update-vega')
    this.$serverBus.$off('payment-method-update-gpt')
    this.$serverBus.$off('letsconnect-cancel-subscriptions')
  },
  async mounted() {
    try {
      this.$serverBus.$on('letsconnect-cancel-subscriptions', () => {
        this.cancelPrompt = true
      })

      this.$serverBus.$on('payment-method-update-mollie', () => {
        this.updatePaymentMethodMollie()
      })

      this.$serverBus.$on('payment-method-update-invoice', () => {
        this.updatePaymentMethodInvoice()
      })

      this.$serverBus.$on('payment-method-update-paddle', () => {
        this.updatePaymentMethod()
      })

      this.$serverBus.$on('payment-method-update-vega', () => {
        this.updatePaymentMethodMollieVega()
      })

      this.$serverBus.$on('payment-method-update-gpt', () => {
        this.updatePaymentMethodMollieGpt()
      })

      if (this.company && this.company.currency && this.company.currency.text) {
        this.updateCurrency(this.company.currency)
      }

      await this.getSubscriptionInfo()
    } catch (error) {
      /* eslint-disable-next-line */
      console.log(error.message)
    }
  },
  methods: {
    ...mapMutations({
      updateSubscription: 'UPDATE_SUBSCRIPTION',
      updateRefreshState: 'UPDATE_REFRESH_STATE',
      updateCurrency: 'UPDATE_CURRENCY'
    }),
    onCancellationListUpdated() {
      this.updateKey = Math.random().toString(36).substring(2, 15)
    },

    async onMollieFormClose(t) {
      if (t.transaction && t.transaction.status === 'paid') {
        this.$vs.notify({
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('vue.paymentMethodUpdatedSuccessfully'),
          color: 'green'
        })
      }
      this.showMollieComponent = false
      this.showMollieVegaComponent = false
      this.showMollieVegaComponent = false

      window.location.reload(true)
    },
    formatDate(date) {
      return dayjs.unix(date.seconds).tz(this.$defaultTimezone).locale(this.$i18n.locale).format('LL')
    },
    formatMollieDate(date) {
      return dayjs(date).locale(this.$i18n.locale).format('LL')
    },
    formatPaddleDate(date) {
      return dayjs(date).locale(this.$i18n.locale).format('LL')
    },

    getExpiryDate() {
      return dayjs.unix(this.company.expiryDate.seconds).tz(this.$defaultTimezone).locale(this.$i18n.locale).format('LL')
    },

    updatePaymentMethodMollieVega() {
      this.showMollieVegaComponent = true
    },

    updatePaymentMethodMollieGpt() {
      this.showMollieGptComponent = true
    },

    updatePaymentMethodMollie() {
      this.showMollieComponent = true
    },

    updatePaymentMethodInvoice() {
      this.showMollieComponent = true
    },

    updatePaymentMethod() {
      this.$vs.loading()

      window.Paddle.Checkout.open({
        override: this.subscription.update_url,
        successCallback: this.updateSuccess
      })

      setTimeout(() => {
        this.$vs.loading.close()
      }, 200)
    },
    async updateSuccess() {
      this.$vs.notify({
        title: this.$i18n.t('vue.success'),
        text: this.$i18n.t('vue.subscriptionUpdated'),
        color: 'green'
      })
      this.$serverBus.$emit('successful-payment')
    },

    notifyMessage(title, text, color) {
      this.$vs.notify({
        title,
        text,
        color
      })
    },

    async cancelSubscriptionConfirm() {
      const selectedList = this.subscriptionList.filter((x) => x.enabled)
      this.cancelPrompt = false
      this.cancelPromptConfirm = false

      if (selectedList.length > 0) {
        await this.$vs.loading()
        const cancelSubscription = this.$functions.httpsCallable('cancelSubscription')
        const response = await cancelSubscription({ list: selectedList, company: this.activeUserInfo.company })

        if (response && response.data && response.data.success) {
          this.notifyMessage(this.$i18n.t('vue.error'), this.$i18n.t('vue.subscriptionCanceled'), 'green')
        }

        await this.$vs.loading.close()

        setTimeout(() => {
          this.updateSubscription(null)
          this.$serverBus.$emit('subscription-cancelled')
        }, 500)
      }
    },

    cancelSubscription() {
      if (this.subscription.subscription_id) {
        this.cancelPrompt = true
      }
    },

    subscriptionUpdated(subscription) {
      console.log(subscription)
      subscription.enabled = !subscription.enabled
    }
  }
}
</script>

<style lang="scss">
.btn {
  &-main {
    font-size: 20px;
    font-weight: 500;

    .vs-button-text {
      text-transform: capitalize;
    }
  }
}

.pricing-subscription {
  padding-top: 30px;

  &__details {
    margin-top: 60px;
    color: #262629;
    font-size: 16px;

    &__row {
      margin-bottom: 30px;
    }

    &__title {
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}

.subscription-popup {
  .vs-popup {
    &--title {
      h3 {
        padding: 20px 50px;
        color: #ff004e;
        font-family: 'Lato';
        font-size: 20px;
      }
    }

    &--close {
      margin-right: 18px;
      color: #878893;
      font-weight: 700;
      background: none !important;
      box-shadow: none;
      transform: none;
    }

    &--content {
      margin: 0;
      padding: 0;
    }
  }

  &-body {
    padding: 20px 50px;
  }

  &-list-item {
    margin-top: 27px;
    color: #7e8186;
    font-family: 'Lato';
    font-size: 20px;
    font-weight: 500;

    &:first-child {
      margin-top: 0;
    }

    .vs-checkbox {
      margin-right: 20px;
      border-color: #7e8186 !important;
      border-radius: 4px;
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    padding: 30px 36px;
    width: 100%;
    border-top: 1px solid #f2f2f2;
  }
}
</style>
