<template>
  <div>
    <svg width="151" height="123" viewBox="0 0 151 123" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M103.999 109.144C122.082 109.144 136.741 94.4853 136.741 76.4023C136.741 58.3193 122.082 43.6602 103.999 43.6602"
        stroke="#C8E2F3"
        stroke-width="7.6213"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
      <path d="M144.928 117.331L127.124 99.5273" stroke="#C8E2F3" stroke-width="7.6213" stroke-linecap="square" stroke-linejoin="round" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M114.502 0.96875L99.6384 39.9371C82.1244 42.6453 68.7167 57.7863 68.7167 76.0588C68.7167 84.8632 71.8295 92.9406 77.0147 99.2504L70.7652 115.635H43.7368L0 0.96875H29.9769L54.712 68.294L57.1691 76.4844L59.7901 68.294L84.5251 0.96875H114.502ZM76.338 76.0588C76.338 63.188 84.7427 52.2802 96.3631 48.5242L80.2897 90.664C77.7779 86.3772 76.338 81.3862 76.338 76.0588Z"
        fill="#C8E2F3"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'VegaPerAnalyzedIcon',
  computed: {
    locale() {
      return this.$i18n.locale
    }
  }
}
</script>
