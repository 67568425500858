<template>
  <svg width="57" height="40" viewBox="0 0 57 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_9738_1064)">
      <rect x="2" width="53" height="36" rx="6" fill="#EBF5FE" />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.2003 14.918H40.4669C41.2003 14.918 41.8003 15.1267 42.2003 15.4745C42.4669 15.8223 42.6003 16.3788 42.4669 17.0049C42.2669 18.5354 41.4003 19.3006 39.8669 19.3006H39.1336C38.9336 19.3006 38.8003 19.4397 38.8003 19.5788L38.5336 21.318C38.5336 21.4571 38.4669 21.5267 38.3336 21.5267H37.1336C37.0003 21.5267 36.9336 21.4571 36.9336 21.318L37.8669 15.1962C37.9336 15.0571 38.0669 14.918 38.2003 14.918ZM39.4002 18.0483C40.0002 18.0483 40.6002 18.0483 40.7336 17.2136C40.7336 16.8657 40.7336 16.657 40.6002 16.5875C40.4002 16.3092 40.0002 16.3092 39.6002 16.3092H39.4669C39.3336 16.3092 39.2669 16.3788 39.2669 16.4483L39.0002 18.0483H39.4002Z"
      fill="#139AD6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.8673 14.918H24.1339C24.9339 14.918 25.4673 15.1267 25.8673 15.4745C26.1339 15.8223 26.2673 16.3788 26.1339 17.0049C25.9339 18.5354 25.0673 19.3006 23.5339 19.3006H22.8006C22.6006 19.3006 22.4673 19.4397 22.4673 19.5788L22.2006 21.2484C22.1339 21.3875 22.0006 21.5267 21.8673 21.5267H20.8006C20.6673 21.5267 20.6006 21.4571 20.6006 21.318L21.5339 15.1962C21.6006 15.0571 21.7339 14.918 21.8673 14.918ZM23.0673 18.0483C23.6673 18.0483 24.2673 18.0483 24.4006 17.2136C24.4673 16.8657 24.4006 16.657 24.3339 16.5875C24.1339 16.3092 23.7339 16.3092 23.3339 16.3092H23.2006C23.0673 16.3092 23.0006 16.3788 23.0006 16.4483L22.7339 18.0483H23.0673Z"
      fill="#263B80"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.9326 17.1469H30.9993C31.066 17.1469 31.1993 17.2861 31.266 17.3556L30.666 21.2513C30.5993 21.3904 30.466 21.5296 30.3326 21.5296H29.3326C29.1993 21.5296 29.1326 21.46 29.1326 21.3209L29.1993 20.973C29.1993 20.973 28.666 21.5991 27.666 21.5991C27.066 21.5991 26.5993 21.46 26.266 21.0426C25.9326 20.6252 25.7326 19.9991 25.866 19.3035C26.066 17.9817 27.1326 17.0078 28.3326 17.0078C28.866 17.0078 29.3326 17.1469 29.5993 17.4948L29.666 17.6339L29.7326 17.2861C29.7326 17.2165 29.866 17.1469 29.9326 17.1469ZM28.1991 20.4851C28.8658 20.4851 29.3324 19.9982 29.4658 19.3721C29.4658 19.0938 29.3991 18.746 29.1991 18.5373C28.9991 18.3982 28.7991 18.2591 28.4658 18.2591C27.8658 18.2591 27.3324 18.746 27.2658 19.3721C27.2658 19.7199 27.3324 19.9982 27.4658 20.2069C27.5991 20.346 27.8658 20.4851 28.1991 20.4851Z"
      fill="#263B80"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M46.2002 17.1469H47.2669C47.3688 17.1469 47.4317 17.2281 47.4855 17.2975C47.5021 17.3189 47.5178 17.3392 47.5336 17.3556L46.9336 21.2513C46.8669 21.3904 46.7336 21.5296 46.6002 21.5296H45.6002C45.4669 21.5296 45.4002 21.46 45.4002 21.3209L45.4669 20.973C45.4669 20.973 44.9336 21.5991 43.9336 21.5991C43.3336 21.5991 42.8669 21.46 42.5336 21.0426C42.2002 20.6252 42.0002 19.9991 42.1336 19.3035C42.3336 17.9817 43.4002 17.0078 44.6002 17.0078C45.1336 17.0078 45.6002 17.1469 45.8669 17.4948L45.9336 17.6339L46.0002 17.2861C46.0002 17.2165 46.1336 17.1469 46.2002 17.1469ZM44.4667 20.4851C45.1334 20.4851 45.6 19.9982 45.7334 19.3721C45.8 19.0938 45.7334 18.746 45.4667 18.5373C45.2667 18.3982 45.0667 18.2591 44.7334 18.2591C44.1334 18.2591 43.6 18.746 43.5334 19.3721C43.5334 19.7199 43.6 19.9982 43.7334 20.2069C43.8667 20.346 44.1334 20.4851 44.4667 20.4851Z"
      fill="#139AD6"
    />
    <path
      d="M36.8003 17.1484H35.667C35.5337 17.1484 35.467 17.218 35.4003 17.2876L33.9337 19.6528L33.267 17.4267C33.2003 17.2876 33.1337 17.218 32.9337 17.218H31.867C31.7337 17.218 31.667 17.3571 31.667 17.4963L32.867 21.1832L31.7337 22.8528C31.667 22.9919 31.7337 23.2006 31.867 23.2006H32.9337C33.067 23.2006 33.1337 23.131 33.2003 23.0615L36.867 17.5658C37.067 17.3571 36.9337 17.1484 36.8003 17.1484Z"
      fill="#263B80"
    />
    <path
      d="M48.5329 15.1313L47.5996 21.3921C47.5996 21.5313 47.6663 21.6008 47.7996 21.6008H48.7329C48.8663 21.6008 48.9996 21.4617 49.0663 21.3226L49.9996 15.2008C49.9996 15.0617 49.9329 14.9921 49.7996 14.9921H48.7329C48.6663 14.9226 48.5996 14.9921 48.5329 15.1313Z"
      fill="#139AD6"
    />
    <path
      d="M15.6667 12.8348C15.2 12.2783 14.3333 12 13.1333 12H9.8C9.6 12 9.4 12.2087 9.33333 12.4174L8 21.5304C8 21.7391 8.13333 21.8783 8.26667 21.8783H10.3333L10.8667 18.4696V18.6087C10.9333 18.4 11.1333 18.1913 11.3333 18.1913H12.3333C14.2667 18.1913 15.7333 17.3565 16.2 15.0609C16.2 14.9913 16.2 14.9217 16.2 14.8522C16.1333 14.8522 16.1333 14.8522 16.2 14.8522C16.2667 13.9478 16.1333 13.3913 15.6667 12.8348Z"
      fill="#263B80"
    />
    <path
      d="M16.1338 14.8516C16.1338 14.9211 16.1338 14.9907 16.1338 15.0603C15.6671 17.4255 14.2005 18.1907 12.2671 18.1907H11.2671C11.0671 18.1907 10.8671 18.3994 10.8005 18.6081L10.1338 22.8516C10.1338 22.9907 10.2005 23.1298 10.4005 23.1298H12.1338C12.3338 23.1298 12.5338 22.9907 12.5338 22.782V22.7124L12.8671 20.5559V20.4168C12.8671 20.2081 13.0671 20.069 13.2671 20.069H13.5338C15.2005 20.069 16.5338 19.3733 16.8671 17.2863C17.0005 16.4516 16.9338 15.6863 16.5338 15.1994C16.4671 15.0603 16.3338 14.9211 16.1338 14.8516Z"
      fill="#139AD6"
    />
    <path
      d="M15.6672 14.6423C15.6005 14.6423 15.5339 14.5727 15.4672 14.5727C15.4005 14.5727 15.3339 14.5727 15.2672 14.5032C15.0005 14.4336 14.7339 14.4336 14.4005 14.4336H11.8005C11.7339 14.4336 11.6672 14.4336 11.6005 14.5032C11.4672 14.5727 11.4005 14.7119 11.4005 14.851L10.8672 18.4684V18.6075C10.9339 18.3988 11.1339 18.1901 11.3339 18.1901H12.3339C14.2672 18.1901 15.7339 17.3553 16.2005 15.0597C16.2005 14.9901 16.2005 14.9206 16.2672 14.851C16.1339 14.7814 16.0672 14.7119 15.9339 14.7119C15.7339 14.6423 15.7339 14.6423 15.6672 14.6423Z"
      fill="#232C65"
    />
    <defs>
      <filter id="filter0_d_9738_1064" x="0" y="0" width="57" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9738_1064" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9738_1064" result="shape" />
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'PaypalIcon'
}
</script>
