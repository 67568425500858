var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lc-payment-details" }, [
    _vm.company.isAppSumoAccount
      ? _c(
          "div",
          { staticClass: "lc-payment-details_details" },
          [
            _c("div", { staticClass: "lc-sub-details--label" }, [
              _vm._v(_vm._s(_vm.$t("vue.lastPayment"))),
            ]),
            _c("div", { staticClass: "lc-payment-details_details--text" }, [
              _vm._v(
                _vm._s(_vm.lastPaymentCurrency) +
                  " " +
                  _vm._s(_vm.lastPaymentAmount) +
                  ",-"
              ),
            ]),
            _c("div", { staticClass: "lc-payment-details_details--text" }, [
              _vm._v("on " + _vm._s(_vm.lastPaymentDate)),
            ]),
            _c("div", { staticClass: "lc-sub-details--label mtop-30" }, [
              _vm._v(_vm._s(_vm.$t("vue.nextPayment"))),
            ]),
            _c("div", { staticClass: "lc-payment-details_details--text" }, [
              _vm._v(_vm._s(_vm.$t("pricing.noPaymentPlanned"))),
            ]),
            _vm.WAS_GPT_ACTIVATED && _vm.lastGptPayment
              ? [
                  _c("div", { staticClass: "lc-sub-details--label mtop-30" }, [
                    _vm._v(_vm._s(_vm.$t("vue.lastGptPayment"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [_vm._v("€ " + _vm._s(_vm.lastGptPaymentAmount) + ",-")]
                  ),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [_vm._v("on " + _vm._s(_vm.lastGptPaymentDate))]
                  ),
                ]
              : _vm._e(),
            _vm.WAS_GPT_ACTIVATED && !_vm.IS_GPT_SUBSCRIPTION_CANCELLED
              ? [
                  _c("div", { staticClass: "lc-sub-details--label mtop-30" }, [
                    _vm._v(_vm._s(_vm.$t("vue.nextGptConnectPayment"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [
                      _vm._v(
                        "\n        € " +
                          _vm._s(_vm.nextGptPaymentAmount) +
                          ",- "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n        on " +
                          _vm._s(_vm.nextGptPaymentDate) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
            _vm.gptPaymentCancelledAt
              ? [
                  _c("div", { staticClass: "lc-sub-details--label mtop-30" }, [
                    _vm._v("GPT Connect Canceled"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [_vm._v("on " + _vm._s(_vm.gptPaymentCancelledAt))]
                  ),
                ]
              : _vm._e(),
            _vm.WAS_VEGA_ACTIVATED && _vm.lastVegaPayment
              ? [
                  _c("div", { staticClass: "lc-sub-details--label mtop-30" }, [
                    _vm._v(_vm._s(_vm.$t("vue.lastVegaPayment"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [_vm._v("€ " + _vm._s(_vm.lastVegaPaymentAmount) + ",-")]
                  ),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [_vm._v("on " + _vm._s(_vm.lastVegaPaymentDate))]
                  ),
                ]
              : _vm._e(),
            _vm.WAS_VEGA_ACTIVATED
              ? [
                  _c("div", { staticClass: "lc-sub-details--label mtop-30" }, [
                    _vm._v(_vm._s(_vm.$t("vue.nextVegaPayment"))),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "lc-payment-details_details--current-costs",
                      on: {
                        click: function ($event) {
                          return _vm.$serverBus.$emit("show-vega")
                        },
                      },
                    },
                    [
                      _c("RightArrowIcon"),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("vega.subscription.checkCurrentVegaCosts")
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [_vm._v("on " + _vm._s(_vm.nextVegaPaymentDate))]
                  ),
                ]
              : _vm._e(),
            _c("div", { staticClass: "lc-sub-details--label mtop-30" }, [
              _vm._v(_vm._s(_vm.$t("vue.planType"))),
            ]),
            _c("div", { staticClass: "lc-payment-details_details--text" }, [
              _vm._v(_vm._s(_vm.$t("pricing.lifetime"))),
            ]),
          ],
          2
        )
      : _c(
          "div",
          { staticClass: "lc-payment-details_details" },
          [
            !_vm.canceledDate &&
            _vm.lastPaymentDate &&
            !_vm.IS_SUBSCRIPTION_CANCELLED
              ? [
                  _c("div", { staticClass: "lc-sub-details--label" }, [
                    _vm._v(_vm._s(_vm.$t("vue.lastLetsConnectPayment"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [
                      _vm._v(
                        _vm._s(_vm.lastPaymentCurrency) +
                          " " +
                          _vm._s(_vm.lastPaymentAmount) +
                          ",-"
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [_vm._v("on " + _vm._s(_vm.lastPaymentDate))]
                  ),
                ]
              : _vm._e(),
            _vm.canceledDate
              ? [
                  _c("div", { staticClass: "lc-sub-details--label mtop-30" }, [
                    _vm._v("Let's Connect Canceled"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [_vm._v("on " + _vm._s(_vm.canceledDate))]
                  ),
                ]
              : _vm._e(),
            !_vm.canceledDate && _vm.nextPaymentDate
              ? [
                  _c("div", { staticClass: "lc-sub-details--label mtop-30" }, [
                    _vm._v(_vm._s(_vm.$t("vue.nextLetsConnectPayment"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [
                      _vm._v(
                        _vm._s(_vm.nextPaymentCurrency) +
                          " " +
                          _vm._s(_vm.nextPaymentAmount) +
                          ",-"
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [_vm._v("on " + _vm._s(_vm.nextPaymentDate))]
                  ),
                ]
              : _vm._e(),
            !_vm.nextPaymentDate &&
            _vm.HAS_FREE_SUBSCRIPTION &&
            !_vm.WAS_VEGA_ACTIVATED
              ? [
                  _c("div", { staticClass: "lc-sub-details--label mtop-30" }, [
                    _vm._v(_vm._s(_vm.$t("vue.nextLetsConnectPayment"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [_vm._v(_vm._s(_vm.$t("pricing.noPaymentPlanned")))]
                  ),
                ]
              : _vm._e(),
            _vm.WAS_VEGA_ACTIVATED
              ? [
                  _c("div", { staticClass: "lc-sub-details--label mtop-30" }, [
                    _vm._v(_vm._s(_vm.$t("vue.nextVegaPayment"))),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "lc-payment-details_details--current-costs",
                      on: {
                        click: function ($event) {
                          return _vm.$serverBus.$emit("show-vega")
                        },
                      },
                    },
                    [
                      _c("RightArrowIcon"),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("vega.subscription.checkCurrentVegaCosts")
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [_vm._v("on " + _vm._s(_vm.nextVegaPaymentDate))]
                  ),
                ]
              : _vm._e(),
            _vm.WAS_GPT_ACTIVATED && _vm.lastGptPayment
              ? [
                  _c("div", { staticClass: "lc-sub-details--label mtop-30" }, [
                    _vm._v(_vm._s(_vm.$t("vue.lastGptPayment"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [_vm._v("€ " + _vm._s(_vm.lastGptPaymentAmount) + ",-")]
                  ),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [_vm._v("on " + _vm._s(_vm.lastGptPaymentDate))]
                  ),
                ]
              : _vm._e(),
            _vm.WAS_GPT_ACTIVATED && !_vm.IS_GPT_SUBSCRIPTION_CANCELLED
              ? [
                  _c("div", { staticClass: "lc-sub-details--label mtop-30" }, [
                    _vm._v(_vm._s(_vm.$t("vue.nextGptConnectPayment"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [
                      _vm._v(
                        "\n        € " +
                          _vm._s(_vm.nextGptPaymentAmount) +
                          ",- "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n        on " +
                          _vm._s(_vm.nextGptPaymentDate) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
            _vm.gptPaymentCancelledAt
              ? [
                  _c("div", { staticClass: "lc-sub-details--label mtop-30" }, [
                    _vm._v("GPT Connect Canceled"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [_vm._v("on " + _vm._s(_vm.gptPaymentCancelledAt))]
                  ),
                ]
              : _vm._e(),
            ["subscription-cancelled", "deleted"].includes(
              _vm.company.paymentStatus
            )
              ? [
                  _c("div", { staticClass: "lc-sub-details--label mtop-30" }, [
                    _vm._v(_vm._s(_vm.$t("vue.expiryDate"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "lc-payment-details_details--text" },
                    [_vm._v("on " + _vm._s(_vm.expiryDate))]
                  ),
                ]
              : _vm._e(),
            _vm.planPeriod
              ? [
                  _c("div", { staticClass: "lc-sub-details--label mtop-30" }, [
                    _vm._v(_vm._s(_vm.$t("vue.planType"))),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "lc-payment-details_details--text capitalize",
                    },
                    [_vm._v(_vm._s(_vm.planPeriod))]
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
    _c("div", { staticClass: "lc-payment-details_invoices-wrapper" }, [
      _c(
        "div",
        {
          staticClass: "lc-payment-details_invoices",
          on: { click: _vm.onShowInvoices },
        },
        [
          _c("span", { staticClass: "mr-4" }, [_vm._v("Invoices")]),
          _c("img", {
            attrs: {
              src: require("@/assets/images/register-funnel/forward-arrow.png"),
              width: "18px",
              height: "16.2px",
              alt: "Next",
            },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }