<template>
  <div class="pav-subscription">
    <vega-message v-if="!HAS_ACCESS"></vega-message>
    <div v-if="isProcessing" class="pav-subscription__loading">
      <div class="pav-subscription__loading__loader">
        <span class="pav-subscription__loading__loader__spinner" />
        <div class="pav-subscription__loading__loader__text">{{ loaderSpinnerText }}</div>
      </div>
    </div>

    <div v-if="isVegaActivationSuccessful" class="pav-subscription__loading">
      <div class="pav-subscription__loading__loader">
        <img src="@/assets/images/register-funnel/check.png" alt="Success" />
        <div class="pav-subscription__loading__loader__title">{{ $t('vega.subscription.thankYou') }}</div>
        <div class="pav-subscription__loading__loader__text">{{ $t('vega.subscription.thankYouMessage') }}</div>
      </div>
    </div>

    <div v-if="hasQuerySourceVega && hasVegaPaymentFailed && !isPaymentFailedClosed" class="pav-subscription__loading">
      <div class="pav-subscription__close" @click="onClosePaymentFailedUI">
        <close-icon color="#000000" :width="35" :height="35"></close-icon>
      </div>
      <div class="pav-subscription__loading__loader">
        <img src="@/assets/images/register-funnel/payment_failed.png" alt="Payment Failed" />
        <div class="pav-subscription__loading__loader__title">{{ $t('promocode.somethingWentWrong') }}</div>
        <div class="pav-subscription__loading__loader__text">{{ $t('promocode.paymentCouldNotBeProcessed') }}</div>
      </div>
    </div>

    <div class="pav-subscription--header">
      {{ $t('vega.subscription.pricingHeader') }}
    </div>
    <div class="pav-subscription--description">
      {{ $t('vega.subscription.pricingDescription') }}
    </div>
    <div class="pav-subscription-features">
      <div class="pav-subscription-features--item">
        <CheckIcon class="pav-subscription-features--item--icon" />
        {{ $t('vega.subscription.pricingFeature1') }}
      </div>
      <div class="pav-subscription-features--item">
        <CheckIcon class="pav-subscription-features--item--icon" />
        {{ $t('vega.subscription.pricingFeature2') }}
      </div>
      <div class="pav-subscription-features--item">
        <CheckIcon class="pav-subscription-features--item--icon" />
        {{ $t('vega.subscription.pricingFeature3') }}
      </div>
    </div>

    <template v-if="showGetStarted">
      <div class="pav-subscription-usage">
        <div class="pav-subscription-usage-box">
          <div class="pav-subscription-usage-box-item1">
            <div class="pav-subscription-usage-box-item1--header"></div>
            <div class="pav-subscription-usage-box-item1--info">
              {{ $t('vega.subscription.vegaAnalysing') }}
            </div>
          </div>
          <div class="pav-subscription-usage-box-item2">
            <div class="pav-subscription-usage-box-item2--header">
              {{ $t('vega.subscription.usage') }}
            </div>
            <div class="pav-subscription-usage-box-item2--info">
              <div class="pav-subscription-usage-box-item2--info--slider">
                <div v-if="!isVegaActivationSuccessful"><vs-slider step="100" :min="10" :max="10000" v-model="totalVisitors" /></div>
                <div>{{ $t('vega.subscription.websiteVisitors') }}</div>
              </div>
              <div class="pav-subscription-usage-box-item2--info--text">
                {{ totalVisitors }}
              </div>
            </div>
          </div>
          <div class="pav-subscription-usage-box-item3">
            <div class="pav-subscription-usage-box-item3--header">
              {{ $t('vega.subscription.monthlyCost') }}
            </div>
            <div class="pav-subscription-usage-box-item3--info">€ {{ totalCost }}</div>
          </div>
        </div>
        <div class="pav-subscription-usage--cost">
          {{ totalCostText }}
        </div>
      </div>

      <div class="pav-subscription--button" @click="onGetStarted">
        {{ $t('vega.getStarted') }}
      </div>
      <div class="pav-subscription--info">
        {{ $t('vega.needMoreInfo') }}
      </div>
      <div class="pav-subscription--link" @click="onGoToSeeMore">
        {{ $t('vega.seeMore') }}
      </div>
    </template>

    <template v-if="showActivationInfo">
      <div class="pav-subscription--dailylimit">
        <div class="pav-subscription--dailylimit--switch">
          <div class="ml-2 mr-2 message-container-upper pointer" style="margin-top: 5px" @click="onSetDailyLimit">
            <switch-on-icon color="#3034F7" :width="41" :height="20" v-if="vegaHasDailyLimit"></switch-on-icon>
            <switch-off-icon color="#B2B2B2" :width="41" :height="20" v-if="!vegaHasDailyLimit"></switch-off-icon>
          </div>
          <div>{{ $t('vega.subscription.setDailyLimit') }}</div>
        </div>
        <div v-if="vegaHasDailyLimit" class="pav-subscription--dailylimit--box">
          <div class="pav-subscription--dailylimit--box--label">
            {{ $t('vega.subscription.setYourDailyLimit') }}
          </div>
          <div v-if="!isDailyLimitEditMode" class="pav-subscription--dailylimit--box--price">{{ vegaDailyLimit }} €</div>
          <div v-else class="pav-subscription--dailylimit--box--input">
            <input type="number" v-model="vegaDailyLimit" :min="$vegaCostPerVisitor" step=".01" /> €
          </div>
          <div class="pav-subscription--dailylimit--box--icon" @click="onEditDailyLimit">
            <PencilIcon v-if="!isDailyLimitEditMode" />
            <SaveIcon v-else />
          </div>
        </div>
        <div class="pav-subscription--dailylimit--description">
          {{ $t('vega.subscription.dailyLimitDescription') }}
        </div>
      </div>
      <div class="pav-subscription--costs">
        <div class="pav-subscription--costs--month">
          <div class="pav-subscription--costs--month--select" @click="onShowSelectMonth">
            <div class="pav-subscription--costs--month--select--icon">
              <CalendarIcon />
            </div>
            <div class="pav-subscription--costs--month--select--text">
              {{ currentSelectedMonth }}
            </div>
            <div class="pav-subscription--costs--month--select--dropdown">
              <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 1L4.5 4L1 1" fill="#275D73" />
                <path d="M8 1L4.5 4L1 1H8Z" stroke="#275D73" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
          <transition name="fade-bottom-2x">
            <div v-if="showSelectMonth" class="pav-subscription--costs--month--box">
              <scroll-view class="pav-subscription--costs--month--box--scroll" v-if="showSelectMonth">
                <div
                  class="pav-subscription--costs--month--box--scroll--item"
                  :class="{ 'pav-subscription--costs--month--box--scroll--item--selected': item.text === selectedMonth.text }"
                  v-for="(item, index) in monthList"
                  :key="index"
                  @click="onSelectMonth(item)"
                >
                  {{ item.text }}
                </div>
              </scroll-view>
            </div>
          </transition>
        </div>
        <div class="pav-subscription--costs--cost">
          <div class="pav-subscription--costs--cost--icon">
            <div class="pav-subscription--costs--cost--info">
              <VegaPerAnalyzedIcon />
              <div class="pav-subscription--costs--cost--info--text">
                <div class="pav-subscription--costs--cost--info--text--heading">{{ vegaCostPerVisitor }}€</div>
                <div class="pav-subscription--costs--cost--info--text--subtext">
                  {{ $t('vega.subscription.perAnalyzedWebsiteVisitor') }}
                </div>
              </div>
            </div>
          </div>
          <div class="pav-subscription--costs--cost--current">{{ $t('vega.subscription.currentCosts') }}</div>
          <div class="pav-subscription--costs--cost--total">{{ totalCurrentCost }} €</div>
        </div>
      </div>
      <div v-if="!vegaIsActivated" class="pav-subscription--button-activate" @click="onActivateVega">
        {{ $t('vega.subscription.activateButtonText') }}
      </div>
    </template>

    <template v-if="vegaIsActivated">
      <div class="pav-subscription--actions">
        <div class="pav-subscription--actions--vega" @click="openVEGA">
          {{ $t('vega.subscription.openVEGA') }}
        </div>
        <div v-if="!isPaused" class="pav-subscription--actions--pause" @click="onPauseVega">
          <PauseIcon class="pav-subscription--actions--icon" />

          {{ $t('vega.subscription.pause') }}
        </div>
        <div v-else class="pav-subscription--actions--activate" @click="onUnPauseVega">
          <ActivateIcon class="pav-subscription--actions--icon" /> {{ $t('vega.subscription.activate') }}
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import CloseIcon from '@/components/icons/CloseIcon.vue'
import CheckIcon from './icons/CheckIcon.vue'
import VegaPerAnalyzedIcon from './icons/VegaPerAnalyzedIcon.vue'
import SwitchOnIcon from '@/components/icons/SwitchOnIcon.vue'
import SwitchOffIcon from '@/components/icons/SwitchOffIcon.vue'
import PencilIcon from './icons/PencilIcon.vue'
import SaveIcon from './icons/SaveIcon.vue'
import CalendarIcon from './icons/CalendarIcon'
import PauseIcon from './icons/PauseIcon.vue'
import ActivateIcon from './icons/ActivateIcon.vue'
import ScrollView from '@blackbp/vue-smooth-scrollbar'
import VegaMessage from '@/components/upgrade-messages/VegaMessage.vue'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(utc)
dayjs.extend(customParseFormat)

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'VegaSubscriptionGetStarted',
  props: {
    autoGetStarted: {
      type: Boolean,
      default: false
    },
    hasQuerySourceVega: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CloseIcon,
    CheckIcon,
    VegaPerAnalyzedIcon,
    SwitchOffIcon,
    SwitchOnIcon,
    PencilIcon,
    SaveIcon,
    CalendarIcon,
    PauseIcon,
    ActivateIcon,
    ScrollView,
    VegaMessage
  },
  data() {
    return {
      showSelectMonth: false,
      loaderSpinnerText: 'Processing...',
      isProcessing: false,
      isVegaActivationSuccessful: false,
      hasActivationProcessStarted: false,
      totalVisitors: 1000,
      currentVisitors: 0,
      chargedVisitors: 0,
      vegaHasDailyLimit: false,
      isDailyLimitEditMode: false,
      vegaDailyLimit: '20.00',
      selectedMonth: {
        text: dayjs().utc().format('MMMM YYYY'),
        month: dayjs().utc().format('MM'),
        year: dayjs().utc().format('YYYY'),
        monthName: dayjs().utc().format('MMMM')
      },
      unsubscribeSnapshot: null,
      isPaymentFailedClosed: false
    }
  },
  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser'
    }),

    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('vega') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },

    vegaCostPerVisitor() {
      return this.company && this.company.vegaCostPerVisitor ? this.company.vegaCostPerVisitor : this.$vegaCostPerVisitor
    },

    currentSelectedMonth() {
      return `${this.selectedMonth.monthName} ${this.selectedMonth.year}`
    },

    hasVegaPaymentFailed() {
      if (this.company && this.company.vegaPaymentFailed) return true
      return false
    },

    HAS_MOLLIE_MANDATE() {
      if (!this.company) return false
      return Boolean(this.company.mollieMandateId)
    },

    HAS_SUBSCRIPTION() {
      return (this.HAS_MOLLIE_SUBSCRIPTION && this.HAS_MOLLIE_MANDATE) || this.HAS_INVOICE_SUBSCRIPTION || this.HAS_MOLLIE_MANDATE
    },

    HAS_SUBSCRIPTION_PAYMENT_STATUS() {
      if (!this.company) return false
      return ['subscription-cancelled', 'subscription', 'on-trial', 'open', 'paid'].includes(this.company.paymentStatus)
    },

    HAS_PADDLE_SUBSCRIPTION() {
      return (
        this.company &&
        Boolean(this.company.subscriptionId) &&
        Boolean(this.company.subscriptionPlanId) &&
        this.HAS_SUBSCRIPTION_PAYMENT_STATUS &&
        this.company.planType !== 'free' &&
        this.company.planType !== 'trial'
      )
    },

    HAS_INVOICE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.currentInvoiceId) && this.HAS_SUBSCRIPTION_PAYMENT_STATUS
    },

    HAS_MOLLIE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.mollieCustomerId && this.company.mollieSubscriptionId) && this.HAS_SUBSCRIPTION_PAYMENT_STATUS
    },

    monthList() {
      const months = []
      const currentDate = dayjs()

      for (let i = 0; i < 12; i++) {
        const month = currentDate.subtract(i, 'month')
        const text = month.format('MMMM YYYY')
        const value = {
          month: month.format('MM'),
          year: month.format('YYYY'),
          monthName: month.format('MMMM')
        }

        months.push({
          text,
          value
        })
      }

      return months
    },

    totalCost() {
      const vegaCostPerVisitor = this.company && this.company.vegaCostPerVisitor ? this.company.vegaCostPerVisitor : this.$vegaCostPerVisitor
      const cost = this.totalVisitors * vegaCostPerVisitor
      return cost.toFixed(2)
    },
    totalCurrentCost() {
      const vegaCostPerVisitor = this.company && this.company.vegaCostPerVisitor ? this.company.vegaCostPerVisitor : this.$vegaCostPerVisitor
      const totalVisitorsCost = this.currentVisitors * vegaCostPerVisitor
      const totalChargedCost = this.chargedVisitors * vegaCostPerVisitor
      const cost = totalVisitorsCost + totalChargedCost
      return cost.toFixed(2)
    },
    totalCostText() {
      const text = this.$i18n.t('vega.subscription.actualPriceText')
      return text.replace('{{price}}', this.vegaCostPerVisitor)
    },
    showGetStarted() {
      if (!this.company) return true

      if (this.hasActivationProcessStarted) return false

      if (!this.company.vegaIsActivated) return true
      if (this.company && this.company.vegaIsActivated) return false

      return true
    },
    showActivationInfo() {
      if (this.company && this.company.vegaIsActivated) return true
      if (this.hasActivationProcessStarted) return true

      return false
    },
    vegaIsActivated() {
      if (this.company && this.company.vegaIsActivated) return true
      return false
    },
    isPaused() {
      if (this.company && this.company.vegaIsPaused) return true
      return false
    }
  },
  watch: {
    'company.vegaDailyLimit'() {
      if (this.company && this.company.vegaDailyLimit) {
        this.vegaDailyLimit = this.company.vegaDailyLimit
        this.setVegaDailyLimit(this.company.vegaDailyLimit)
      }
    },
    'company.vegaHasDailyLimit'() {
      if (this.company && this.company.vegaHasDailyLimit) {
        this.vegaHasDailyLimit = this.company.vegaHasDailyLimit
        this.setVegaHasDailyLimit(this.company.vegaHasDailyLimit)
      }
    },
    selectedMonth: {
      deep: true,
      handler() {
        if (this.unsubscribeSnapshot) {
          this.unsubscribeSnapshot() // Unsubscribe from the previous snapshot
        }
        this.fetchTotalVisitors()
      }
    }
  },
  created() {},
  mounted() {
    if (this.company && this.company.vegaDailyLimit) {
      this.vegaDailyLimit = this.company.vegaDailyLimit
      this.setVegaDailyLimit(this.vegaDailyLimit)
    }
    if (this.company && this.company.vegaHasDailyLimit) {
      this.vegaHasDailyLimit = this.company.vegaHasDailyLimit
      this.setVegaHasDailyLimit(this.vegaHasDailyLimit)
    }
    this.fetchTotalVisitors()
    if (this.autoGetStarted) {
      this.onGetStarted()
    }
  },
  methods: {
    ...mapMutations({
      updateShowChangePlanUI: 'UPDATE_SHOW_CHANGE_PLAN_UI',
      setShowMollieVega: 'SET_SHOW_MOLLIE_VEGA',
      setVegaHasDailyLimit: 'SET_VEGA_HAS_DAILY_LIMIT',
      setVegaDailyLimit: 'SET_VEGA_DAILY_LIMIT'
    }),
    onClosePaymentFailedUI() {
      const url = window.location.href
      // Remove query parameters
      const cleanUrl = url.split('?')[0]
      // Replace the current URL without query parameters
      history.replaceState(null, null, cleanUrl)
      this.isPaymentFailedClosed = true
    },
    onGoToSeeMore() {
      window.open('https://www.letsconnect.at/visitor-prequalification', '_blank')
    },
    onShowSelectMonth() {
      this.showSelectMonth = !this.showSelectMonth
    },
    onSelectMonth(item) {
      const selectedItem = {
        text: item.text,
        month: item.value.month,
        monthName: item.value.monthName,
        year: item.value.year
      }
      this.selectedMonth = selectedItem
      this.showSelectMonth = false
    },
    getMonthAbbreviation(month, year) {
      // Create a dayjs object with the month and a fixed year (since we only care about the month)
      const monthDate = dayjs(`${year}-${month}-01`, 'YYYY-MM-DD')

      // Format the month as a three-letter abbreviation
      const monthAbbreviation = monthDate.format('MMM').toUpperCase()

      return monthAbbreviation
    },
    fetchTotalVisitors() {
      const companyId = this.activeUserInfo.company
      const { year, month } = this.selectedMonth
      const monthAbbreviation = this.getMonthAbbreviation(month, year)

      const docRef = this.$db.collection('vega-visitors-count').doc(companyId).collection('year').doc(year).collection('month').doc(monthAbbreviation)

      // Set up a real-time listener
      this.unsubscribeSnapshot = docRef.onSnapshot(
        (doc) => {
          if (doc.exists) {
            this.currentVisitors = doc.data().totalVisitors || 0
            this.chargedVisitors = doc.data().chargedVisitors || 0
          } else {
            this.currentVisitors = 0
            this.chargedVisitors = 0
          }
        },
        (error) => {
          this.currentVisitors = 0
          this.chargedVisitors = 0
          /* eslint-disable no-console */
          console.error('Error fetching data:', error)
        }
      )
    },
    async onPauseVega() {
      try {
        this.isProcessing = true
        const _info = {
          vegaIsPaused: true
        }
        await this.$db.collection('company').doc(this.activeUserInfo.company).set(_info, { merge: true })
        this.isProcessing = false
      } catch (error) {
        this.isProcessing = false
        console.log(error.message)
      }
    },
    async onUnPauseVega() {
      try {
        this.isProcessing = true
        const _info = {
          vegaIsPaused: false
        }
        await this.$db.collection('company').doc(this.activeUserInfo.company).set(_info, { merge: true })
        this.isProcessing = false
      } catch (error) {
        this.isProcessing = false
        /* eslint-disable no-console */
        console.log(error.message)
      }
    },

    async onActivateVega() {
      if (!this.company || this.company.vegaIsActivated) {
        return
      }

      const _info = {
        vegaIsOnTrial: false,
        vegaIsActivated: true,
        vegaHasDailyLimit: this.vegaHasDailyLimit,
        vegaDailyLimit: this.vegaDailyLimit ? this.vegaDailyLimit : '20.00',
        vegaIsPaused: false,
        vegaExpiryDate: dayjs().utc().add(40, 'days').toDate()
      }
      if (!this.company.vegaActivationDate) {
        _info.vegaActivationDate = dayjs().utc().toDate()
      }
      if (!this.company.vegaCostPerVisitor) {
        _info.vegaCostPerVisitor = this.$vegaCostPerVisitor
      }

      this.$serverBus.$emit('vega-funnel-paying-process', 'clicked-activate-pay-per-analysed-visitor-now')

      try {
        if (this.HAS_SUBSCRIPTION) {
          await this.updateCompanyInfoAndShowActivation(_info)
        } else if (this.HAS_MOLLIE_SUBSCRIPTION && !this.HAS_MOLLIE_MANDATE) {
          await this.handleHasMollieSubscriptionWithoutMandate(_info)
        } else {
          this.showMollieVegaAndUpdateUI()
        }
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
        this.isProcessing = false
      }
    },

    async handleHasMollieSubscriptionWithoutMandate(_info) {
      this.$serverBus.$emit('vega-funnel-paying-process', 'check-for-mollie-mandate')

      const querySnapshot = await this.$db
        .collection('mollie-payments')
        .where('companyId', '==', this.activeUserInfo.company)
        .where('status', 'array-contains', 'paid')
        .orderBy('createdAtTS', 'desc')
        .limit(1)
        .get()

      if (!querySnapshot.empty) {
        const payment = querySnapshot.docs[0].data()
        if (payment && payment.transaction && payment.transaction.mandateId) {
          _info.mollieMandateId = payment.transaction.mandateId
          await this.updateCompanyInfoAndShowActivation(_info)
        } else {
          this.showMollieVegaAndUpdateUI()
        }
      } else {
        this.showMollieVegaAndUpdateUI()
      }
    },

    async updateCompanyInfoAndShowActivation(_info) {
      this.$serverBus.$emit('vega-funnel-paying-process', 'activated-vega')

      this.isProcessing = true
      await this.$db.collection('company').doc(this.activeUserInfo.company).set(_info, { merge: true })
      this.isProcessing = false
      this.isVegaActivationSuccessful = true
      setTimeout(() => {
        this.isVegaActivationSuccessful = false
      }, 3000)
    },

    showMollieVegaAndUpdateUI() {
      this.$serverBus.$emit('vega-funnel-paying-process', 'start-vega-payment-process')
      this.setShowMollieVega(true)
      this.updateShowChangePlanUI(true)
    },

    onGetStarted() {
      this.$serverBus.$emit('vega-funnel-paying-process', 'activation-process-started')

      this.hasActivationProcessStarted = true
    },
    openVEGA() {
      this.$router.push({ name: 'vega' })
    },
    onSetDailyLimit() {
      this.vegaHasDailyLimit = !this.vegaHasDailyLimit
    },
    async onEditDailyLimit() {
      if (!this.isDailyLimitEditMode) {
        this.isDailyLimitEditMode = true
      } else {
        await this.$vs.loading()
        const _dailylimit = {
          vegaHasDailyLimit: this.vegaHasDailyLimit,
          vegaDailyLimit: this.vegaDailyLimit ? this.vegaDailyLimit : '20.00'
        }
        const _dailylimitLogs = {
          type: 'vega-daily-limit',
          vegaHasDailyLimit: this.vegaHasDailyLimit,
          vegaDailyLimit: this.vegaDailyLimit ? this.vegaDailyLimit : '20.00',
          updatedBy: this.activeUserInfo.uid,
          updatedByName: `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`,
          updated: dayjs().utc().toDate(),
          before: {
            vegaHasDailyLimit: this.company.vegaHasDailyLimit || null,
            vegaDailyLimit: this.company.vegaDailyLimit || null
          }
        }

        await this.$db.collection('company').doc(this.activeUserInfo.company).set(_dailylimit, { merge: true })

        if (
          _dailylimitLogs.before.vegaHasDailyLimit !== _dailylimit.vegaHasDailyLimit ||
          _dailylimitLogs.before.vegaDailyLimit !== _dailylimit.vegaDailyLimit
        ) {
          await this.$db.collection('company').doc(this.activeUserInfo.company).collection('logs').add(_dailylimitLogs)
        }

        this.isDailyLimitEditMode = false
        await this.$vs.loading.close()
      }
    }
  },

  beforeDestroy() {
    if (this.unsubscribeSnapshot) {
      this.unsubscribeSnapshot() // Unsubscribe before the component is destroyed
    }
  }
}
</script>
<style lang="scss">
.fade-bottom-2x-enter-active,
.fade-bottom-2x-leave-active {
  transition: opacity 0.2s, transform 0.25s;
}
.fade-bottom-2x-enter {
  opacity: 0;
  transform: translateY(-4%);
}

.fade-bottom-2x-leave-to {
  opacity: 0;
  transform: translateY(4%);
}

.pav-subscription-usage-item2--info {
  .vs-slider-primary .vs-circles-slider {
    border: 1px solid #c7c7c7 !important;
  }
  .vs-slider {
    background: linear-gradient(180deg, #626262 0%, #d7d7d7 100%);
    border-radius: 10px;

    .vs-slider-line-one {
      background: #3034f7;
      border-radius: 10px;
    }
  }
}
</style>
<style lang="scss" scoped>
.pav-subscription {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1118px;
  max-height: 718px;
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
    z-index: 999;

    &__loader {
      text-align: center;

      &__spinner {
        display: inline-block;
        width: 70px;
        height: 70px;
        border: 10px solid rgb(222, 241, 254);
        border-bottom-color: rgb(66, 181, 255);
        border-radius: 50%;
        animation: rotation 1s linear infinite;
      }

      &__title {
        margin-top: 28px;
        color: rgb(38, 38, 41);
        font-family: 'Larsseit-Bold';
        font-size: 28px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.155556px;
      }

      &__text {
        margin-top: 6px;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.111111px;
        color: rgb(83, 83, 91);
      }
    }
  }

  &--header {
    font-family: 'Larsseit-Medium';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.177778px;
    color: #262629;
  }

  &--description {
    margin-top: 31px;
    font-family: 'Larsseit-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.111111px;
    color: #53535b;

    padding: 0px 171px 0px 197px;

    @media (max-width: 1024px) {
      padding: 0px 100px 0px 121px;
    }
    @media (max-width: 800px) {
      padding: 0px 80px 0px 89px;
    }
    @media (max-width: 600px) {
      padding: 0px 30px 0px 37px;
    }
  }

  &-features {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 0px 30px 0px 30px;

    &--item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      font-family: 'Larsseit-Regular';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.0888889px;
      color: #262629;

      margin-right: 34px;

      &--icon {
        margin-right: 6px;
      }
    }

    &--item:last-child {
      margin-right: 0px;
    }
  }

  &-usage {
    width: 90%;
    height: 146px;

    max-width: 1018px;
    max-height: 146px;

    display: flex;
    flex-direction: column;
    margin: 50px;

    &-box {
      background: #f8fbfe;
      box-shadow: 0px 2px 10px rgba(40, 167, 249, 0.2);
      border-radius: 8px;

      display: flex;
      flex-direction: row;
      &-item1 {
        flex: 3;
        display: flex;
        flex-direction: column;
        max-width: 237px;
        height: 146px;

        &--header {
          background: #d1ecff;
          box-shadow: 0px 2px 10px rgba(40, 167, 249, 0.2);
          border-radius: 8px 0px 0px 0px;
          height: 62px;
        }
        &--info {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;

          background: #f8fbfe;
          box-shadow: 0px 0px 10px rgba(40, 167, 249, 0.2);
        }
      }

      &-item2 {
        flex: 6;
        display: flex;
        flex-direction: column;
        max-width: 465px;
        height: 146px;

        &--header {
          background: #d1ecff;
          box-shadow: 0px 2px 10px rgba(40, 167, 249, 0.2);
          border-radius: 0px 0px 0px 0px;
          height: 62px;
          display: flex;
          justify-content: center;
          align-items: center;

          font-family: 'Larsseit-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 22px;
          text-align: center;
          letter-spacing: -0.111111px;
          color: #3034f7;
        }
        &--info {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          padding: 0px 30px;
          background: #f8fbfe;
          box-shadow: 0px 0px 10px rgba(40, 167, 249, 0.2);

          &--slider {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            margin-right: 46px;
          }
        }
      }

      &-item3 {
        flex: 4;
        display: flex;
        flex-direction: column;
        max-width: 316px;
        height: 146px;

        &--header {
          background: #a2d8ff;
          box-shadow: 0px 2px 10px rgba(40, 167, 249, 0.2);
          border-radius: 0px 8px 0px 0px;
          height: 62px;
          display: flex;
          justify-content: center;
          align-items: center;

          font-family: 'Larsseit-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 22px;
          text-align: center;
          letter-spacing: -0.111111px;
          color: #3034f7;
        }
        &--info {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;

          background: #f8fbfe;
          box-shadow: 0px 0px 10px rgba(40, 167, 249, 0.2);
        }
      }
    }

    &--cost {
      align-self: flex-end;
      margin-top: 20px;
      margin-right: 20px;
    }
  }

  &--button {
    margin-top: 40px;
    background: #275D73;
    border-radius: 8px;
    width: 164px;
    height: 60px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Larsseit-Medium';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: -0.111111px;
    color: #ffffff;
  }

  &--info {
    margin-top: 30px;
    font-family: 'Larsseit-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    letter-spacing: 0.05px;
    color: #53535b;
  }

  &--link {
    cursor: pointer;
    font-family: 'Larsseit-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.05px;
    color: #3034f7;
  }

  &--dailylimit {
    margin-top: 40px;
    width: 480px;

    display: flex;
    flex-direction: column;

    &--switch {
      cursor: pointer;
      align-self: flex-start;
      display: flex;
      align-items: center;
    }

    &--box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 480px;
      height: 60px;
      margin-top: 20px;
      background: #f8fbfe;
      box-shadow: 0px 2px 10px rgba(40, 167, 249, 0.2);
      border-radius: 8px;
      padding: 20px;

      &--label {
        font-family: 'Larsseit-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.111111px;
        color: #878894;
      }
      &--price {
        font-family: 'Larsseit-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        text-align: right;
        letter-spacing: -0.111111px;
        color: #262629;
      }

      &--input {
        font-family: 'Larsseit-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        text-align: right;
        letter-spacing: -0.111111px;
        color: #262629;
        border: none;
        background: none;

        input {
          width: 85px;
          font-family: 'Larsseit-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 20px;
          text-align: right;
          letter-spacing: -0.111111px;
          color: #262629;
          border: none;
          background: none;
        }
      }

      &--icon {
        cursor: pointer;
      }
    }

    &--description {
      margin-top: 20px;
      font-family: 'Larsseit-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.111111px;
      color: #53535b;
    }
  }

  &--costs {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 480px;

    &--month {
      align-self: flex-end;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;

      &--select {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;

        &--text {
          margin-left: 8px;
          cursor: pointer;
        }
        &--dropdown {
          margin-left: 20px;
          cursor: pointer;
        }
      }

      &--box {
        position: absolute;
        z-index: 10;
        margin-top: 5px;
        width: 176px;
        height: 200px;
        background: #ffffff;
        border: 0.5px solid #275D73;
        border-radius: 8px;
        top: 21px;
        right: 0px;
        overflow: hidden;

        &--scroll {
          margin-top: 15px;
          &--item {
            height: 30px;
            padding-left: 9px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            font-family: 'Larsseit-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            &--selected {
              background: #f8fbfe;
              color: #275D73;
            }
          }

          height: 175px;
          .scroll-content {
            height: 175px;
          }
        }
      }

      select {
        border: none;
        margin-left: 8px;
        font-family: 'Larsseit';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.111111px;
        color: #53535b;
      }
    }
    &--cost {
      position: relative;
      background: #f8fbfe;
      box-shadow: 0px 2px 10px rgba(40, 167, 249, 0.2);
      border-radius: 8px;
      height: 110px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 480px;

      &--icon {
        position: absolute;
        left: -140px;
      }

      &--info {
        position: relative;

        &--text {
          position: absolute;
          display: flex;
          flex-direction: column;
          width: 60px;
          bottom: 35px;
          right: 14px;

          &--heading {
            color: #53535b;
            font-family: Larsseit;
            font-size: 13.476px;
            font-style: normal;
            font-weight: 700;
            line-height: 9.882px; /* 73.333% */
            letter-spacing: -0.05px;
            text-align: center;
          }
          &--subtext {
            color: #53535b;
            font-family: Larsseit;
            font-size: 6.738px;
            font-style: normal;
            font-weight: 400;
            line-height: 8.984px; /* 133.333% */
            letter-spacing: -0.05px;
            text-align: center;
            margin-top: 3px;
          }
        }
      }

      &--current {
        font-family: 'Larsseit-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.111111px;
        color: #737373;
      }
      &--total {
        margin-top: 15px;
        font-family: 'Larsseit-Medium';
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
        line-height: 22px;
        letter-spacing: -0.111111px;
        color: #262629;
      }
    }
  }

  &--button-activate {
    margin-top: 34px;
    cursor: pointer;
    height: 60px;
    background: #1c8439;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 21px 65px;

    font-family: 'Larsseit-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: -0.111111px;
    color: #ffffff;

    &--disabled {
      background-color: #979797;
    }
  }

  &--actions {
    display: flex;
    position: absolute;
    bottom: 10px;
    left: 10px;

    font-family: 'Larsseit-Medium';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: -0.111111px;
    color: #ffffff;

    &--icon {
      margin-right: 10px;
    }

    &--vega {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #275D73;
      border-radius: 8px;
      padding: 21px 30px;
      cursor: pointer;
      margin-right: 20px;
    }

    &--pause {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f0a941;
      border-radius: 8px;
      padding: 21px 30px;
      cursor: pointer;
    }

    &--activate {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #1c8439;
      border-radius: 8px;
      padding: 21px 30px;
      cursor: pointer;
    }
  }
}
</style>
