var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "17",
        height: "16",
        viewBox: "0 0 17 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: { width: "16", height: "16", rx: "6", fill: "#C8E2F3" },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M7.76658 10.5166C8.06994 10.826 8.48251 11 8.91058 11C8.93165 11 8.95272 11 8.97167 10.9978C9.42253 10.9807 9.84602 10.7723 10.1386 10.4241L16.6103 2.72337C17.1917 2.03168 17.1138 0.989327 16.4354 0.396643C15.7592 -0.194253 14.7372 -0.116902 14.1541 0.574998L8.82497 6.98247L6.76213 4.94039C6.13013 4.2958 5.10646 4.2958 4.47412 4.94039C3.84179 5.58499 3.84213 6.62906 4.47412 7.274L7.76658 10.5166Z",
          fill: "#3034F7",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }