var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lastPayment
    ? _c("div", { staticClass: "lc-payment-method" }, [
        _c("div", { staticClass: "lc-payment-method--label fw-700" }, [
          _vm._v(_vm._s(_vm.$t("pricing.paymentMethod"))),
        ]),
        _c("div", { staticClass: "lc-payment-method--detail" }, [
          _c("div", { staticClass: "lc-payment-method--detail--card" }, [
            _vm.paymentMethod
              ? _c(
                  "div",
                  { staticClass: "lc-payment-method--detail--card--icon" },
                  [
                    _vm.paymentMethod === "creditcard"
                      ? [
                          _c("CreditCardIcon"),
                          _c("CheckIcon", {
                            staticClass:
                              "lc-payment-method--detail--card--check",
                          }),
                        ]
                      : _vm._e(),
                    _vm.paymentMethod === "paypal"
                      ? [
                          _c("PaypalIcon"),
                          _c("CheckIcon", {
                            staticClass:
                              "lc-payment-method--detail--card--check",
                          }),
                        ]
                      : _vm._e(),
                    _vm.paymentMethod === "invoice"
                      ? [
                          _c("InvoiceIcon"),
                          _c("CheckIcon", {
                            staticClass:
                              "lc-payment-method--detail--card--check",
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm.paymentMethodText
              ? _c(
                  "div",
                  { staticClass: "lc-payment-method--detail--card-detail" },
                  [
                    _c("div", { staticClass: "lc-payment-method--card-text" }, [
                      _vm._v(_vm._s(_vm.paymentMethodText)),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
          _vm.SHOW_PAYMENT_METHOD
            ? _c(
                "div",
                {
                  staticClass: "lc-payment-method--detail--update fw-700",
                  on: { click: _vm.updatePaymentMethod },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("vue.updatePaymentMethod")) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }