var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lc-sub-details" },
    [
      _c(
        "div",
        { staticClass: "lc-sub-details_left-column" },
        [
          _vm.HAS_SUBSCRIPTION || _vm.IS_SUBSCRIPTION_CANCELLED
            ? _c("PlanInfo")
            : _vm._e(),
          _vm.WAS_GPT_ACTIVATED ? _c("GptModule") : _vm._e(),
          _vm.WAS_VEGA_ACTIVATED ? _c("VegaModule") : _vm._e(),
          !_vm.WAS_VEGA_ACTIVATED &&
          (_vm.HAS_INTERNAL_SUBSCRIPITON || _vm.IS_SUBSCRIPTION_CANCELLED)
            ? _c("PaymentMethod")
            : _vm._e(),
        ],
        1
      ),
      _vm.HAS_SUBSCRIPTION ||
      _vm.WAS_VEGA_ACTIVATED ||
      _vm.IS_SUBSCRIPTION_CANCELLED
        ? _c(
            "div",
            { staticClass: "lc-sub-details_right-column" },
            [_c("PaymentDetail")],
            1
          )
        : _vm._e(),
      !_vm.HAS_SHOWN_PAYMENT_METHOD_BELOW_VEGA_MODULE &&
      ((_vm.HAS_INTERNAL_SUBSCRIPITON && _vm.WAS_VEGA_ACTIVATED) ||
        _vm.IS_SUBSCRIPTION_CANCELLED)
        ? _c("div", { staticClass: "lc-sub-details_bottom-row" }, [
            _c(
              "div",
              { staticClass: "lc-sub-details_item-full-width" },
              [_c("PaymentMethod")],
              1
            ),
          ])
        : _vm._e(),
      _c("CancelSubscription"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }