const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

import { mapGetters } from 'vuex'
let plans = null
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'pathadvice-app') {
  const plansModule = require('@/assets/plans/prod_plans.js')
  plans = plansModule.plans
} else {
  const plansModule = require('@/assets/plans/stage_plans.js')
  plans = plansModule.plans
}
const gptPlansModule = require('@/assets/plans/gpt_plans.js')
const gptPlans = gptPlansModule.plans

export const mcancelsubscription = {
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      currentUserInfo: 'activeUser',
      company: 'company',
      subscription: 'subscription',
      gptSubscription: 'gpt/gptSubscription'
    }),
    allPlans() {
      let mergedPlans = []

      if (plans && plans.length) {
        mergedPlans = [...mergedPlans, ...plans]
      }

      if (gptPlans && gptPlans.length) {
        mergedPlans = [...mergedPlans, ...gptPlans]
      }
      return mergedPlans
    },

    subscriptionList() {
      const list = []
      if ((this.HAS_INVOICE_SUBSCRIPTION || this.HAS_MOLLIE_SUBSCRIPTION || this.HAS_PADDLE_SUBSCRIPTION) && !this.IS_LETSCONNECT_SUBSCRIPTION_CANCELLED) {
        let subscription = null
        if (this.HAS_PADDLE_SUBSCRIPTION) {
          subscription = {
            companyId: this.activeUserInfo.company,
            subscription_id: this.subscription.subscription_id,
            source: 'paddle'
          }
        }
        if (this.HAS_MOLLIE_SUBSCRIPTION) {
          subscription = {
            companyId: this.activeUserInfo.company,
            mollieSubscriptionId: this.company.mollieSubscriptionId,
            mollieCustomerId: this.company.mollieCustomerId,
            source: 'mollie'
          }
        } else if (this.HAS_INVOICE_SUBSCRIPTION) {
          subscription = {
            companyId: this.activeUserInfo.company,
            currentInvoiceId: this.company.currentInvoiceId,
            source: 'invoice'
          }
        }
        if (subscription) {
          list.push({ name: "Let's Connect", enabled: false, subscription })
        }
      }

      if (this.HAS_GPT_SUBSCRIPTION && !this.IS_GPT_SUBSCRIPTION_CANCELLED) {
        let subscription = null
        if (this.HAS_GPT_INVOICE_SUBSCRIPTION) {
          subscription = {
            companyId: this.activeUserInfo.company,
            gptCurrentInvoiceId: this.company.gptCurrentInvoiceId,
            source: 'gpt-invoice'
          }
        }

        if (this.HAS_GPT_MOLLIE_SUBSCRIPTION) {
          subscription = {
            companyId: this.activeUserInfo.company,
            mollieGptSubscriptionId: this.company.mollieGptSubscriptionId,
            mollieCustomerId: this.company.mollieCustomerId,
            source: 'gpt-mollie'
          }
        }

        if (subscription) {
          list.push({ name: 'GPT Connect', enabled: false, subscription })
        }
      }

      if (this.HAS_VEGA_SUBSCRIPTION) {
        const subscription = { source: 'vega', companyId: this.activeUserInfo.company }
        list.push({ name: 'VEGA', enabled: false, subscription })
      }

      return list
    },

    HAS_APPSUMO_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company && this.company.isAppSumoAccount)
    },

    HAS_INVOICE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.currentInvoiceId)
    },

    IS_LETSCONNECT_SUBSCRIPTION_CANCELLED() {
      if (this.company && this.company.paymentStatus === 'subscription-cancelled') {
        return true
      }
      if (this.subscription && (this.subscription.subscriptionStatus === 'canceled' || this.subscription.status === 'canceled')) {
        return true
      }
      return false
    },

    HAS_MOLLIE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.mollieCustomerId && this.company.mollieSubscriptionId && this.company.paymentStatus !== 'subscription-cancelled')
    },

    HAS_GPT_INVOICE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.gptCurrentInvoiceId)
    },

    IS_GPT_SUBSCRIPTION_CANCELLED() {
      if (this.company && this.company.gptPaymentStatus === 'subscription-cancelled') {
        return true
      }
      if (this.gptSubscription && (this.gptSubscription.subscriptionStatus === 'canceled' || this.gptSubscription.status === 'canceled')) {
        return true
      }
      return false
    },

    HAS_MOLLIE_MANDATE() {
      if (!this.company) return false
      return this.company.mollieMandateId
    },

    HAS_GPT_SUBSCRIPTION() {
      return (this.HAS_GPT_MOLLIE_SUBSCRIPTION && this.HAS_MOLLIE_MANDATE) || this.HAS_GPT_INVOICE_SUBSCRIPTION
    },

    HAS_VEGA_SUBSCRIPTION() {
      if (this.company && this.company.vegaIsActivated && this.company.vegaExpiryDate && !this.company.vegaIsOnTrial) {
        const now = dayjs.utc()
        const vegaExpiryDayjs = dayjs.unix(this.vegaCompany.vegaExpiryDate.seconds).utc()
        return now.isBefore(vegaExpiryDayjs)
      }

      if (this.company && this.company.vegaExpiryDate && this.company.vegaIsOnTrial) {
        const now = dayjs.utc()
        const vegaExpiryDayjs = dayjs.unix(this.vegaCompany.vegaExpiryDate.seconds).utc()
        return now.isBefore(vegaExpiryDayjs)
      }
      return false
    },

    HAS_GPT_MOLLIE_SUBSCRIPTION() {
      return (
        this.company &&
        Boolean(this.company.gptSubscriptionPlanId) &&
        Boolean(this.company.mollieGptSubscriptionId) &&
        Boolean(this.company.mollieCustomerId) &&
        this.company.gptPaymentStatus !== 'subscription-cancelled' &&
        this.company.gptExpiryDate &&
        dayjs().isBefore(dayjs.unix(this.company.gptExpiryDate.seconds))
      )
    },

    HAS_PADDLE_SUBSCRIPTION() {
      return (
        this.company &&
        (this.company.paymentStatus === 'subscription' || this.company.paymentStatus === 'deleted') &&
        this.company.subscriptionId &&
        this.company.subscriptionPlanId &&
        !this.company.mollieSubscriptionId
      )
    },

    CAN_CANCEL_SUBSCRIPTION() {
      let canCancelMollie = false,
        canCancelPaddle = false,
        canCancelInvoice = false,
        canCancelGPT = false,
        canCancelVega = false

      if (this.HAS_MOLLIE_SUBSCRIPTION) {
        const isSubscriptionCanceled = Boolean(this.subscription && this.subscription.canceledAt)
        const paymentStatus = this.company.paymentStatus === 'subscription' || this.company.paymentStatus === 'on-trial'
        canCancelMollie = paymentStatus && !isSubscriptionCanceled
      }

      if (this.HAS_PADDLE_SUBSCRIPTION) {
        canCancelPaddle = this.subscription && this.subscription.cancel_url && this.company && this.company.paymentStatus === 'subscription'
      }

      if (this.HAS_INVOICE_SUBSCRIPTION) {
        canCancelInvoice = this.company && this.company.paymentStatus !== 'subscription-cancelled'
      }

      if (this.HAS_GPT_SUBSCRIPTION) {
        canCancelGPT = this.company.gptPaymentStatus !== 'subscription-cancelled'
        const isSubscriptionCanceled = Boolean(this.gptSubscription && this.gptSubscription.canceledAt)
        const paymentStatus = this.company.gptPaymentStatus !== 'subscription-cancelled'
        canCancelGPT = paymentStatus && !isSubscriptionCanceled
      }

      if (this.HAS_VEGA_SUBSCRIPTION) {
        canCancelVega = true
      }

      return canCancelMollie || canCancelPaddle || canCancelInvoice || canCancelGPT || canCancelVega
    }
  },
  methods: {}
}
