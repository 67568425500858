var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pricing-subscription" },
    [
      _c(
        "connect-modal",
        {
          attrs: {
            show: _vm.showMollieComponent,
            width: "505px",
            bkcolor: "#F7F7F7",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "price-list-mollie-close-button",
              staticStyle: { "margin-left": "auto", cursor: "pointer" },
              on: { click: _vm.onMollieFormClose },
            },
            [
              _c("close-circle-icon", {
                staticClass:
                  "price-list-mollie-close-button-icon pa-bk-secondary",
                attrs: { "show-circle": false, width: 14, height: 14 },
              }),
            ],
            1
          ),
          _vm.subscription &&
          _vm.subscription.planDetails &&
          _vm.showMollieComponent
            ? _c("mollie", {
                attrs: {
                  selectedPlan: _vm.subscription.planDetails,
                  subscriptionPlanId:
                    _vm.subscription.planDetails.id.toString(),
                  isFirstInvoice: true,
                  isModal: true,
                  supportedPaymentMethods: _vm.supportedPaymentMethods,
                  updatePaymentMethod: true,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "connect-modal",
        {
          attrs: {
            show: _vm.showMollieVegaComponent,
            width: "505px",
            bkcolor: "#F7F7F7",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "price-list-mollie-close-button",
              staticStyle: { "margin-left": "auto", cursor: "pointer" },
              on: { click: _vm.onMollieFormClose },
            },
            [
              _c("close-circle-icon", {
                staticClass:
                  "price-list-mollie-close-button-icon pa-bk-secondary",
                attrs: { "show-circle": false, width: 14, height: 14 },
              }),
            ],
            1
          ),
          _vm.showMollieVegaComponent
            ? _c("MollieVega", {
                attrs: {
                  isModal: true,
                  supportedPaymentMethods: _vm.supportedPaymentMethods,
                  updatePaymentMethod: true,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "connect-modal",
        {
          attrs: {
            show: _vm.showMollieGptComponent,
            width: "505px",
            bkcolor: "#F7F7F7",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "price-list-mollie-close-button",
              staticStyle: { "margin-left": "auto", cursor: "pointer" },
              on: { click: _vm.onMollieFormClose },
            },
            [
              _c("close-circle-icon", {
                staticClass:
                  "price-list-mollie-close-button-icon pa-bk-secondary",
                attrs: { "show-circle": false, width: 14, height: 14 },
              }),
            ],
            1
          ),
          _vm.showMollieGptComponent
            ? _c("MollieGpt", {
                attrs: {
                  isModal: true,
                  supportedPaymentMethods: _vm.supportedPaymentMethods,
                  updatePaymentMethod: true,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "pricing__heading" }, [
        _c("h3", { staticClass: "pricing__heading__title" }, [
          _vm._v(_vm._s(_vm.$t("vue.subscriptionDetail"))),
        ]),
        _c("p", { staticClass: "pricing__heading__description" }, [
          _vm._v(_vm._s(_vm.$t("vue.subscriptionDescription"))),
        ]),
      ]),
      _vm.CAN_SHOW_SUBSCRIPTION ? _c("SubscriptionDetail") : _vm._e(),
      _c(
        "vs-popup",
        {
          staticClass: "subscription-popup",
          attrs: {
            title: _vm.$t("vue.cancelSubscription"),
            active: _vm.cancelPrompt,
          },
          on: {
            "update:active": function ($event) {
              _vm.cancelPrompt = $event
            },
          },
        },
        [
          _vm.subscriptionList.length > 0
            ? _c(
                "div",
                { staticClass: "subscription-popup-body" },
                _vm._l(_vm.subscriptionList, function (subscription, d) {
                  return _c(
                    "div",
                    {
                      key: d,
                      staticClass: "vx-row subscription-popup-list-item",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "ma-0 pt-0 pb-0 checkbox-main",
                              attrs: { "hide-details": "" },
                              on: { change: _vm.onCancellationListUpdated },
                              model: {
                                value: subscription.enabled,
                                callback: function ($$v) {
                                  _vm.$set(subscription, "enabled", $$v)
                                },
                                expression: "subscription.enabled",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(subscription.name) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "subscription-popup-footer" },
            [
              _c(
                "vs-button",
                {
                  key: _vm.updateKey,
                  attrs: {
                    color: "#FF004E",
                    type: "filled",
                    disabled:
                      _vm.subscriptionList.filter(function (x) {
                        return x.enabled
                      }).length === 0,
                  },
                  on: {
                    click: function ($event) {
                      _vm.cancelPromptConfirm = true
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("vue.cancelSubscription")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "vs-popup",
            {
              staticClass: "subPopup",
              attrs: {
                title: _vm.$t("vue.cancelSubscription"),
                active: _vm.cancelPromptConfirm,
              },
              on: {
                "update:active": function ($event) {
                  _vm.cancelPromptConfirm = $event
                },
              },
            },
            [
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.$t("vue.confirmQuestion"))),
              ]),
              _c(
                "vs-button",
                {
                  staticClass: "mr-2",
                  attrs: { color: "#e8546f", type: "filled" },
                  on: { click: _vm.cancelSubscriptionConfirm },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("vue.confirm")) + "\n      "
                  ),
                ]
              ),
              _c(
                "vs-button",
                {
                  attrs: { type: "border", color: "#e8546f", enabled: "" },
                  on: {
                    click: function ($event) {
                      _vm.cancelPromptConfirm = false
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("vue.cancel")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }