var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "data-list-container pricing-subscription pricing-invoices",
      attrs: { id: "data-list-thumb-view" },
    },
    [
      _c(
        "connect-modal",
        {
          attrs: {
            show: _vm.showInvoiceRecipients,
            width: "750px",
            bkcolor: "#F7F7F7",
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex-flex-column pricing-invoices__popup" },
            [
              _c("div", { staticClass: "pricing-invoices__popup__heading" }, [
                _vm._v(_vm._s(_vm.$t("vue.invoiceRecipients"))),
              ]),
              _vm._l(
                _vm.invoiceRecipients.filter(function (x) {
                  return !x.isDeleted
                }),
                function (recipient, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "pricing-invoices__popup__recipients",
                    },
                    [
                      !recipient.isEdit
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-invoices__popup__recipients-recipient",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(recipient.email) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-invoices__popup__recipients-action",
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("vs-button", {
                                      attrs: {
                                        color: "primary",
                                        size: "small",
                                        type: "border",
                                        icon: "edit",
                                      },
                                      on: {
                                        click: function ($event) {
                                          recipient.isEdit = true
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c("vs-button", {
                                      attrs: {
                                        color: "danger",
                                        size: "small",
                                        type: "border",
                                        icon: "delete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          recipient.isDeleted = true
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        : [
                            _c(
                              "div",
                              { staticClass: "w-full flex flex-column" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full pricing-invoices__popup__recipients-recipient flex items-center",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "w-full" },
                                      [
                                        _c("vs-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "validateEmail",
                                              expression: "'validateEmail'",
                                            },
                                          ],
                                          staticClass:
                                            "w-full pricing-invoices__popup__recipients-edit mr-2",
                                          attrs: {
                                            name: "invoiceRecipients_" + index,
                                          },
                                          model: {
                                            value: recipient.email,
                                            callback: function ($$v) {
                                              _vm.$set(recipient, "email", $$v)
                                            },
                                            expression: "recipient.email",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "ml-5 mr-2" },
                                      [
                                        _c("vs-button", {
                                          attrs: {
                                            color: "primary",
                                            size: "small",
                                            type: "filled",
                                            disabled: _vm.errors.first(
                                              "invoiceRecipients_" + index
                                            ),
                                            icon: "save",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onSaveRecipientEmail(
                                                recipient
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm.errors.first("invoiceRecipients_" + index)
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "text-danger text-sm" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                "invoiceRecipients_" + index
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                    ],
                    2
                  )
                }
              ),
              _c("div", { staticClass: "mt-5 flex items-center" }, [
                _c(
                  "div",
                  { staticClass: "mr-2" },
                  [
                    _c("vs-button", {
                      attrs: {
                        color: "#2D90F3",
                        size: "small",
                        type: "filled",
                        disabled:
                          _vm.errors.first("invoiceEmail") ||
                          _vm.invoiceRecipient.trim().length === 0,
                        icon: "add",
                      },
                      on: { click: _vm.addInvoiceRecipient },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "w-full flex flex-column" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "validateEmail",
                          expression: "'validateEmail'",
                        },
                      ],
                      staticClass:
                        "w-full mt-2 pricing-invoices__popup__recipients-input",
                      attrs: {
                        "label-placeholder": _vm.$t("vue.enterEmailAddress"),
                        name: "invoiceEmail",
                      },
                      model: {
                        value: _vm.invoiceRecipient,
                        callback: function ($$v) {
                          _vm.invoiceRecipient = $$v
                        },
                        expression: "invoiceRecipient",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("invoiceEmail"))),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "flex mt-2" }, [
                _c(
                  "div",
                  { staticClass: "mr-2" },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "ml-auto",
                        attrs: {
                          disabled:
                            _vm.errors.items.length > 0 ||
                            _vm.invoiceRecipient.length > 0,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.saveInvoiceEmailRecipients()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("vue.save")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "ml-auto",
                        attrs: { color: "danger" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onCloseInvoiceRecipients.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("vue.cancel")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ]
      ),
      _c("div", { staticClass: "pricing__heading" }, [
        _c("h3", { staticClass: "pricing__heading__title" }, [
          _vm._v(_vm._s(_vm.$t("pricing.yourInvoices"))),
        ]),
        _c("p", { staticClass: "pricing__heading__description" }, [
          _vm._v(_vm._s(_vm.$t("pricing.invoicesReadyForDownload"))),
        ]),
      ]),
      _c(
        "vs-table",
        {
          ref: "table",
          staticClass: "pricing-invoices__table",
          attrs: {
            pagination: "",
            "max-items": _vm.itemsPerPage,
            data: _vm.allInvoices,
          },
          on: { "change-page": _vm.handleChangePage },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var data = ref.data
                return _vm._l(data, function (tr, indextr) {
                  return _c(
                    "vs-tr",
                    {
                      key: indextr,
                      staticClass: "pricing-invoices__table__value-row",
                      attrs: { data: tr },
                    },
                    [
                      _c("vs-td", { attrs: { data: tr.company } }, [
                        _vm._v(" " + _vm._s(tr.company) + " "),
                      ]),
                      _c("vs-td", { attrs: { data: tr.date } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.formatDate(tr.date)) +
                            "\n        "
                        ),
                      ]),
                      _c("vs-td", { attrs: { data: tr.subscription } }, [
                        _vm._v(" " + _vm._s(tr.subscription) + " "),
                      ]),
                      _c(
                        "vs-td",
                        {
                          staticClass:
                            "text-center pricing-invoices__table__value pricing-invoices__table__value_effect",
                          attrs: { data: tr.amount },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(tr.amount) +
                              " " +
                              _vm._s(tr.currency) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "vs-td",
                        {
                          staticClass:
                            "text-center pricing-invoices__table__value pricing-invoices__table__value_effect",
                          attrs: { data: tr.status },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pricing-invoices__table__status",
                              class: {
                                "pricing-invoices__table__status--open":
                                  tr.status === "open",
                                "pricing-invoices__table__status--paid":
                                  tr.status === "paid" ||
                                  tr.status === "completed",
                                "pricing-invoices__table__status--unpaid":
                                  tr.status === "unpaid",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(tr.status) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "vs-td",
                        {
                          staticClass: "text-center",
                          attrs: { data: data[indextr].downloadURL },
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                download: "",
                                target: "_blank",
                                href: data[indextr].downloadURL,
                              },
                            },
                            [_c("DownloadIcon")],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                })
              },
            },
          ]),
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex flex-wrap-reverse items-center flex-grow justify-between",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-wrap-reverse items-center" },
                [
                  _vm.company &&
                  (_vm.company.mollieSubscriptionId ||
                    _vm.company.currentInvoiceId ||
                    _vm.company.subscriptionId)
                    ? _c(
                        "vs-button",
                        {
                          staticClass: "mt-4",
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.showInvoiceRecipients = true
                            },
                          },
                        },
                        [
                          !_vm.company.invoiceRecipients
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("vue.addInvoiceRecipients"))
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("vue.changeEmailForYourInvoices")
                                  )
                                ),
                              ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.allInvoices.length > 0
                ? _c(
                    "vs-dropdown",
                    {
                      staticClass: "cursor-pointer mb-4 mr-4",
                      attrs: { "vs-trigger-click": "" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                          staticStyle: { "border-radius": "5px" },
                        },
                        [
                          _c("span", { staticClass: "mr-2" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.currentPage * _vm.itemsPerPage -
                                    (_vm.itemsPerPage - 1)
                                ) +
                                " -\n            " +
                                _vm._s(
                                  _vm.allInvoices.length -
                                    _vm.currentPage * _vm.itemsPerPage >
                                    0
                                    ? _vm.currentPage * _vm.itemsPerPage
                                    : _vm.allInvoices.length
                                ) +
                                " of " +
                                _vm._s(_vm.queriedItems) +
                                "\n          "
                            ),
                          ]),
                          _c("feather-icon", {
                            attrs: {
                              icon: "ChevronDownIcon",
                              svgClasses: "h-4 w-4",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-dropdown-menu",
                        [
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.itemsPerPage = 5
                                },
                              },
                            },
                            [_c("span", [_vm._v("5")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.itemsPerPage = 10
                                },
                              },
                            },
                            [_c("span", [_vm._v("10")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.itemsPerPage = 15
                                },
                              },
                            },
                            [_c("span", [_vm._v("15")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.itemsPerPage = 20
                                },
                              },
                            },
                            [_c("span", [_vm._v("20")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.itemsPerPage = 50
                                },
                              },
                            },
                            [_c("span", [_vm._v("50")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "template",
            { slot: "thead" },
            [
              _c(
                "vs-th",
                {
                  staticClass: "pricing-invoices__table__heading-title",
                  attrs: { "sort-key": "company" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("pricing.company")) + " ")]
              ),
              _c(
                "vs-th",
                {
                  staticClass: "pricing-invoices__table__heading-title",
                  attrs: { "sort-key": "date" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("pricing.month")) + " ")]
              ),
              _c(
                "vs-th",
                {
                  staticClass: "pricing-invoices__table__heading-title",
                  attrs: { "sort-key": "subscription" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("pricing.subscription")) + " ")]
              ),
              _c(
                "vs-th",
                {
                  staticClass:
                    "pricing-invoices__table__heading-title pricing-invoices__table__heading-title_text-center",
                  attrs: { "sort-key": "amount" },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("pricing.amount")) + "\n      "
                  ),
                ]
              ),
              _c(
                "vs-th",
                {
                  staticClass:
                    "pricing-invoices__table__heading-title pricing-invoices__table__heading-title_text-center",
                  attrs: { "sort-key": "status" },
                },
                [_vm._v(" Status ")]
              ),
              _c(
                "vs-th",
                {
                  staticClass:
                    "text-center pricing-invoices__table__heading-title pricing-invoices__table__heading-title_text-center",
                  attrs: { "sort-key": "downloadURL" },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("vue.download")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }