<template>
  <div class="gpt-messages__progress-bar-wrapper" :class="{ 'gpt-messages__progress-bar-wrapper_reached': isReachedMessagesLimit }">
    <div class="gpt-messages__progress-bar__heading">
      <div class="gpt-messages__progress-bar__heading__title">{{ $t('pricing.currentMessagesUsedThisMonth') }}</div>
      <div v-if="isReachedMessagesLimit" class="gpt-messages__progress-bar__heading__button-upgrade">{{ $t('pricing.upgradeToNextPlan') }}</div>
    </div>

    <div class="gpt-messages__progress-bar">
      <div class="gpt-messages__progress-bar__text">
        {{ currentMessagesUsed | formatNumericValue }} of {{ limitMessages | formatNumericValue }} messages used up
      </div>

      <div class="gpt-messages__progress-bar__line-wrapper">
        <div class="gpt-messages__progress-bar__line" :style="`width: ${formatToPercentage(currentMessagesUsed, limitMessages)}%;`"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { gpt_subscription } from '@/mixins/gpt_subscription'
export default {
  name: 'GptConnectMessagesCount',
  mixins: [gpt_subscription],
  data() {
    return {}
  },
  filters: {
    formatNumericValue(value) {
      if (value === null || typeof value === 'undefined') {
        return ''
      }
      return value.toLocaleString(undefined, { minimumFractionDigits: 0 })
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      companyData: 'company'
    }),
    isReachedMessagesLimit() {
      return this.formatToPercentage(this.currentMessagesUsed, this.limitMessages) >= 80
    },
    currentMessagesUsed() {
      return this.companyData && this.companyData.gptMessageCount ? this.companyData.gptMessageCount : 0
    },

    CURRENT_PLAN() {
      const plan = this.gptPlans.find((x) => x.id === this.companyData.gptSubscriptionPlanId)
      return plan
    },

    limitMessages() {
      if (!this.CURRENT_PLAN) return 0

      return this.CURRENT_PLAN.messages
    }
  },
  methods: {
    formatToPercentage(partialValue, totalValue) {
      return (100 * partialValue) / totalValue
    }
  }
}
</script>
<style lang="scss" scoped>
.gpt-messages {
  &__progress-bar {
    margin-top: 10px;
    &-wrapper {
      margin-top: 18px;
      padding: 15px 30px;
      border-radius: 6px;
      background: #f4faff;
      &_reached {
        background: #fff3f6;
        .gpt-messages__progress-bar__line {
          background: #ff004e;
        }
      }
    }
    &__heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__title {
        color: #262629;
        font-size: 20px;
        font-weight: 700;
      }
      &__button-upgrade {
        color: #82ceff;
        font-size: 18px;
        font-weight: 700;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    &__text {
      color: #979797;
      font-size: 12px;
      font-weight: 500;
    }
    &__line {
      background: #275D73;
      max-width: 100%;
      height: 100%;
      border-radius: 6px;
      transition: all 0.5s linear;
      &-wrapper {
        margin-top: 5px;
        background: #ffffff;
        height: 10px;
        border-radius: 6px;
        overflow: hidden;
      }
    }
  }
}
</style>
