<template>
  <div class="pav-sub">
    <Subscription :autoGetStarted="autoGetStarted" :hasQuerySourceVega="hasQuerySourceVega" />
  </div>
</template>
<script>
import Subscription from './Subscription.vue'
export default {
  name: 'VegaSubscription',
  components: {
    Subscription
  },
  props: {
    autoGetStarted: {
      type: Boolean,
      default: false
    },
    hasQuerySourceVega: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.pav-sub {
  height: 100vh;
  width: 100vw;
  margin: 10px;

  background: none;
}
</style>
