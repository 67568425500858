var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "lc-gpt-module",
      class: { "lc-gpt-module--ht-100p": !_vm.HAS_GPT_SUBSCRIPTION },
    },
    [
      _c("div", { staticClass: "lc-gpt-module_info-wrapper" }, [
        _c("div", { staticClass: "lc-gpt-module_info__label" }, [
          _vm._v("Upgrade Module"),
        ]),
        _c("div", { staticClass: "lc-gpt-module_info" }, [
          _c(
            "div",
            { staticClass: "lc-gpt-module_info--icon" },
            [_c("GptLogo")],
            1
          ),
          _c("div", { staticClass: "lc-gpt-module_info_detail" }, [
            _c("div", { staticClass: "lc-gpt-module_info_detail--head" }, [
              _vm._v("\n          GPT Connect\n          "),
              _c(
                "div",
                { staticClass: "lc-gpt-module_info_detail--head__plan" },
                [_vm._v(_vm._s(_vm.CURRENT_PLAN_NAME))]
              ),
            ]),
            _c("div", { staticClass: "lc-gpt-module_info_detail--text" }, [
              _vm._v("active since " + _vm._s(_vm.gptActivationDateText)),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "lc-gpt-module_right-column" }, [
        _c("div", { staticClass: "lc-gpt-module_pricing" }, [
          _c("span", { staticClass: "lc-gpt-module_pricing--euro" }, [
            _vm._v("€"),
          ]),
          _c("span", { staticClass: "lc-gpt-module_pricing--price" }, [
            _vm._v(_vm._s(_vm.CURRENT_PLAN_PRICE)),
          ]),
          _c("span", { staticClass: "lc-gpt-module_pricing--text" }, [
            _vm._v(" /mo"),
          ]),
        ]),
        _vm.CURRENT_PLAN_IS_NOT_ENTERPRISE && !_vm.IS_GPT_SUBSCRIPTION_CANCELLED
          ? _c(
              "div",
              {
                staticClass: "lc-gpt-module_right-column_upgrade",
                on: { click: _vm.onShowGptPlans },
              },
              [
                _c("span", { staticClass: "mr-4" }, [
                  _vm._v(_vm._s(_vm.$t("upgrade-messages.upgrade-plan"))),
                ]),
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/register-funnel/forward-arrow.png"),
                    width: "18px",
                    height: "16.2px",
                    alt: "Next",
                  },
                }),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }