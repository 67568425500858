var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "lc-vega-module",
      class: { "lc-vega-module--ht-100p": !_vm.HAS_SUBSCRIPTION },
    },
    [
      _c("div", { staticClass: "lc-vega-module_info-wrapper" }, [
        _c("div", { staticClass: "lc-vega-module_info__label" }, [
          _vm._v("Upgrade Module"),
        ]),
        _c("div", { staticClass: "lc-vega-module_info" }, [
          _c(
            "div",
            { staticClass: "lc-vega-module_info--icon" },
            [_c("VegaLogo")],
            1
          ),
          _c("div", { staticClass: "lc-vega-module_info_detail" }, [
            _c("div", { staticClass: "lc-vega-module_info_detail--head" }, [
              _vm._v("VEGA"),
            ]),
            _c("div", { staticClass: "lc-vega-module_info_detail--text" }, [
              _vm._v("active since " + _vm._s(_vm.vegaActivationDateText)),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "lc-vega-module_pricing" },
        [
          _c("span", { staticClass: "lc-vega-module_pricing--euro" }, [
            _vm._v("€"),
          ]),
          _c("span", { staticClass: "lc-vega-module_pricing--price" }, [
            _vm._v(_vm._s(_vm.formattedVegaCostsPerVisitor)),
          ]),
          _c("span", { staticClass: "lc-vega-module_pricing--text" }, [
            _vm._v(" /visitor"),
          ]),
          _c("VegaIcon", { staticClass: "lc-vega-module_pricing--icon" }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }