<template>
  <svg :width="width" :height="height" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.4223 0.440632L19.5795 7.94073C19.8613 8.23136 20 8.61574 20 9.00012C20 9.3845 19.8602 9.76794 19.5806 10.0604L12.4234 17.5605C11.8642 18.1465 10.9584 18.1465 10.3992 17.5605C9.84008 16.9746 9.84008 16.0254 10.3992 15.4394L15.1152 10.5001H1.39119C0.599866 10.5001 0 9.82841 0 9.04231C0 8.2562 0.599866 7.5001 1.39119 7.5001L15.1152 7.5001L10.4004 2.55941C9.8412 1.97346 9.8412 1.02423 10.4004 0.438288C10.9595 -0.147657 11.8631 -0.145313 12.4223 0.440632Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'RightArrowIcon',
  props: {
    width: {
      type: Number,
      default: 20,
      required: false
    },
    height: {
      type: Number,
      default: 18,
      required: false
    },
    color: {
      type: String,
      default: '#275D73',
      required: false
    }
  }
}
</script>
