const plansModule = require('@/assets/plans/gpt_plans.js')
const plans = plansModule.plans

export const gpt_subscription = {
  data() {
    return {}
  },
  computed: {
    gptPlans() {
      if (plans && plans.length) {
        return plans.filter((x) => x.enabled)
      }
      return []
    }
  },
  methods: {}
}
