var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gpt-messages__progress-bar-wrapper",
      class: {
        "gpt-messages__progress-bar-wrapper_reached":
          _vm.isReachedMessagesLimit,
      },
    },
    [
      _c("div", { staticClass: "gpt-messages__progress-bar__heading" }, [
        _c(
          "div",
          { staticClass: "gpt-messages__progress-bar__heading__title" },
          [_vm._v(_vm._s(_vm.$t("pricing.currentMessagesUsedThisMonth")))]
        ),
        _vm.isReachedMessagesLimit
          ? _c(
              "div",
              {
                staticClass:
                  "gpt-messages__progress-bar__heading__button-upgrade",
              },
              [_vm._v(_vm._s(_vm.$t("pricing.upgradeToNextPlan")))]
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "gpt-messages__progress-bar" }, [
        _c("div", { staticClass: "gpt-messages__progress-bar__text" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm._f("formatNumericValue")(_vm.currentMessagesUsed)) +
              " of " +
              _vm._s(_vm._f("formatNumericValue")(_vm.limitMessages)) +
              " messages used up\n    "
          ),
        ]),
        _c("div", { staticClass: "gpt-messages__progress-bar__line-wrapper" }, [
          _c("div", {
            staticClass: "gpt-messages__progress-bar__line",
            style:
              "width: " +
              _vm.formatToPercentage(
                _vm.currentMessagesUsed,
                _vm.limitMessages
              ) +
              "%;",
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }