<template>
  <div class="lc-sub-details">
    <div class="lc-sub-details_left-column">
      <PlanInfo v-if="HAS_SUBSCRIPTION || IS_SUBSCRIPTION_CANCELLED" />
      <GptModule v-if="WAS_GPT_ACTIVATED" />
      <VegaModule v-if="WAS_VEGA_ACTIVATED" />
      <PaymentMethod v-if="!WAS_VEGA_ACTIVATED && (HAS_INTERNAL_SUBSCRIPITON || IS_SUBSCRIPTION_CANCELLED)" />
    </div>

    <div v-if="HAS_SUBSCRIPTION || WAS_VEGA_ACTIVATED || IS_SUBSCRIPTION_CANCELLED" class="lc-sub-details_right-column">
      <PaymentDetail />
    </div>

    <div
      v-if="!HAS_SHOWN_PAYMENT_METHOD_BELOW_VEGA_MODULE && ((HAS_INTERNAL_SUBSCRIPITON && WAS_VEGA_ACTIVATED) || IS_SUBSCRIPTION_CANCELLED)"
      class="lc-sub-details_bottom-row"
    >
      <div class="lc-sub-details_item-full-width">
        <PaymentMethod />
      </div>
    </div>
    <CancelSubscription />
  </div>
</template>
<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

import { mapGetters } from 'vuex'
import PlanInfo from './PlanInfo.vue'
import PaymentMethod from './PaymentMethod.vue'
import PaymentDetail from './PaymentDetail.vue'
import GptModule from './GptModule.vue'
import VegaModule from './VegaModule.vue'
import CancelSubscription from './CancelSubscription.vue'

export default {
  name: 'SubscriptionDetail',
  components: {
    PaymentMethod,
    PaymentDetail,
    PlanInfo,
    GptModule,
    VegaModule,
    CancelSubscription
  },
  computed: {
    ...mapGetters({
      company: 'company'
    }),
    vegaActivationDate() {
      return this.company && this.company.vegaActivationDate
    },

    HAS_SHOWN_PAYMENT_METHOD_BELOW_VEGA_MODULE() {
      return !this.WAS_VEGA_ACTIVATED && (this.HAS_INTERNAL_SUBSCRIPITON || this.IS_SUBSCRIPTION_CANCELLED)
    },
    WAS_VEGA_ACTIVATED() {
      if (this.company && this.company.vegaIsActivated && !this.company.vegaIsOnTrial) {
        return true
      }

      return false
    },

    IS_SUBSCRIPTION_CANCELLED() {
      const isSubscriptionCancelled = this.company && this.company.paymentStatus === 'subscription-cancelled'
      const expiryDate = dayjs.unix(this.company.expiryDate.seconds).utc()
      const currentDate = dayjs().utc()
      const hasExpired = this.company && this.company.paymentStatus === 'deleted' && expiryDate.isBefore(currentDate)
      return isSubscriptionCancelled || !hasExpired
    },

    HAS_SUBSCRIPTION() {
      return (
        this.HAS_MOLLIE_SUBSCRIPTION ||
        this.HAS_INVOICE_SUBSCRIPTION ||
        this.HAS_PADDLE_SUBSCRIPTION ||
        this.HAS_APPSUMO_SUBSCRIPTION ||
        this.HAS_SHOPIFY_SUBSCRIPTION
      )
    },

    HAS_INTERNAL_SUBSCRIPITON() {
      return this.HAS_MOLLIE_SUBSCRIPTION || this.HAS_INVOICE_SUBSCRIPTION || this.HAS_PADDLE_SUBSCRIPTION
    },

    HAS_FREE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.subscriptionPlanId && this.company.subscriptionPlanId.indexOf('FREE') > -1 && this.company.paymentStatus === 'subscription')
    },

    HAS_SHOPIFY_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company && this.company.isShopifyAccount)
    },

    HAS_APPSUMO_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company && this.company.isAppSumoAccount)
    },

    HAS_INVOICE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.currentInvoiceId)
    },

    IS_GPT_ACTIVATED() {
      if (!this.company) return false
      return this.company.gptIsActivated
    },

    WAS_GPT_ACTIVATED() {
      return this.HAS_GPT_SUBSCRIPTION && this.IS_GPT_ACTIVATED
    },

    HAS_GPT_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.gptSubscriptionPlanId)
    },

    HAS_MOLLIE_MANDATE() {
      if (!this.company) return false
      return this.company.mollieMandateId
    },

    HAS_GPT_MOLLIE_SUBSCRIPTION() {
      return (
        this.company &&
        Boolean(this.company.gptSubscriptionPlanId) &&
        Boolean(this.company.mollieGptSubscriptionId) &&
        Boolean(this.company.mollieCustomerId) &&
        this.company.gptPaymentStatus !== 'subscription-cancelled' &&
        this.company.gptExpiryDate &&
        dayjs().isBefore(dayjs.unix(this.company.gptExpiryDate.seconds))
      )
    },

    HAS_MOLLIE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.mollieCustomerId && this.company.mollieSubscriptionId)
    },

    HAS_PADDLE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.subscriptionId && !this.company.mollieSubscriptionId && this.company.paymentStatus === 'subscription')
    }
  }
}
</script>

<style lang="scss">
.mtop-30 {
  margin-top: 30px;
}
.fw-700 {
  font-weight: 700;
}

.lc-sub-details {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  font-family: Lato;
  font-style: normal;

  &--label {
    color: #7e8186;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.111px;
  }

  &--label2 {
    color: #262629;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.111px;
  }

  &_left-column {
    flex: 1;
    flex-basis: 64%;
    display: flex;
    flex-direction: column;
  }

  &_right-column {
    flex-basis: 33.33%;
    display: flex;
    flex-direction: column;
  }

  &_bottom-row {
    flex-basis: 100%;
    display: flex;
  }

  &_item {
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 10px 10px;
  }

  &_item-full-width {
    flex-basis: 100%;
  }
}

@media (max-width: 768px) {
  .lc-sub-details {
    flex-wrap: wrap;

    &_left-column,
    &_right-column,
    &_bottom-row {
      flex-basis: 100%;
    }

    &_item {
      flex-basis: 100%;
    }
  }

  .lc-sub-plan {
    flex-direction: column;

    &-left-column,
    &-right-column {
      flex-basis: 100%;
    }
  }
}
</style>
