var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gpt-subscription" },
    [
      !_vm.HAS_ACCESS ? _c("gpt-connect-message") : _vm._e(),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.upgradeTitle,
            active: _vm.showUpgradeConfirmation,
          },
          on: {
            "update:active": function ($event) {
              _vm.showUpgradeConfirmation = $event
            },
          },
        },
        [
          _vm.selectedPlan && _vm.selectedPlan.level > this.CURRENT_PLAN_LEVEL
            ? _c("p", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.$t("vue.confirmQuestion"))),
              ])
            : _vm._e(),
          _c(
            "vs-button",
            {
              staticClass: "mr-2",
              attrs: { color: "#3034F7", type: "filled" },
              on: { click: _vm.onConfirmUpgrade },
            },
            [_vm._v(_vm._s(_vm.$t("vue.confirm")))]
          ),
          _c(
            "vs-button",
            {
              attrs: { type: "border", color: "#3034F7" },
              on: { click: _vm.onCloseUpgrade },
            },
            [_vm._v(_vm._s(_vm.$t("vue.cancelChangePlan")))]
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.downgradeTitle,
            active: _vm.showDowngradeConfirmation,
          },
          on: {
            "update:active": function ($event) {
              _vm.showDowngradeConfirmation = $event
            },
          },
        },
        [
          _c("p", { staticClass: "mb-2" }, [
            _vm._v(_vm._s(_vm.DOWNGRADED_FROM)),
          ]),
          _c(
            "vs-button",
            {
              staticClass: "mr-2",
              attrs: { color: "#e8546f", type: "filled" },
              on: { click: _vm.onConfirmDowngrade },
            },
            [_vm._v(_vm._s(_vm.$t("vue.confirm")))]
          ),
          _c(
            "vs-button",
            {
              attrs: { type: "border", color: "#e8546f" },
              on: { click: _vm.onCloseDowngrade },
            },
            [_vm._v(_vm._s(_vm.$t("vue.cancelChangePlan")))]
          ),
        ],
        1
      ),
      _vm.isProcessing
        ? _c("div", { staticClass: "gpt-subscription__loading" }, [
            _c("div", { staticClass: "gpt-subscription__loading__loader" }, [
              _c("span", {
                staticClass: "gpt-subscription__loading__loader__spinner",
              }),
              _c(
                "div",
                { staticClass: "gpt-subscription__loading__loader__text" },
                [_vm._v(_vm._s(_vm.$t("appsumo.processing")))]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.isGptActivationSuccessful
        ? _c("div", { staticClass: "gpt-subscription__loading" }, [
            _c("div", { staticClass: "gpt-subscription__loading__loader" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/register-funnel/check.png"),
                  alt: "Success",
                },
              }),
              _c(
                "div",
                { staticClass: "gpt-subscription__loading__loader__title" },
                [_vm._v(_vm._s(_vm.$t("gpt.subscription.thankYou")))]
              ),
              _c(
                "div",
                { staticClass: "gpt-subscription__loading__loader__text" },
                [_vm._v(_vm._s(_vm.$t("gpt.subscription.thankYouMessage")))]
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "vx-card",
        { staticClass: "p-0" },
        [
          _c(
            "vs-row",
            { staticClass: "mt-5" },
            [
              _c("vs-col", { attrs: { "vs-type": "flex" } }, [
                _c(
                  "div",
                  { staticClass: "price-list" },
                  [
                    _c("div", { staticClass: "price-list__heading" }, [
                      _c("h3", { staticClass: "price-list__heading__title" }, [
                        _vm._v(
                          _vm._s(_vm.$t("pricing.chooseTheRightPlanForYou"))
                        ),
                      ]),
                    ]),
                    [
                      _c(
                        "div",
                        { staticClass: "price-list__plans" },
                        [
                          _c(
                            "div",
                            { staticClass: "price-list__plans__heading" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "price-list__plans__heading__text",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("gpt.billingHeadingText")) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "price-list__plans-list" },
                            _vm._l(_vm.filteredGptPlans, function (plan) {
                              return _c(
                                "div",
                                {
                                  key: plan.id,
                                  staticClass: "price-list__plans-list__item",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "price-list__plans-list__item__heading",
                                      class: {
                                        "price-list__plans-list__item__heading--active":
                                          _vm.isPlanHighlighted(plan),
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "price-list__plans-list__item__heading__title",
                                        },
                                        [_vm._v(_vm._s(plan.title))]
                                      ),
                                      plan.isNewPlan
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "price-list__plans-list__item__heading__label-summary",
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(_vm.ACTIVE_FROM) +
                                                  "\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "price-list__plans-list__item__body",
                                    },
                                    [
                                      plan.mostPopular
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "price-list__plans-list__item__content__label price-list__plans-list__item__content__label--desktop",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.$t("vue.mostPopular")
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "price-list__plans-list__item__content__label price-list__plans-list__item__content__label--mobile",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.$t("vue.mostPopular")
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "price-list__plans-list__item__content__price",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "price-list__plans-list__item__content__price__currency",
                                            },
                                            [_vm._v("€")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "price-list__plans-list__item__content__price__amount",
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(plan.price) +
                                                  "\n                        "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "price-list__plans-list__item__content__price__amount__period",
                                                },
                                                [_vm._v("/mo")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "price-list__plans-list__item__heading__label-action",
                                          class: _vm.SET_PLAN_CLASS(plan),
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "price-list__plans-list__item__heading__label-action__text",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onChoosePlan(plan)
                                                },
                                              },
                                            },
                                            [
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.planChangeText(plan)
                                                    )
                                                  )
                                                ),
                                              ],
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                      plan.isNewPlan && _vm.DOWNGRADED_FROM_TEXT
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "price-list__plans-list__item__downgrade",
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.DOWNGRADED_FROM_TEXT
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "price-list__plans-list__item__body__options-list",
                                        },
                                        [
                                          _vm._l(
                                            plan.options,
                                            function (option) {
                                              return _c(
                                                "li",
                                                {
                                                  key: option.id,
                                                  staticClass:
                                                    "price-list__plans-list__item__body__options-list__item",
                                                  class: {
                                                    "price-list__plans-list__item__body__options-list__item--boldify":
                                                      option.boldify,
                                                  },
                                                },
                                                [
                                                  _c("ThinCheckIcon", {
                                                    staticClass:
                                                      "price-list__plans-list__item__body__options-list__item__icon",
                                                  }),
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          option.description
                                                        )
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          plan.isEnterprisePlan
                                            ? _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "price-list__plans-list__item__body__options-list__item",
                                                },
                                                [
                                                  _c("AmpersandIcon", {
                                                    staticClass:
                                                      "price-list__plans-list__item__body__options-list__item__icon",
                                                  }),
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.$t("pricing.more")
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          _vm.HAS_GPT_MOLLIE_SUBSCRIPTION ||
                          _vm.HAS_GPT_INVOICE_SUBSCRIPTION
                            ? _c("MessagesCount")
                            : _c("div", { staticClass: "price-list__info" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "price-list__info--more-info",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("gpt.needMoreInfo")))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "price-list__info--see-more",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onGoToSeeMore()
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("gpt.seeMore")))]
                                ),
                              ]),
                        ],
                        1
                      ),
                    ],
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }