var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.CAN_CANCEL_SUBSCRIPTION
    ? _c(
        "div",
        {
          staticClass: "lc-cancel-subscription",
          on: { click: _vm.onCancelSubscription },
        },
        [_vm._v("\n  " + _vm._s(_vm.$t("vue.cancelSubscription")) + "\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }