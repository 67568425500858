<template>
  <div id="data-list-thumb-view" class="data-list-container pricing-subscription pricing-invoices">
    <connect-modal :show="showInvoiceRecipients" :width="'750px'" bkcolor="#F7F7F7">
      <div class="flex-flex-column pricing-invoices__popup">
        <div class="pricing-invoices__popup__heading">{{ $t('vue.invoiceRecipients') }}</div>
        <div class="pricing-invoices__popup__recipients" v-for="(recipient, index) in invoiceRecipients.filter((x) => !x.isDeleted)" :key="index">
          <template v-if="!recipient.isEdit">
            <div class="pricing-invoices__popup__recipients-recipient">
              {{ recipient.email }}
            </div>
            <div class="pricing-invoices__popup__recipients-action">
              <div>
                <vs-button color="primary" size="small" type="border" icon="edit" @click="recipient.isEdit = true"></vs-button>
              </div>
              <div>
                <vs-button color="danger" size="small" type="border" icon="delete" @click="recipient.isDeleted = true"></vs-button>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="w-full flex flex-column">
              <div class="w-full pricing-invoices__popup__recipients-recipient flex items-center">
                <div class="w-full">
                  <vs-input
                    class="w-full pricing-invoices__popup__recipients-edit mr-2"
                    :name="`invoiceRecipients_${index}`"
                    v-model="recipient.email"
                    v-validate="'validateEmail'"
                  ></vs-input>
                </div>
                <div class="ml-5 mr-2">
                  <vs-button
                    color="primary"
                    size="small"
                    type="filled"
                    :disabled="errors.first(`invoiceRecipients_${index}`)"
                    icon="save"
                    @click="onSaveRecipientEmail(recipient)"
                  ></vs-button>
                </div>
              </div>
              <div v-if="errors.first(`invoiceRecipients_${index}`)">
                <span class="text-danger text-sm">{{ errors.first(`invoiceRecipients_${index}`) }}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="mt-5 flex items-center">
          <div class="mr-2">
            <vs-button
              color="#2D90F3"
              size="small"
              type="filled"
              :disabled="errors.first('invoiceEmail') || invoiceRecipient.trim().length === 0"
              icon="add"
              @click="addInvoiceRecipient"
            ></vs-button>
          </div>
          <div class="w-full flex flex-column">
            <vs-input
              class="w-full mt-2 pricing-invoices__popup__recipients-input"
              :label-placeholder="$t('vue.enterEmailAddress')"
              name="invoiceEmail"
              v-model="invoiceRecipient"
              v-validate="'validateEmail'"
            ></vs-input>
            <span class="text-danger text-sm">{{ errors.first('invoiceEmail') }}</span>
          </div>
        </div>
        <div class="flex mt-2">
          <div class="mr-2">
            <vs-button class="ml-auto" :disabled="errors.items.length > 0 || invoiceRecipient.length > 0" @click.prevent="saveInvoiceEmailRecipients()">
              {{ $t('vue.save') }}
            </vs-button>
          </div>
          <div>
            <vs-button color="danger" class="ml-auto" @click.prevent="onCloseInvoiceRecipients">
              {{ $t('vue.cancel') }}
            </vs-button>
          </div>
        </div>
      </div>
    </connect-modal>

    <div class="pricing__heading">
      <h3 class="pricing__heading__title">{{ $t('pricing.yourInvoices') }}</h3>
      <p class="pricing__heading__description">{{ $t('pricing.invoicesReadyForDownload') }}</p>
    </div>

    <vs-table
      pagination
      ref="table"
      class="pricing-invoices__table"
      :max-items="itemsPerPage"
      :data="allInvoices"
      v-model="selected"
      @change-page="handleChangePage"
    >
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center">
          <vs-button
            v-if="company && (company.mollieSubscriptionId || company.currentInvoiceId || company.subscriptionId)"
            color="primary"
            class="mt-4"
            @click.prevent="showInvoiceRecipients = true"
          >
            <span v-if="!company.invoiceRecipients">{{ $t('vue.addInvoiceRecipients') }}</span>
            <span v-else>{{ $t('vue.changeEmailForYourInvoices') }}</span>
          </vs-button>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown v-if="allInvoices.length > 0" vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            style="border-radius: 5px"
          >
            <span class="mr-2">
              {{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{ allInvoices.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : allInvoices.length }} of {{ queriedItems }}
            </span>

            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>

          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 5">
              <span>5</span>
            </vs-dropdown-item>

            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>

            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>

            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>

            <vs-dropdown-item @click="itemsPerPage = 50">
              <span>50</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th class="pricing-invoices__table__heading-title" sort-key="company"> {{ $t('pricing.company') }} </vs-th>

        <vs-th class="pricing-invoices__table__heading-title" sort-key="date"> {{ $t('pricing.month') }} </vs-th>

        <vs-th class="pricing-invoices__table__heading-title" sort-key="subscription"> {{ $t('pricing.subscription') }} </vs-th>

        <vs-th class="pricing-invoices__table__heading-title pricing-invoices__table__heading-title_text-center" sort-key="amount">
          {{ $t('pricing.amount') }}
        </vs-th>

        <vs-th class="pricing-invoices__table__heading-title pricing-invoices__table__heading-title_text-center" sort-key="status"> Status </vs-th>

        <vs-th class="text-center pricing-invoices__table__heading-title pricing-invoices__table__heading-title_text-center" sort-key="downloadURL">
          {{ $t('vue.download') }}
        </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr v-for="(tr, indextr) in data" class="pricing-invoices__table__value-row" :data="tr" :key="indextr">
          <vs-td :data="tr.company"> {{ tr.company }} </vs-td>

          <vs-td :data="tr.date">
            {{ formatDate(tr.date) }}
          </vs-td>

          <vs-td :data="tr.subscription"> {{ tr.subscription }} </vs-td>

          <vs-td class="text-center pricing-invoices__table__value pricing-invoices__table__value_effect" :data="tr.amount">
            {{ tr.amount }} {{ tr.currency }}
          </vs-td>

          <vs-td class="text-center pricing-invoices__table__value pricing-invoices__table__value_effect" :data="tr.status">
            <div
              class="pricing-invoices__table__status"
              :class="{
                'pricing-invoices__table__status--open': tr.status === 'open',
                'pricing-invoices__table__status--paid': tr.status === 'paid' || tr.status === 'completed',
                'pricing-invoices__table__status--unpaid': tr.status === 'unpaid'
              }"
            >
              {{ tr.status }}
            </div>
          </vs-td>

          <vs-td class="text-center" :data="data[indextr].downloadURL">
            <a download target="_blank" :href="data[indextr].downloadURL">
              <DownloadIcon />
            </a>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
// VeeValidate
import { Validator } from 'vee-validate'
// Merge all defined languages in the dictionary and sets the current locale.
import validationDictionary from '../../../validations/validationDictionary'
import ConnectModal from '@/components/ConnectModal.vue'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

let plans = null
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'pathadvice-app') {
  const plansModule = require('@/assets/plans/prod_plans.js')
  plans = plansModule.plans
} else {
  const plansModule = require('@/assets/plans/stage_plans.js')
  plans = plansModule.plans
}
const gptPlansModule = require('@/assets/plans/gpt_plans.js')
const gptPlans = gptPlansModule.plans

import DownloadIcon from '../../../components/icons/DownloadIcon'

export default {
  components: {
    DownloadIcon,
    ConnectModal
  },
  data: () => ({
    selected: [],
    itemsPerPage: 5,
    currentPage: 0,
    invoiceRecipients: [],
    invoiceRecipient: '',
    showInvoiceRecipients: false
  }),

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      invoices: 'invoices',
      pathadviceInvoices: 'invoice/pathadviceInvoices',
      refresh: 'refresh'
    }),
    pathadviceInvoicesFilteredByCompanyId() {
      if (this.pathadviceInvoices && this.pathadviceInvoices.length) {
        return this.pathadviceInvoices.filter((el) => el.companyId === this.activeUserInfo.company)
      }
      return []
    },
    allPlans() {
      let mergedPlans = []

      if (plans && plans.length) {
        mergedPlans = [...mergedPlans, ...plans]
      }

      if (gptPlans && gptPlans.length) {
        mergedPlans = [...mergedPlans, ...gptPlans]
      }
      return mergedPlans
    },
    normalizedPathadviceInvoices() {
      if (this.pathadviceInvoicesFilteredByCompanyId && this.pathadviceInvoicesFilteredByCompanyId.length && this.allPlans && this.allPlans.length) {
        return this.pathadviceInvoicesFilteredByCompanyId.map((el) => {
          const selectedPlan = this.allPlans.find((item) => item.id === el.selectedPlanId)

          let currency = null

          if (el.method === 'invoice') {
            currency = el.currency && el.currency.text ? el.currency.text : null
          } else {
            //currency = el.metadata && el.metadata.currency && el.metadata.currency.symbol ? el.metadata.currency.symbol : null
            currency = el.amount && el.amount.currency ? el.amount.currency : null
          }

          if (!currency && Object.keys(el.amount)) {
            currency = Object.keys(el.amount)[0]
          }

          let normalizedEl = null
          if (el.method === 'invoice') {
            normalizedEl = {
              id: el.id,
              company: el.company,
              status: el.status,
              amount: el.amount && el.amount[currency],
              currency,
              date: dayjs.unix(el.createdAt.seconds).locale(this.$i18n.locale).format('YYYY-MM-DD'),
              downloadURL: el.previewUrl && el.previewUrl.length > 0 ? el.previewUrl : `${this.$functionsURL}/generateInvoice?id=${el.id}`,
              subscription: (selectedPlan && selectedPlan.description) || el.description
            }
          } else {
            let queryString = `id=${el.id}`
            if (el && el.companyInfo && el.companyInfo.mollieCustomerId && el.companyInfo.subscriptionPlanId) {
              queryString = `paymentId=${el.id}&companyId=${el.companyId}`
            }

            normalizedEl = {
              id: el.id,
              company: el.company,
              status: el.status,
              amount: el.amount && el.amount.value,
              currency,
              date: dayjs.unix(el.createdAt.seconds).locale(this.$i18n.locale).format('YYYY-MM-DD'),
              downloadURL: el.previewUrl && el.previewUrl.length > 0 ? el.previewUrl : `${this.$functionsURL}/generateInvoice?${queryString}`,
              subscription: (selectedPlan && selectedPlan.description) || el.description
            }
          }

          return normalizedEl
        })
      }
      return []
    },
    allInvoices() {
      let mergedInvocies = []
      if (this.invoices) {
        mergedInvocies = mergedInvocies.concat(this.invoices)
      }
      if (this.normalizedPathadviceInvoices) {
        mergedInvocies = mergedInvocies.concat(this.normalizedPathadviceInvoices)
      }
      return mergedInvocies
        .filter((x) => parseFloat(x.amount) > 0)
        .sort((a, b) => {
          const dateA = new Date(a.date)
          const dateB = new Date(b.date)
          return dateB - dateA
        })
    },

    locale() {
      return this.activeUserInfo.language || this.$i18n.locale
    },

    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.allInvoices.length
    }
  },

  async mounted() {
    /* Localize validator */
    Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])
    if (this.company.invoiceRecipients) {
      this.invoiceRecipients = this.company.invoiceRecipients.split(',').map((x) => {
        return {
          email: x,
          isEdit: false,
          isDeleted: false
        }
      })
    }
    if (
      (!this.invoices || this.invoices.length === 0 || this.refresh.invoices) &&
      this.company &&
      this.company.paymentStatus !== 'trial' &&
      this.company.paymentStatus !== 'on-trial' &&
      !this.company.mollieSubscriptionId &&
      !this.company.currentInvoiceId
    ) {
      await this.$vs.loading()
      await this.getInvoices()
      await this.$vs.loading.close()
    }

    if (this.allInvoices.length > 0) {
      this.currentPage = 1
    }

    if (this.$route && this.$route.query && this.$route.query.showInvoice) {
      window.open(`${this.$functionsURL}/generateInvoice?id=${this.$route.query.showInvoice}`, '_blank')
    }
  },

  methods: {
    ...mapMutations({
      updateInvoices: 'UPDATE_INVOICES',
      updateRefreshState: 'UPDATE_REFRESH_STATE'
    }),
    onSaveRecipientEmail(recipient) {
      recipient.isEdit = false
    },
    addInvoiceRecipient() {
      this.invoiceRecipients.push({ email: this.invoiceRecipient, isEdit: false, isDeleted: false })
      this.invoiceRecipient = ''
    },
    onCloseInvoiceRecipients() {
      this.invoiceRecipients = this.company.invoiceRecipients.split(',').map((x) => {
        return {
          email: x,
          isEdit: false,
          isDeleted: false
        }
      })
      this.showInvoiceRecipients = false
    },
    async saveInvoiceEmailRecipients() {
      const result = await this.$validator.validateAll()
      if (result) {
        let recipients = this.invoiceRecipients
          .filter((x) => !x.isDeleted)
          .map((x) => x.email)
          .join(',')
        if (!recipients || recipients.length === 0) {
          recipients = this.$firebase.firestore.FieldValue.delete()
        }

        await this.$vs.loading()
        await this.$db.collection('company').doc(this.activeUserInfo.company).set({ invoiceRecipients: recipients }, { merge: true })
        this.showInvoiceRecipients = false
        await this.$vs.loading.close()
      }
    },
    handleChangePage(page) {
      this.currentPage = page
    },
    onDisplayError(message) {
      this.$vs.notify({
        title: this.$i18n.t('vue.error'),
        text: this.$i18n.t(message),
        color: 'danger',
        time: 10000
      })
    },
    formatDate(date) {
      return dayjs(date).locale(this.locale).format('MMMM YYYY')
    },
    async getMolliePayments() {
      const mollieListPayments = this.$functions.httpsCallable('mollieListPayments')
      const { data } = await mollieListPayments({ mollieCustomerId: this.company.mollieCustomerId })

      if (data && data.error) {
        this.onDisplayError(data.error.message)
        return []
      }
      const _payments = data.map((payment) => {
        return {
          company: this.company.name,
          date: payment.createdAt,
          subscription: payment.description,
          amount: parseFloat(payment.amount.value),
          method: payment.method,
          status: payment.status,
          subscriptionId: payment.subscriptionId,
          customerId: payment.customerId,
          downloadURL: `${this.$functionsURL}/generateInvoice?paymentId=${payment.id}&companyId=${this.activeUserInfo.company}&fn=${this.activeUserInfo.firstname}&ln=${this.activeUserInfo.lastname}`
        }
      })
      return _payments
    },

    async getInvoices() {
      let invoices = []
      const getAccountInvoices = this.$functions.httpsCallable('getInvoices')
      const { data } = await getAccountInvoices(this.activeUserInfo.company)
      if (data) {
        const _invoices = data.map((invoice) => {
          return {
            company: this.company.name,
            date: invoice.created_at,
            subscription: invoice.name,
            status: invoice.status,
            amount: invoice.amount,
            downloadURL: invoice.receipt_url
          }
        })
        invoices = invoices.concat(_invoices)
      }

      this.updateInvoices(invoices)
      /* Update the refresh state so that the method is not called on mounted()  */
      const refreshState = { ...this.refresh }
      refreshState.invoices = false
      this.updateRefreshState(refreshState)
    }
  }
}
</script>

<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: capitalize;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

.pricing-invoices {
  &__popup {
    padding: 35px 30px;

    &__heading {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #8e8e8e;
    }

    &__recipients {
      background: #e6ecf1;
      opacity: 0.5;
      border-radius: 6px;
      margin-top: 10px;
      min-height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-recipient {
        margin-left: 20px;
      }

      &-edit {
        margin-top: 0px !important;
      }

      &-action {
        margin-right: 20px;
        display: flex;
        flex-direction: row;

        > div {
          flex: 1 1 auto;
          text-align: center;
          margin: 5px;
        }
      }
    }
  }

  &__table {
    margin-top: 50px;

    &__status {
      padding: 2.5px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 50px;

      &.pricing-invoices__table__status--open {
        border: 1px solid orange;
        background: orange;
        color: white;
        transition: 0.3s ease-in-out;
      }

      &.pricing-invoices__table__status--paid {
        border: 1px solid green;
        background: green;
        color: white;
      }

      &.pricing-invoices__table__status--unpaid {
        border: 1px solid red;
        background: red;
        color: white;
        transition: 0.3s ease-in-out;
      }
    }

    &__heading-title {
      .vs-table-text {
        font-size: 15px;
        font-weight: 500 !important;
      }

      &_text {
        &-center {
          .vs-table-text {
            justify-content: center;
          }
        }
      }
    }

    &__value {
      &-row {
        font-size: 15px;
      }

      &_effect {
        color: rgb(59, 134, 247);
        font-weight: 700;
      }
    }
  }
}
</style>
