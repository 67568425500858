<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <circle cx="9.5" cy="9.5" r="9.5" fill="#2BFF6F" />
    <path d="M13.6 7L8.375 12.7L6 10.1091" stroke="white" stroke-width="1.9" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
<script>
export default {
  name: 'CheckIcon'
}
</script>
