<template>
  <div v-if="lastPayment" class="lc-payment-method">
    <div class="lc-payment-method--label fw-700">{{ $t('pricing.paymentMethod') }}</div>
    <div class="lc-payment-method--detail">
      <div class="lc-payment-method--detail--card">
        <div v-if="paymentMethod" class="lc-payment-method--detail--card--icon">
          <template v-if="paymentMethod === 'creditcard'">
            <CreditCardIcon />
            <CheckIcon class="lc-payment-method--detail--card--check" />
          </template>
          <template v-if="paymentMethod === 'paypal'">
            <PaypalIcon />
            <CheckIcon class="lc-payment-method--detail--card--check" />
          </template>
          <template v-if="paymentMethod === 'invoice'">
            <InvoiceIcon />
            <CheckIcon class="lc-payment-method--detail--card--check" />
          </template>
        </div>

        <div v-if="paymentMethodText" class="lc-payment-method--detail--card-detail">
          <div class="lc-payment-method--card-text">{{ paymentMethodText }}</div>
        </div>
      </div>
      <div v-if="SHOW_PAYMENT_METHOD" class="lc-payment-method--detail--update fw-700" @click="updatePaymentMethod">
        {{ $t('vue.updatePaymentMethod') }}
      </div>
    </div>
  </div>
</template>
<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

import CheckIcon from './CheckIcon.vue'
import CreditCardIcon from './CreditCardIcon.vue'
import PaypalIcon from './PaypalIcon.vue'
import InvoiceIcon from './InvoiceIcon.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'PaymentMethod',
  components: {
    CreditCardIcon,
    PaypalIcon,
    InvoiceIcon,
    CheckIcon
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      allUsers: 'users',
      company: 'company',
      subscription: 'subscription',
      pathadviceInvoices: 'invoice/pathadviceInvoices'
    }),

    CAN_UPDATE_PAYMENT_METHOD() {
      return (
        this.subscription &&
        this.company &&
        this.company.mollieCustomerId &&
        this.company.mollieSubscriptionId &&
        this.company.paymentStatus !== 'subscription-cancelled'
      )
    },

    CAN_UPDATE_PAYMENT_METHOD_FOR_INVOICE() {
      return this.company && this.company.currentInvoiceId && this.company.paymentStatus !== 'subscription-cancelled'
    },

    WAS_VEGA_ACTIVATED() {
      if (this.company && this.company.vegaIsActivated && !this.company.vegaIsOnTrial) {
        return true
      }

      return false
    },

    WAS_GPT_ACTIVATED() {
      if (this.company && this.company.gptIsActivated) {
        return true
      }

      return false
    },

    SHOW_PAYMENT_METHOD() {
      if (this.IS_SUBSCRIPTION_CANCELED) {
        return false
      }

      if (this.HAS_GPT_SUBSCRIPTION) {
        return true
      }

      if (this.HAS_INVOICE_SUBSCRIPTION && this.CAN_UPDATE_PAYMENT_METHOD_FOR_INVOICE) {
        return true
      }

      if (this.HAS_MOLLIE_SUBSCRIPTION && this.CAN_UPDATE_PAYMENT_METHOD && !this.IS_SUBSCRIPTION_CANCELED) {
        return true
      }

      if (this.HAS_PADDLE_SUBSCRIPTION && this.CAN_UPDATE_PAYMENT_METHOD) {
        return true
      }

      if (!this.HAS_MOLLIE_SUBSCRIPTION && this.WAS_VEGA_ACTIVATED) {
        return true
      }

      return false
    },

    HAS_GPT_SUBSCRIPTION() {
      return (this.HAS_GPT_MOLLIE_SUBSCRIPTION && this.HAS_MOLLIE_MANDATE) || this.HAS_GPT_INVOICE_SUBSCRIPTION
    },

    HAS_GPT_INVOICE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.gptCurrentInvoiceId)
    },

    HAS_MOLLIE_MANDATE() {
      if (!this.company) return false
      return this.company.mollieMandateId
    },

    HAS_GPT_MOLLIE_SUBSCRIPTION() {
      return (
        this.company &&
        Boolean(this.company.gptSubscriptionPlanId) &&
        Boolean(this.company.mollieGptSubscriptionId) &&
        Boolean(this.company.mollieCustomerId) &&
        this.company.gptPaymentStatus !== 'subscription-cancelled' &&
        this.company.gptExpiryDate &&
        dayjs().isBefore(dayjs.unix(this.company.gptExpiryDate.seconds))
      )
    },

    HAS_SUBSCRIPTION() {
      return (
        this.HAS_MOLLIE_SUBSCRIPTION ||
        this.HAS_INVOICE_SUBSCRIPTION ||
        this.HAS_PADDLE_SUBSCRIPTION ||
        this.HAS_APPSUMO_SUBSCRIPTION ||
        this.HAS_SHOPIFY_SUBSCRIPTION ||
        this.HAS_FREE_SUBSCRIPTION
      )
    },

    HAS_FREE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.subscriptionPlanId && this.company.subscriptionPlanId.indexOf('FREE') > -1 && this.company.paymentStatus === 'subscription')
    },

    HAS_SHOPIFY_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company && this.company.isShopifyAccount)
    },

    HAS_APPSUMO_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company && this.company.isAppSumoAccount)
    },

    HAS_INVOICE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.currentInvoiceId)
    },

    HAS_MOLLIE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.mollieCustomerId && this.company.mollieSubscriptionId)
    },

    HAS_PADDLE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.subscriptionId && !this.company.mollieSubscriptionId && this.company.paymentStatus === 'subscription')
    },

    IS_SUBSCRIPTION_CANCELED() {
      if (this.company && this.company.mollieSubscriptionId && this.subscription && this.subscription.status === 'canceled') {
        return true
      }
      if (this.company && this.company.subscriptionId && this.subscription && this.subscription.state === 'deleted') {
        return true
      }
      return false
    },
    lastPayment() {
      if (this.company && this.company.currentInvoiceId) {
        return this.pathadviceInvoices.find((x) => x.id === this.company.currentInvoiceId)
      }

      if (this.company && this.company.mollieSubscriptionId) {
        try {
          const invoices = this.pathadviceInvoices.filter((x) => x.companyId === this.activeUserInfo.company)

          const invoice = invoices.reduce((prev, current) => {
            return prev.createdAt.seconds > current.createdAt.seconds ? prev : current
          })
          return invoice
        } catch (error) {
          /* eslint-disable no-console */
          console.log(error.message)
          return null
        }
      }

      if (this.company && this.company.subscriptionId && this.subscription && this.subscription.last_payment) {
        return this.subscription
      }

      if (this.company && this.company.subscriptionId && this.subscription && this.subscription.last_payment) {
        return this.subscription
      }

      if (this.company && this.company.mollieMandateId) {
        try {
          const invoices = this.pathadviceInvoices.filter((x) => {
            return x.companyId === this.activeUserInfo.company && x.mandateId === this.company.mollieMandateId
          })

          const invoice = invoices.reduce((prev, current) => {
            return prev.createdAt.seconds > current.createdAt.seconds ? prev : current
          })

          return invoice
        } catch (error) {
          console.log(error.message)
          return null
        }
      }

      return null
    },

    paymentMethod() {
      if (this.company && this.company.paymentMethod) {
        return this.company.paymentMethod
      }

      if (this.company && this.company.mollieSubscriptionId && this.lastPayment && this.lastPayment.method) {
        return this.lastPayment.method
      }

      if (this.company && this.company.subscriptionId && this.subscription && this.subscription.payment_information) {
        return this.subscription.payment_information.payment_method === 'card' ? 'creditcard' : this.subscription.payment_information.payment_method
      }

      if (this.company && this.company.currentInvoiceId) {
        return 'invoice'
      }

      if (this.lastPayment && this.lastPayment.mandateId) {
        return this.lastPayment.method
      }

      return null
    },

    paymentMethodText() {
      if (this.paymentMethod === 'creditcard') {
        return 'Credit Card'
      }
      if (this.paymentMethod === 'invoice') {
        return 'Invoice'
      }
      if (this.paymentMethod === 'paypal') {
        return 'PayPal'
      }
      return null
    }
  },
  methods: {
    updatePaymentMethod() {
      if (this.HAS_INVOICE_SUBSCRIPTION) {
        this.$serverBus.$emit('payment-method-update-invoice')
        return
      }

      if (this.HAS_MOLLIE_SUBSCRIPTION) {
        this.$serverBus.$emit('payment-method-update-mollie')
        return
      }

      if (this.HAS_PADDLE_SUBSCRIPTION) {
        this.$serverBus.$emit('payment-method-update-paddle')
        return
      }

      if (this.WAS_VEGA_ACTIVATED) {
        this.$serverBus.$emit('payment-method-update-vega')
        return
      }

      if (this.WAS_GPT_ACTIVATED) {
        this.$serverBus.$emit('payment-method-update-gpt')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lc-payment-method {
  display: flex;
  flex-direction: column;

  padding: 40px !important;

  min-height: 170px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 10px 10px;

  &--label {
    margin-bottom: 25px;
    color: #7e8186;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.111px;
  }

  &--card-text {
    text-transform: capitalize;

    color: #262629;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 137.5% */
    letter-spacing: -0.111px;
  }

  &--detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &--card {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &--icon {
        position: relative;
        margin-right: 24px;
        height: 40px;
      }
      &--check {
        position: absolute;
        top: -7px;
        right: -7px;
      }
    }

    &--card-detail {
      display: flex;
      flex-direction: column;
    }

    &--update {
      color: #3034f7;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.1px;
      padding: 14px 30px;
      border-radius: 8px;
      border: 1px solid #3034f7;
      background: rgba(48, 52, 247, 0.15);
      cursor: pointer;
    }
  }
}
</style>
